import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUseRouteDateDataQueryVariables = Types.Exact<{
  routeId: Types.Scalars['Int'];
}>;


export type GetUseRouteDateDataQuery = { __typename?: 'Query', route?: { __typename?: 'Route', id: number, startTimeLocal: Types.Scalars['DateTime'], endTimeLocal: Types.Scalars['DateTime'] } | null };


export const GetUseRouteDateDataDocument = gql`
    query GetUseRouteDateData($routeId: Int!) {
  route(id: $routeId) {
    id
    startTimeLocal
    endTimeLocal
  }
}
    `;

/**
 * __useGetUseRouteDateDataQuery__
 *
 * To run a query within a React component, call `useGetUseRouteDateDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUseRouteDateDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUseRouteDateDataQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetUseRouteDateDataQuery(baseOptions: Apollo.QueryHookOptions<GetUseRouteDateDataQuery, GetUseRouteDateDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUseRouteDateDataQuery, GetUseRouteDateDataQueryVariables>(GetUseRouteDateDataDocument, options);
      }
export function useGetUseRouteDateDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUseRouteDateDataQuery, GetUseRouteDateDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUseRouteDateDataQuery, GetUseRouteDateDataQueryVariables>(GetUseRouteDateDataDocument, options);
        }
export type GetUseRouteDateDataQueryHookResult = ReturnType<typeof useGetUseRouteDateDataQuery>;
export type GetUseRouteDateDataLazyQueryHookResult = ReturnType<typeof useGetUseRouteDateDataLazyQuery>;
export type GetUseRouteDateDataQueryResult = Apollo.QueryResult<GetUseRouteDateDataQuery, GetUseRouteDateDataQueryVariables>;
export function refetchGetUseRouteDateDataQuery(variables: GetUseRouteDateDataQueryVariables) {
      return { query: GetUseRouteDateDataDocument, variables: variables }
    }