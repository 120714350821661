import {
  useState, useCallback, useRef, ReactNode,
} from 'react'
import styled from 'styled-components'

type DirectionType = 'top' | 'right' | 'bottom' | 'left'

interface ChevronProps {
  direction: DirectionType;
}

const Chevron = styled.div<ChevronProps>`
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  height: 0.25rem;
  width: 0.25rem;
  transition: all 0.25s ease-in-out;

  transform: ${(p) => p.direction === 'top' && 'rotate(-45deg)'};
  transform: ${(p) => p.direction === 'right' && 'rotate(45deg)'};
  transform: ${(p) => p.direction === 'bottom' && 'rotate(135deg)'};
  transform: ${(p) => p.direction === 'left' && 'rotate(-135deg)'};
`;

interface AccordionProps {
  title: ReactNode;
  children: ReactNode;
}

const Container = styled.div`
  padding: 0 0.4rem 0 0.9rem;
  & + & {
    margin-top: -0.125rem;
  }
`;

const Title = styled.div`
  display: flex;
  background: #ECEFF1 ;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 0.5rem;
`;

const ContentWrapper = styled.div<{ maxHeight: number }>`
  max-height: ${(p) => `${p.maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`;

const Content = styled.div`
  line-height: 1.5;
  background: #f3f3f3 ;
`;

export const Accordion = (props: AccordionProps) => {
  const { title, children } = props

  const [isExpanded, setExpand] = useState<boolean>()

  const contentRef = useRef<HTMLDivElement>(null)
  const contentHeight = isExpanded ? contentRef.current?.scrollHeight ?? 0 : 0

  const handleExpandToggle = useCallback(() => {
    setExpand(!isExpanded)
  }, [isExpanded])

  return (
    <Container>
      <Title onClick={handleExpandToggle}>
        {title}
        <Chevron direction={isExpanded ? 'top' : 'bottom'} />
      </Title>
      <ContentWrapper maxHeight={contentHeight}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </Container>
  )
}

const Layout = styled.div`
  padding: 0.25rem;
  background: #f5f5f5;
`

interface AccordionGroupProps {
  children: ReactNode;
}
export const AccordionGroup = (props: AccordionGroupProps) => (
  <Layout>
    {props.children}
  </Layout>
)
