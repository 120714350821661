import { Input as CoreInput } from 'components/core'
import { formatPhone } from 'helpers/formatPhone'
import React, { useEffect, useRef } from 'react'
import { UseControllerProps, useController } from 'react-hook-form'

export type InputProps =
  UseControllerProps<any, any> &
  Omit<React.ComponentProps<typeof CoreInput>, 'value' | 'inputmode'>

export const PhoneInput = (props: InputProps) => {
  const {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    ...inputProps
  } = props

  const ref = useRef<HTMLIonInputElement | null>(null)
  const {
    field: {
      ref: formFieldRef, onChange, onBlur, value,
    },
  } = useController({
    control,
    defaultValue: defaultValue !== undefined ? defaultValue : '',
    name,
    rules,
    shouldUnregister,
  })

  useEffect(() => {
    formFieldRef({
      focus: () => { ref.current?.setFocus() },
    })
  }, [formFieldRef])

  const formattedValue = formatPhone(value)

  return (
    <CoreInput
      {...inputProps}
      ref={ref}
      value={formattedValue}
      inputmode="tel"
      onIonBlur={(evt) => {
        onBlur()
        if (inputProps.onIonBlur) {
          inputProps.onIonBlur(evt)
        }
      }}
      onIonInput={((evt) => {
        const evtValue = (evt?.target as any)?.value?.replace(/\D/g, '')
        const curValue = (value || '').replace(/\D/g, '')

        if (
          evtValue &&
          Math.abs(evtValue.length - curValue.length) > 2
        ) {
          onChange(evtValue)
        }
      })}
      onIonChange={(evt) => {
        const numValue = (evt.detail.value || '').replace(/\D/g, '')
        evt.detail.value = numValue

        onChange(evt.detail.value)
        if (inputProps.onIonChange) {
          inputProps.onIonChange(evt)
        }
      }}
    />
  )
}
