import { InventoryItemVisibilities, Maybe, OrderInventoryItem } from 'schema'
import { filterVisibleInventory } from './filterVisibleInventory'

type InventoryItemLine = Pick<OrderInventoryItem, 'quantity'> & {
  item: Pick<OrderInventoryItem['item'], 'id' | 'consumable'> & {
    visibility: Pick<InventoryItemVisibilities, 'operatorAssignment'>
  }
}

type OrderWithInventory<T extends InventoryItemLine> = {
  planned?: Maybe<{
    inventory?: Array<T>
  }>
}

export function groupInventoryAcrossOrders<T extends InventoryItemLine>(orders: OrderWithInventory<T>[]) {
  const inventoryByItemId: Record<number, T> = {}

  orders.forEach((order) => {
    const visibleInventory = filterVisibleInventory('operatorAssignment', order?.planned?.inventory || [])

    visibleInventory.forEach((lineItem) => {
      const itemId = lineItem.item.id

      if (!inventoryByItemId[itemId]) {
        inventoryByItemId[itemId] = { ...lineItem }
      } else {
        if (lineItem.quantity === undefined || lineItem.quantity === null) return

        const currentQuantity = inventoryByItemId[itemId].quantity || 0
        const isConsumable = lineItem.item.consumable

        if (isConsumable) {
          inventoryByItemId[itemId].quantity = currentQuantity + lineItem.quantity
        } else {
          inventoryByItemId[itemId].quantity = Math.max(currentQuantity, lineItem.quantity)
        }
      }
    })
  })

  return Object.values(inventoryByItemId)
}
