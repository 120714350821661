import { IonButton } from '@ionic/react'
import styled from 'styled-components'

export const LinkButton = styled(IonButton)`
  text-align: center;
  width: 100%;
  background: none;
  border: none;
  color: var(--ion-color-primary);
  font-size: 1rem;
  --background: none;
  text-decoration: underline;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --box-shadow: none;
  
`
