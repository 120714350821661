import { DateTime } from 'luxon'
import styled from 'styled-components'

import { useLoading } from 'hooks/useLoading'
import { useForm } from 'react-hook-form'
import { useHistory, useRouteMatch } from 'react-router'

import {
  Button,
  Item,
  Label,
} from 'components/core'

import { PhoneInput } from 'components/form/PhoneInput'
import { initiateAuth } from 'helpers/cognitoLogin'
import { useToast } from '../../../hooks/useToast'
import { LoginLayout } from './Layout'

const SubmitButton = styled(Button).attrs(() => ({
  children: 'Continue',
  size: 'medium',
  type: 'submit',
  className: 'brand-colors',
}))`
  margin-top: 30px;
  min-width: 80%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
  margin-bottom: 16px;
`

const TermsOfSMS = styled.div.attrs(() => ({
  children: 'By continuing, you will receive an SMS for verification. Message and data rates may apply.',
}))`
  color: var(--ion-color-step-700);
  font-size: 0.8em;
  line-height: 1.8em;
  padding: 20px 15px;
`

interface FormValues {
  mobile: string
}

export const EnterPhonePage = () => {
  const match = useRouteMatch()
  const history = useHistory()

  const { withLoading } = useLoading()
  const [presentToast] = useToast()

  const form = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onFormSubmit = withLoading(async (data: FormValues) => {
    try {
      const expires = Math.floor(DateTime.utc().plus({ minutes: 10 }).toSeconds())

      const fullPhone = `+1${data.mobile}`

      const authResp = await initiateAuth(fullPhone)

      const username = authResp.ChallengeParameters.USERNAME || fullPhone

      const qs = new URLSearchParams({
        x: expires.toString(),
        u: utf8_to_b64(username),
        s: utf8_to_b64(authResp.Session),
      })

      history.push(`${match.path}/validate?${qs.toString()}`)
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  return (
    <LoginLayout id="login-start">
      {Object.entries(form.formState.errors).map(([field, { message }]) => {
        if (!message) return
        return <ErrorMessage key={`errmsg-${field}`}>{message}</ErrorMessage>
      })}

      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <Item>
          <Label position="floating">Enter Your Phone Number</Label>
          <PhoneInput
            name="mobile"
            control={form.control}
            autocomplete="tel-national"
            rules={{
              required: 'Enter your phone number to login',
              minLength: {
                value: 10,
                message: 'Please enter a valid phone number',
              },
              maxLength: {
                value: 10,
                message: 'Please enter a valid phone number',
              },
            }}
          />
        </Item>

        <div className="ion-text-center">
          <SubmitButton />
        </div>

        <TermsOfSMS />
      </form>
    </LoginLayout>
  )
}

function utf8_to_b64(str: string | number | boolean) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

export default EnterPhonePage
