import { OrderScheduleEntry } from 'schema'
import { RecursivePartial } from 'types/RecursivePartial'

export function sortSchedule<T extends RecursivePartial<OrderScheduleEntry>>(schedule: T[] | null | undefined): T[] {
  if (!schedule) return []

  return schedule.slice().sort((a, b) => {
    if (a.startTime === b.startTime) {
      return (a?.scheduleIndex || Infinity) < (b?.scheduleIndex || Infinity) ? -1 : 1
    }
    if (!b.startTime) return -1
    if (!a.startTime) return 1
    return a.startTime < b.startTime ? -1 : 1
  })
}
