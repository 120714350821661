import { Terms } from 'schema'

import {
  Button,
  Buttons,
  Content,
  Header,
  Title,
  Toolbar,
} from 'components/core'

export const TermsAndConditionsModal = ({
  terms,
  onDismiss,
}: {
  terms: Pick<Terms, 'title' | 'body'>,
  onDismiss?: () => void
}) => (
  <>
    <Header>
      <Toolbar>
        <Title>{terms.title}</Title>
        <Buttons slot="end">
          <Button onClick={onDismiss}> Close </Button>
        </Buttons>
      </Toolbar>
    </Header>
    <Content fullscreen className="ion-padding">
      {terms.body}
      <Button onClick={onDismiss} expand="block" className="ion-margin-top"> Close </Button>
    </Content>
  </>
)
