import { TimeClock } from 'hooks/timeclock/useTimeClock'
import styled from 'styled-components'
import { AspectRatioBox } from '../common/AspectRatio'
import { Duration } from '../common/Duration'

const BannerWrap = styled.div<{ status?: string }>`
  --cs-timeclock-banner-color: var(--ion-color-step-500);
  ${(props) => props.status === 'clockedIn' && `
    --cs-timeclock-banner-color: var(--vulcan-color-status-confirmed);
  `}
  ${(props) => props.status === 'clockedOut' && `
    // --cs-timeclock-banner-color: var(--vulcan-color-status-cancelled);
    --cs-timeclock-banner-color: var(--ion-color-step-450);
  `}
  ${(props) => props.status === 'onBreak' && `
    --cs-timeclock-banner-color: var(--vulcan-color-status-attention);
  `}
`

const Circle = styled.div`
  background: var(--ion-card-background);
  border: 3px solid var(--cs-timeclock-banner-color);
  box-shadow: 0px 0px 6px var(--ion-color-step-250);
  margin: 2% auto;
  width: 96%;
  height: 96%;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  > * {
    flex: 0 0 auto;
    min-height: 0px;
    margin: 3px 0;
    text-align: center;
  }
`

const Status = styled.div`
  font-weight: 700;
  font-size: 1.5em;
`

const CurrentDurationWrap = styled.div`
  font-size: 2.9em;
`

const ShiftDurationWrap = styled.div`
  font-size: 0.9em;
  margin-top: 7px;
`

const Task = styled.div`
  font-size: 1.2em;
  font-weight: 500;
`

export const TimeClockBanner = ({ timeClock }: { timeClock: TimeClock | undefined | null }) => {
  const status = timeClock?.status

  return (
    <BannerWrap status={timeClock?.status?.slug}>
      <AspectRatioBox ratio={1}>
        <Circle>
          <Status>{status?.name}</Status>
          <CurrentDurationWrap>
            {
              status?.isActive ?
                <Duration startTime={timeClock?.startTime} endTime={timeClock?.endTime} />
                :
                <Duration showEmpty />
            }
          </CurrentDurationWrap>
          {status?.isActive && (
            <>
              <Task>{timeClock?.task?.name}</Task>
              <ShiftDurationWrap>
                Active Total: <Duration startTime={timeClock?.shift?.startTime} endTime={timeClock?.shift?.endTime} />
              </ShiftDurationWrap>
            </>
          )}
        </Circle>
      </AspectRatioBox>
    </BannerWrap>
  )
}
