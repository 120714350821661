import { DateTime } from 'luxon'
import { RouteMaybeDates, RouteWithDates, routeDate } from './routeDate'

export interface AssignmentWithDates {
  route: RouteWithDates
}

export type AssignmentMaybeDates = {
  route?: RouteMaybeDates | null
}

export function assignmentDate(assignment: AssignmentWithDates): DateTime
export function assignmentDate(assignment: null | undefined): undefined
export function assignmentDate(assignment: AssignmentMaybeDates | null | undefined): DateTime | undefined
export function assignmentDate(assignment: any): undefined
export function assignmentDate(assignment: AssignmentWithDates | any): DateTime | undefined {
  if (!assignment) return
  return routeDate(assignment.route)
}
