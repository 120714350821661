import { gql } from '@apollo/client'
import { useNetwork } from 'ahooks'
import { useSession } from 'hooks/useSession'
import { Duration } from 'luxon'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { EagerLoadDataQueryVariables, useEagerLoadDataLazyQuery } from './__generated__/EagerLoadData'

gql`
  query EagerLoadData($operatorId: Int!) {
    inventory(where: { active: { equals: true } }) {
      ...InventoryItemData
    }
    operators(where: { id: { equals: $operatorId }}) {
      id

      assignments(where: { visible: { equals: true } }) {
        id
        ...AssignmentCardSlimData
        ...AssignmentCardFullData

        route {
          id
          startTimeLocal
          endTimeLocal
          ...RouteOverviewData
          ...NavigateRouteButtonData

          waypoints {
            id
            ...WaypointCardSlimData

            order {
              id
              ...OrderShowData
              ...OrderWorkTicketData
            }
          }
        }
      }
    }
  }
`

export const EagerLoadData = ({ children }: React.PropsWithChildren<any>) => {
  const { user } = useSession()
  return (
    <>
      {children}

      {user && <EagerLoader operatorId={user.id} />}
    </>
  )
}

const POLL_INTERVAL_MS = Duration.fromObject({ minutes: 15 }).toMillis()
const SAFE_DELAY_FROM_LAST_ACTIVITY_MS = 5000

const EagerLoader = (variables: EagerLoadDataQueryVariables) => {
  const lastLoad = useRef<number>(Date.now())
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const location = useLocation()
  const network = useNetwork()

  const [doLoadDataQuery] = useEagerLoadDataLazyQuery({ variables })

  const loadData = async () => {
    // don't eager load if there is "activity"
    // note: probably a better way to detect that
    if (lastLoad.current > Date.now() - SAFE_DELAY_FROM_LAST_ACTIVITY_MS) {
      timeout.current = setTimeout(loadData, SAFE_DELAY_FROM_LAST_ACTIVITY_MS)
      return
    }

    await doLoadDataQuery()
    timeout.current = setTimeout(loadData, POLL_INTERVAL_MS)
  }

  useEffect(() => {
    if (network.online) {
      loadData()
    }
  }, [network.online])

  useEffect(() => {
    lastLoad.current = Date.now()
  }, [location])

  useLayoutEffect(() => {
    lastLoad.current = Date.now()
    loadData()

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  return <></>
}
