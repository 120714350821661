import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useIonViewDidEnter } from '@ionic/react'
import { useLoading } from 'hooks/useLoading'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import {
  Item,
  Label,
} from 'components/core'
import { Input } from 'components/form/Input'

import { validateCode } from 'helpers/cognitoLogin'
import { useSession } from 'hooks/useSession'
import { useToast } from '../../../hooks/useToast'
import { LoginLayout } from './Layout'

interface FormValues {
  token: string
}

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
  margin-bottom: 16px;
`

export const ValidatePhonePage = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { login } = useSession()

  const { withLoading } = useLoading()
  const [presentToast] = useToast()

  const [didEnter, setDidEnter] = useState(false)
  useIonViewDidEnter(() => setDidEnter(true))

  const query = useMemo(() => (
    Object.fromEntries(new URLSearchParams(location.search))
  ), [location.search])

  const username = ('u' in query) ? b64_to_utf8(query.u) : undefined
  const session = ('s' in query) ? b64_to_utf8(query.s) : undefined
  const expires = useMemo(() => query?.x && DateTime.fromSeconds(parseInt(query.x)), [query?.x])

  const goBack = () => {
    history.replace(match.path.split('/').slice(0, -1).join('/'))
  }

  const showCodeTimedOut = () => {
    presentToast({
      color: 'danger',
      message: 'Sorry the sent code is no longer valid, please enter your phone number again to continue.',
      duration: 4000,
    })
    goBack()
  }

  useEffect(() => {
    if (didEnter && !username) {
      goBack()
    }
  }, [didEnter, username])

  useEffect(() => {
    if (!expires) return

    const diffMs = expires.diffNow().as('milliseconds')

    if (diffMs < 0) {
      showCodeTimedOut()
      return
    }

    const timer = setTimeout(showCodeTimedOut, diffMs)

    return () => {
      clearTimeout(timer)
    }
  }, [expires])

  const form = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      token: '',
    },
  })

  const onFormSubmit = withLoading(async (data: FormValues) => {
    if (!username) return

    try {
      const validateResp = await validateCode({
        username,
        code: data.token,
        session: session || undefined,
      })

      const idToken = validateResp?.AuthenticationResult?.IdToken
      if (idToken) {
        const response = await login(idToken)

        if (!response.success) {
          throw response.error || new Error('Authentication failed')
        }

        history.replace('/assignments')
        return
      }

      if (validateResp.Session) {
        history.replace({
          pathname: location.pathname,
          search: `?${new URLSearchParams({
            ...query,
            s: utf8_to_b64(validateResp.Session),
          })}`,
        })

        form.setValue('token', '')
        form.setError('token', {
          type: 'manual',
          message: 'The code you provided did not match the most recent code sent to your phone. Please try again.',
        })
      }
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `Error: ${err.message}. Please try again`,
        duration: 4000,
      })

      goBack()
    }
  })

  return (
    <LoginLayout id='login-validate' backTo={location.pathname.split('/').slice(0, -1).join('/')}>
      <form onSubmit={form.handleSubmit(onFormSubmit)}>
        <Item>
          {form.formState.errors.token &&
            <ErrorMessage>{form.formState.errors.token.message}</ErrorMessage>}
          <Label position="floating">Enter the code sent to your phone</Label>
          <Input
            name="token"
            type="number"
            inputmode="numeric"
            control={form.control}
            onIonBlur={() => {
              const value = form.getValues('token')
              if (value?.length === 6 && !form.formState.isSubmitting) {
                form.handleSubmit(onFormSubmit)()
              }
            }}
            onIonChange={(event) => {
              const value = event.detail.value || ''
              if (value.length === 6 && !form.formState.isSubmitting) {
                form.handleSubmit(onFormSubmit)()
              }
              if (value.length > 6) {
                form.setValue('token', value.slice(0, 6))
              }
            }}
          />
        </Item>
      </form>
    </LoginLayout>
  )
}

function b64_to_utf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)))
}

function utf8_to_b64(str: string | number | boolean) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

export default ValidatePhonePage
