import { DateTime } from 'luxon'
import { AssignmentMaybeDates, AssignmentWithDates, assignmentDate } from './assignmentDate'
import { formatRelativeTime, parseDatetime } from './datetime'

export function formatAssignmentLeaveTime(assignment: AssignmentWithDates): string
export function formatAssignmentLeaveTime(assignment: null | undefined): undefined
export function formatAssignmentLeaveTime(assignment: AssignmentMaybeDates | null | undefined): string | undefined
export function formatAssignmentLeaveTime(assignment: any): undefined
export function formatAssignmentLeaveTime(assignment: AssignmentWithDates | any): string | undefined {
  const startTime = parseDatetime(assignment?.route?.startTimeLocal)
  if (!startTime) return

  const date = assignmentDate(assignment) as DateTime | undefined

  if (startTime && date) {
    return formatRelativeTime(startTime, date.toLocal(), { omitMinutesIfZero: true }).replace(' ', '')
  }
}
