import * as Types from 'schema';

import { gql } from '@apollo/client';
import { NavigateRouteButtonDataFragmentDoc } from '../../routes/__generated__/NavigateRouteButton';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNavigateAssignmentButtonQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['Int'];
}>;


export type GetNavigateAssignmentButtonQuery = { __typename?: 'Query', assignment?: { __typename?: 'Assignment', id: number, route?: { __typename?: 'Route', id: number, status: Types.RouteStatus, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null } }> } | null } | null };


export const GetNavigateAssignmentButtonDocument = gql`
    query GetNavigateAssignmentButton($assignmentId: Int!) {
  assignment(id: $assignmentId) {
    id
    route {
      ...NavigateRouteButtonData
    }
  }
}
    ${NavigateRouteButtonDataFragmentDoc}`;

/**
 * __useGetNavigateAssignmentButtonQuery__
 *
 * To run a query within a React component, call `useGetNavigateAssignmentButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigateAssignmentButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigateAssignmentButtonQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetNavigateAssignmentButtonQuery(baseOptions: Apollo.QueryHookOptions<GetNavigateAssignmentButtonQuery, GetNavigateAssignmentButtonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavigateAssignmentButtonQuery, GetNavigateAssignmentButtonQueryVariables>(GetNavigateAssignmentButtonDocument, options);
      }
export function useGetNavigateAssignmentButtonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavigateAssignmentButtonQuery, GetNavigateAssignmentButtonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavigateAssignmentButtonQuery, GetNavigateAssignmentButtonQueryVariables>(GetNavigateAssignmentButtonDocument, options);
        }
export type GetNavigateAssignmentButtonQueryHookResult = ReturnType<typeof useGetNavigateAssignmentButtonQuery>;
export type GetNavigateAssignmentButtonLazyQueryHookResult = ReturnType<typeof useGetNavigateAssignmentButtonLazyQuery>;
export type GetNavigateAssignmentButtonQueryResult = Apollo.QueryResult<GetNavigateAssignmentButtonQuery, GetNavigateAssignmentButtonQueryVariables>;
export function refetchGetNavigateAssignmentButtonQuery(variables: GetNavigateAssignmentButtonQueryVariables) {
      return { query: GetNavigateAssignmentButtonDocument, variables: variables }
    }