import { gql } from '@apollo/client'
import { Loading, LoadingWrapper } from 'components/common/Loading'
import { Button, Icon } from 'components/core'
import { calculatorOutline } from 'ionicons/icons'
import { isNil } from 'lodash'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useToast } from '../../hooks/useToast'
import { useGetCodEstimateLazyQuery } from './__generated__/EstimateCollectOnDelivery'

gql`
  query GetCODEstimate($id: Int!) {
    estimateInvoice(where: { id: $id }) {
      id
      total
    }
  }
`

const formatCurrency = (number: number) => (
  (new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })).format(number)
)

export const EstimateCollectOnDelivery = styled(({ className, orderId }: { className?: string, orderId: number }) => {
  const [presentToast] = useToast()

  const [loadEstimate, { data, loading, error }] = useGetCodEstimateLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: orderId,
    },
  })

  useEffect(() => {
    if (error) {
      presentToast({
        color: 'danger',
        message: `An error occured while fetching Collect on Delivery estimate: ${error.message}`,
        duration: 4000,
      })
    }
  }, [error])

  const estimate = data?.estimateInvoice?.total

  return (
    <Button
      expand="block"
      className={className}
      onClick={() => {
        if (loading) return
        loadEstimate()
      }}
    >
      <div className="cod-button-layout">
        <div>
          Estimate COD
        </div>
        <div>
          {
            !isNil(estimate) ?
              formatCurrency(estimate)
              : loading ?
                <Loading enabled />
                :
                <Icon icon={calculatorOutline} />
          }
        </div>
      </div>
    </Button>
  )
})`
  --background: var(--vulcan-color-status-confirmed);
  --background-activated: var(--vulcan-color-status-confirmed);
  --background-focused: var(--vulcan-color-status-confirmed);
  --background-hover: var(--vulcan-color-status-confirmed);

  margin-top: 20px;
  font-weight: 600;

  .cod-button-layout {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    > div {
      padding: 0 5px;
      text-align: center;
    }

    > div:first-child {
      flex: 1 1 auto;
      text-align: left;
    }

    > div:last-child {

      flex: 0 0 auto;
      font-weight: 700;
    }
  }

  ${LoadingWrapper} {
    padding: 0;
    width: 20px;
  }
`
