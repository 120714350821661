import { Logo } from 'components/common/Logo'
import {
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from 'components/core'
import { ReactNode } from 'react'
import { useSession } from '../../hooks/useSession'

export type GreetingHeaderProps = {
  subtitle?: ReactNode
}

export const GreetingHeader = (props: GreetingHeaderProps) => {
  const { user } = useSession()

  return (
    <Row className="ion-padding" style={{ paddingBottom: '4px' }}>
      <Col>
        <CardTitle style={{ marginBottom: '5px' }}>Hello <span className="vulcan-text-color-primary" style={{ fontWeight: 400 }}>{user?.firstName}</span></CardTitle>
        <CardSubtitle>{props.subtitle || ' '}</CardSubtitle>
      </Col>
      <Col size="auto">
        <Logo />
      </Col>
    </Row>
  )
}
