import gql from 'graphql-tag'
import React, { useMemo } from 'react'

import {
  Button,
  Card,
  CardContent as CardContentRaw,
  CardTitle,
  Col,
  Grid,
  Icon,
  Row,
} from 'components/core'

import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { LoadingCard } from 'components/common/LoadingCard'
import { Markdown } from 'components/common/Markdown'
import { ModalButton } from 'components/common/ModalButton'
import { PhoneNumber } from 'components/common/PhoneNumber'
import { EstimateCollectOnDelivery } from 'components/order/EstimateCollectOnDelivery'
import { LinesOnSiteCallout } from 'components/order/LinesOnSiteCallout'
import { contactsForRole } from 'helpers/contactsForRole'
import { formatTime, parseDatetime } from 'helpers/datetime'
import { filterVisibleInventory } from 'helpers/filterVisibleInventory'
import { sortSchedule } from 'helpers/sortting/sortSchedule'
import { popPath } from 'helpers/url'
import { useFeature } from 'hooks/useFeatures'
import { UseNavigateHandlerProps, useNavigateInteractionHandler } from 'hooks/useNavigateInteractionHandler'
import { useOrganization } from 'hooks/useOrganization'
import {
  checkmarkCircleOutline, closeCircleOutline, documentTextOutline, navigate, navigateCircle, pencilOutline,
} from 'ionicons/icons'
import { StandardLayout } from 'layouts/StandardLayout'
import { compact, groupBy, uniq } from 'lodash'
import { CancelOrderModal } from 'modals/CancelOrderModal'
import { CustomerSignatureModal } from 'modals/CustomerSignatureModal'
import { WorkTicketModal } from 'modals/WorkTicketModal'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { GetOrderShowDataQuery, useGetOrderShowDataQuery } from './__generated__/Show'

type Order = NonNullable<GetOrderShowDataQuery['order']>

const Label = styled.div`
  font-weight: 700;
  margin-bottom: 3px;
`

const CardContent = styled(CardContentRaw)`
  font-size: 15px;
  line-height: 1.4em;
  padding: 14px;
`

const CancelButton = styled(ModalButton).attrs((props) => ({
  content: <>
    <Icon slot="start" icon={closeCircleOutline} />
    Cancel Order
  </>,
  size: 'small',
  fill: 'transparent',
}))`
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 45px;
  --padding-end: 45px;
  margin-top: 15px;
  opacity: 0.8;

`

const WorkTicketButton = styled(ModalButton).attrs((props) => ({
  content: props.disabled ? (
    <>
      <Icon slot="start" icon={checkmarkCircleOutline} />
      Work Ticket Completed
    </>
  ) : (
    <>
      <Icon slot="start" icon={documentTextOutline} />
      Enter Work Ticket
    </>
  ),
  className: 'ion-margin',
  expand: 'block',
}))`
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 45px;
  --padding-end: 45px;
  min-width: 40%;

  &.button-disabled {
    opacity: 1;
    --background: var(--ion-color-step-300);
  }
`

const CustomerSignatureButton = styled(ModalButton).attrs((props) => ({
  content: props.disabled ? (
    <>
      <Icon slot="start" icon={checkmarkCircleOutline} />
      Signature Collected
    </>
  ) : (
    <>
      <Icon slot="start" icon={pencilOutline} />
      Customer Signature
    </>
  ),
  className: 'ion-margin',
  expand: 'block',
}))`
  --padding-top: 16px;
  --padding-bottom: 16px;
  --padding-start: 45px;
  --padding-end: 45px;
  min-width: 40%;

  &.button-disabled {
    opacity: 1;
    --background: var(--ion-color-step-300);
  }
`

const NavigateButton = styled(Button).attrs(() => ({
  size: 'small',
  fill: 'clear',
  children: <>
    <Icon slot="start" icon={navigate} />
    Navigate to Site
  </>,
}))`
  margin-left: -8px;

  ion-icon {
    font-size: 1em;
  }
`

gql`
  fragment OrderShowData on Order {
    id
    revision
    name
    dateOfService
    dateOfServiceLocal
    purchaseOrder
    instructions
    cashOnDelivery
    flatRate
    linesOnSite
    statusDetails {
      slug
      name
    }
    planned {
      id
      inventory {
        id
        quantity
        item {
          id
          name
          consumable
          displayUnit
          visibility {
            operatorAssignment
          }
        }
      }
      schedule {
        id
        scheduleIndex
        startTime
        endTime
        duration
        pour {
          id
          name
          type {
            id
            name
          }
        }
        step
        metrics {
          key
          value
        }
      }
    }
    supplier {
      id
      name
      name2
      phones {
        id
        number
      }
    }
    customer {
      id
      name
      abbreviation
    }
    site {
      id
      name
      name2
      customerId
      address {
        ...AddressFields
      }
    }
    contacts {
      id
      default
      contact {
        id
        firstName
        lastName
        phones {
          id
          number
          sms
        }
      }
      role {
        name
        slug
        sort
      }
    }
    terms {
      id
      accepted
      needsAcceptance
    }
  }
`

gql`
  query GetOrderShowData($orderId: Int!) {
    order(id: $orderId) {
      ...OrderShowData
    }
  }
`

interface OrderShowProps {
  orderId: number
}

export const OrderShow = (props: OrderShowProps) => {
  const { orderId } = props

  const { data, loading: loadingRaw, error } = useGetOrderShowDataQuery({
    fetchPolicy: 'cache-first',
    variables: { orderId },
  })

  const order = data?.order
  const loading = !data && loadingRaw
  const notFound = loading === false && !order

  if (error) {
    return (
      <StandardLayout title="Error" defaultBackTo="/">
        <InlineErrorMessage message={`An error occured: ${error}`} />
      </StandardLayout>
    )
  }

  if (loading) {
    return (
      <StandardLayout>
        <LoadingCard />
      </StandardLayout>
    )
  }

  if (notFound) {
    return (
      <StandardLayout title="Not Found" defaultBackTo="/">
        <InlineErrorMessage message="This order could not be found" />
      </StandardLayout>
    )
  }

  return <OrderLoaded order={order || {} as any} />
}

const OrderLoaded = ({ order }: { order: Order }) => {
  const [allowOperatorToCancel] = useFeature('order.allowOperatorToCancel')
  const [showFullAddress] = useFeature('order.showFullAddress')
  const location = useLocation()

  const isCancelled = ['cancelled'].includes(order.statusDetails.slug)
  const workTicketNeeded = !['turned_in', 'reviewed'].includes(order.statusDetails.slug)
  const canCancel = allowOperatorToCancel && !isCancelled

  const signatureNeeded = order.terms?.needsAcceptance
  const showSignature = (
    order.terms.accepted ||
    signatureNeeded
  )

  const title = order.site?.name || order?.customer?.name || `${order.customer?.abbreviation} #${order.id}`

  const fieldContacts = useMemo(() => (
    contactsForRole((order.contacts || []), 'field')
  ), [order.contacts])

  const schedule = useMemo(() => sortSchedule(order.planned?.schedule), [order?.planned?.schedule])
  const steps = groupBy(schedule, 'step')

  const inventory = filterVisibleInventory('operatorAssignment', order.planned?.inventory || [])

  const timezone = order.site?.address?.timezone

  const hasMultiplePours = steps.pour?.length > 1 || false

  const siteAddress = order.site?.address

  const navigateLink: UseNavigateHandlerProps['link'] = (navigator) => {
    if (siteAddress) {
      return navigator.fn([siteAddress])
    }
    return null
  }

  const { ref: headerNavButtonRef } = useNavigateInteractionHandler<HTMLIonButtonElement>({
    link: navigateLink,
  })
  const { ref: siteNavButtonRef } = useNavigateInteractionHandler<HTMLDivElement>({
    link: navigateLink,
  })

  return (
    <StandardLayout
      title={title}
      defaultBackTo={popPath(location.pathname, 2)}
      headerEndButtons={(
        <>
          {siteAddress && (
            <Button
              expand="block"
              target="_blank"
              ref={headerNavButtonRef}
            >
              <span>
                <Icon slot="start" icon={navigateCircle} style={{ margin: 0 }} />
              </span>
            </Button>
          )}
        </>
      )}
    >

      {order.linesOnSite && <LinesOnSiteCallout />}

      <Card>
        <CardContent>
          {
            order.statusDetails.slug === 'cancelled' && (
              <CardTitle style={{ color: 'var(--vulcan-color-status-cancelled)', marginLeft: '10px' }}>
                Cancelled
              </CardTitle>
            )
          }
          <Grid>
            <Row>
              {order.site && (
                <Col size="7">
                  <Label>Site</Label>
                  <div ref={siteNavButtonRef}>
                    {uniq(compact([order.site.name, order.site.name2])).join(' | ')} <br />
                    {showFullAddress && order.site.address.street && <>{order.site.address.street}<br /></>}
                    {showFullAddress && order.site.address.street2 && <>{order.site.address.street2}<br /></>}
                    {compact([order.site.address.city, order.site.address.state]).join(', ')}

                    {siteAddress && (
                      <>
                        <br />
                        <NavigateButton />
                      </>
                    )}
                  </div>
                </Col>
              )}
              {
                schedule[0]?.startTime && (
                  <Col size="5">
                    <Label> Onsite Time </Label>
                    {formatTime(parseDatetime(schedule[0]?.startTime, timezone))}
                  </Col>
                )
              }
            </Row>
            {order.instructions && (
              <Row>
                <Col>
                  <Label> Instructions </Label>
                  <Markdown children={order.instructions} />
                </Col>
              </Row>
            )}
            <Row>
              {order.customer && (
                <Col>
                  <Label> Customer </Label>
                  {order.customer.name}
                </Col>
              )}
              {fieldContacts.length > 0 && (
                <Col size="5">
                  <Label> Contact{fieldContacts.length > 1 && 's'} </Label>
                  {
                    fieldContacts.map((contact) => (
                      <div key={contact.id}>
                        {compact([contact.firstName, contact.lastName]).join(' ')} <br />

                        {contact.phones?.map((phone, i) => <PhoneNumber key={i} phone={phone} clickable />)}
                      </div>
                    ))
                  }
                </Col>
              )}
            </Row>
            <Row class="ion-justify-content-between">
              {
                steps.pour?.map((pourStep, index) => {
                  const pourYardage = pourStep.metrics.find((metric) => metric.key === 'cubicYards')?.value

                  return (
                    <React.Fragment key={index}>
                      {hasMultiplePours && <Col size="12" style={{ marginTop: '20px' }}><Label>Pour #{index + 1}</Label></Col>}

                      {pourStep?.pour?.name && (
                        <Col size="7">
                          <Label> Lot / Location </Label>
                          {pourStep?.pour?.name}
                        </Col>
                      )}
                      {pourStep?.pour?.type?.name && (
                        <Col size="5">
                          <Label> Pour Type </Label>
                          {pourStep?.pour?.type?.name}
                        </Col>
                      )}
                      {pourStep?.startTime && (
                        <Col size="5">
                          <Label> Pour Time </Label>
                          {formatTime(parseDatetime(pourStep?.startTime, timezone))}
                        </Col>
                      )}
                      {pourYardage !== undefined && (
                        <Col size="5">
                          <Label> Pour Yardage </Label>
                          {pourYardage}
                        </Col>
                      )}
                    </React.Fragment>
                  )
                })
              }
            </Row>

            {inventory.length > 0 && (
              <Row>
                <Col>
                  <Label>Inventory</Label>
                  <ul style={{ marginTop: 0 }}>
                    {inventory.map(({ quantity, item }) => (
                      <li key={item.id}>
                        {compact([item.name, quantity && `${quantity}${item.displayUnit || 'x'}`]).join(' - ')}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            )}
            {
              order.supplier && (
                <Row>
                  <Col>
                    <Label>Supplier</Label>
                    {compact(uniq([order.supplier.name, order.supplier.name2])).join(' | ')}

                    {order?.supplier?.phones[0]?.number && (
                      <>
                        <br />
                        <PhoneNumber phone={order.supplier.phones[0]} clickable />
                      </>
                    )}
                  </Col>
                </Row>
              )
            }

            <Row>
              <Col>
                <Label>Order Number</Label>
                {order.id}
              </Col>
            </Row>

            <CollectOnDeliveryCallout order={order} />
          </Grid>
        </CardContent>
      </Card>

      {showSignature && (
        <CustomerSignatureButton disabled={!signatureNeeded}>
          <CustomerSignatureButton.Modal>
            <CustomerSignatureModal id={order.id} />
          </CustomerSignatureButton.Modal>
        </CustomerSignatureButton>
      )}

      <WorkTicketButton disabled={!workTicketNeeded}>
        <WorkTicketButton.Modal>
          <WorkTicketModal id={order.id} />
        </WorkTicketButton.Modal>
      </WorkTicketButton>

      {canCancel && (
        <div className="ion-text-center">
          <CancelButton>
            <CancelButton.Modal>
              <CancelOrderModal id={order.id} />
            </CancelButton.Modal>
          </CancelButton>
        </div>
      )}
    </StandardLayout>
  )
}

const ORGS_WITH_COD_ESTIMATOR = [
  15,
  23,
]

const CollectOnDeliveryCallout = ({ order }: { order: Order }) => {
  const organization = useOrganization()
  const canEstimateCOD = ['turned_in', 'reviewed'].includes(order.statusDetails.slug) && ORGS_WITH_COD_ESTIMATOR.includes(organization?.id || -1)

  if (!order.cashOnDelivery) return null

  return (
    <Row>
      <Col style={{ textAlign: 'center', marginTop: '20px' }}>
        <Label style={{ color: 'var(--vulcan-color-status-confirmed)', fontSize: '1.2em' }}>Collect on Delivery</Label>

        {canEstimateCOD && <EstimateCollectOnDelivery orderId={order.id} />}
      </Col>
    </Row>
  )
}
