import { useIonActionSheet } from '@ionic/react'
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions'
import { defaultNavigationApp, NavigationApp, navigators } from 'helpers/navigate'
import { useUserSettings } from './useUserSettings'

type UseNavigationAppActionSheetProps = {
  onDidDismiss?: HookOverlayOptions['onDidDismiss']
  onDidSelect?: (navigationApp: NavigationApp) => void
}

export const useNavigationAppActionSheet = (props: UseNavigationAppActionSheetProps = {}) => {
  const [present, dismiss] = useIonActionSheet()
  const { navigationApp: currentNavigationApp, setNavigationApp } = useUserSettings()

  const navigationApp = currentNavigationApp || defaultNavigationApp()

  const showActionSheet = ({ skipSave }: { skipSave?: boolean } = {}) => {
    const options = navigators()

    present({
      onDidDismiss: props.onDidDismiss,
      header: 'Select Navigation App',
      buttons: [
        ...Object.entries(options).map(([app, option]) => ({
          text: option.title,
          cssClass: app === navigationApp ? 'action-sheet-default' : undefined,
          handler: () => {
            if (!skipSave) {
              setNavigationApp(app as NavigationApp)
            }

            if (props.onDidSelect) {
              props.onDidSelect(app as NavigationApp)
            }
          },
        })),
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    })
  }

  return {
    present: showActionSheet,
    dismiss,
  }
}
