import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderCancellationReasonsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.QueryOrderCancellationReasonsWhereInput>;
}>;


export type GetOrderCancellationReasonsQuery = { __typename?: 'Query', orderCancellationReasons: Array<{ __typename?: 'OrderCancellationReason', slug: string, name: string, sort: number }> };


export const GetOrderCancellationReasonsDocument = gql`
    query GetOrderCancellationReasons($where: QueryOrderCancellationReasonsWhereInput) {
  orderCancellationReasons(where: $where) {
    slug
    name
    sort
  }
}
    `;

/**
 * __useGetOrderCancellationReasonsQuery__
 *
 * To run a query within a React component, call `useGetOrderCancellationReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCancellationReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCancellationReasonsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOrderCancellationReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderCancellationReasonsQuery, GetOrderCancellationReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderCancellationReasonsQuery, GetOrderCancellationReasonsQueryVariables>(GetOrderCancellationReasonsDocument, options);
      }
export function useGetOrderCancellationReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderCancellationReasonsQuery, GetOrderCancellationReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderCancellationReasonsQuery, GetOrderCancellationReasonsQueryVariables>(GetOrderCancellationReasonsDocument, options);
        }
export type GetOrderCancellationReasonsQueryHookResult = ReturnType<typeof useGetOrderCancellationReasonsQuery>;
export type GetOrderCancellationReasonsLazyQueryHookResult = ReturnType<typeof useGetOrderCancellationReasonsLazyQuery>;
export type GetOrderCancellationReasonsQueryResult = Apollo.QueryResult<GetOrderCancellationReasonsQuery, GetOrderCancellationReasonsQueryVariables>;
export function refetchGetOrderCancellationReasonsQuery(variables?: GetOrderCancellationReasonsQueryVariables) {
      return { query: GetOrderCancellationReasonsDocument, variables: variables }
    }