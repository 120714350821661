import { Duration } from 'luxon'
import { ValueOf } from 'type-fest'
import { useOrganization } from './useOrganization'

const TENANTS = {
  keim: 359,
  miller: 621,
  customConcrete: 654,
  cfnt: 753,
  inplace: 885,
  premix: 1017,
}

type TENANT_ID = ValueOf<typeof TENANTS>

const TENANT_DURATION_MINUTES: Record<TENANT_ID, number> = {
  [TENANTS.keim]: 10,
  [TENANTS.miller]: 15,
  [TENANTS.customConcrete]: 15,
  [TENANTS.cfnt]: 15,
  [TENANTS.inplace]: 15,
  [TENANTS.premix]: 15,
}

const DEFAULT_DURATION_MINUTES = 30

export const useReportAtDuration = () => {
  const organization = useOrganization()
  const forTenant = organization?.id && TENANT_DURATION_MINUTES[organization?.id]

  return Duration.fromObject({
    minutes: forTenant || DEFAULT_DURATION_MINUTES,
  })
}
