import { ApolloLink } from '@apollo/client'
import { Token } from 'hooks/useSession'

export const authLink = new ApolloLink((operation, forward) => {
  const token = Token
  if (token) {
    operation.setContext({
      headers: {
        authorization: token,
      },
    })
  }
  return forward(operation)
})
