import styled from 'styled-components'
import { byFieldAsc } from '../../../helpers/sortting'
import TimeByActivity from './TimeByActivity'

const ActivitySummaryContainer = styled.div`
  overflow: auto hidden;
  padding-bottom: 10px;
`
const ActivitySummaryList = styled.div<{ totalKeys: number, namesLength: number }>`
  overflow-x: auto;
  width: ${(props) => ((props.namesLength * 7.2) + (props.totalKeys * 120))}px ;
`

interface TimeActivitySummaryProps {
  hoursByTask: Record<number, HoursByTaskProps>
}
interface HoursByTaskProps {
  name: string
  time: number
  color: string
}

const TimeActivitySummary = (props: TimeActivitySummaryProps) => {
  const { hoursByTask } = props
  const summaries = Object.values(hoursByTask).sort(byFieldAsc('sort'))

  const namesLength = summaries.reduce((acc, summary) => (
    acc + summary.name.length
  ), 0)

  return (
    <ActivitySummaryContainer>
      <ActivitySummaryList totalKeys={summaries.length} namesLength={namesLength}>
        {summaries.map((summary, i) => (
          <TimeByActivity key={i} color={summary.color} name={summary.name} time={summary.time} />
        ))}
      </ActivitySummaryList>
    </ActivitySummaryContainer>
  )
}

export default TimeActivitySummary
