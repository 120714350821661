import { omit } from 'lodash'
import { DateTime, Zone } from 'luxon'
import { TIME_SIMPLE_WITH_ZONE } from './luxon'

export const TIME_SHIFT_ITEM_FORMAT = 'LL/dd hh:mma'
export const TIME_SHIFT_HOURS_FORMAT = "h'h'm'm'"

export interface DatetimeRange {
  startDate: DateTime;
  endDate: DateTime;
}

export function parseDatetime(time: string, timezone?: string | null | undefined): DateTime
export function parseDatetime(time: null | undefined, timezone?: string | null | undefined): undefined
export function parseDatetime(time: null | undefined | string, timezone?: string | null | undefined): DateTime | undefined
export function parseDatetime(time: null | undefined | string, timezone?: string | null | undefined): DateTime | undefined {
  if (!time) return

  if (timezone) {
    return DateTime.fromISO(time as string).setZone(timezone)
  }
  return DateTime.fromISO(time as string, { setZone: true })
}

export const formatUserRelativeTime = (datetime: DateTime) => formatRelativeTime(datetime, datetime.toLocal())

export const formatRelativeTime = (displayDate: DateTime, relativeDate: DateTime, options?: { omitMinutesIfZero?: boolean }) => {
  let format: Intl.DateTimeFormatOptions = (displayDate.offset !== relativeDate.offset) ? TIME_SIMPLE_WITH_ZONE : DateTime.TIME_SIMPLE

  if (options?.omitMinutesIfZero && displayDate.minute === 0) {
    format = omit(format, 'minute')
  }

  const timeString = displayDate.toLocaleString(format)

  if (displayDate.toISODate() !== relativeDate.toISODate()) {
    return `${timeString} ${displayDate.toFormat('ccc')}`
  }
  return timeString
}

export const formatTime = (displayDate: DateTime, options?: Parameters<typeof formatRelativeTime>[2]) => formatRelativeTime(displayDate, displayDate, options)

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
])

export const daySuffix = (datetime: DateTime) => {
  const pr = new Intl.PluralRules('en-US', {
    type: 'ordinal',
  })
  const rule = pr.select(datetime.day)
  return suffixes.get(rule)
}

export const middleDateTime = (start: DateTime, end: DateTime) => (
  start.plus({ milliseconds: end.diff(start).as('milliseconds') / 2 })
)

export const todayInZone = (timezone: string | Zone) => DateTime.utc().setZone(timezone).startOf('day')
