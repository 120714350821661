import { FetchResult } from '@apollo/client'
import { isEmpty } from 'lodash'
import { useTimeClock } from '../../../hooks/timeclock/useTimeClock'

export const useTimeClockMutationErrorHandler = () => {
  const { refetch } = useTimeClock({
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'network-only',
  })

  return (errors: FetchResult['errors'], options?: { throw?: boolean }) => {
    if (!errors) return
    if (isEmpty(errors)) return

    const isDuplicate = errors.every((error) => error.extensions?.code === 'DUPLICATE_ERROR')
    if (isDuplicate) {
      refetch()
      return
    }

    if (options?.throw) {
      throw new Error(errors.map((err) => err.message).join('\n'))
    }

    return errors
  }
}
