import { uniq } from 'lodash'
import { formatAddress } from '../formatAddress'
import { NavigateFunction, NavigationRegister } from './types'

export const navigateOnGoogleLink: NavigateFunction = (addresses) => {
  if (addresses.length === 0) return null

  const points = uniq(
    addresses.map((address) => {
      if (address.lat && address.lng) {
        return [address.lat, address.lng].join(',')
      }
      return formatAddress(address)
    })
  )

  const parameters: Record<string, string> = {
    api: '1',
    travelmode: 'driving',
  }

  const [first, ...rest] = points
  const last = rest.pop()

  if (last) {
    parameters.origin = first
    parameters.destination = last
  } else {
    parameters.destination = first
  }

  if (rest.length > 0) {
    parameters.waypoints = rest.join('|')
  }

  return `https://www.google.com/maps/dir/?api=1&${new URLSearchParams(parameters).toString()}`
}

export const NavigateOnGoogle: NavigationRegister = {
  title: 'Google Maps',
  fn: navigateOnGoogleLink,
  supportedCount: 5,
}
