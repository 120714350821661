import gql from 'graphql-tag'
import { routeDate } from 'helpers/routeDate'
import { useMemo } from 'react'
import { Route } from 'schema'
import { useGetUseRouteDateDataQuery } from './__generated__/useRouteDate'

gql`
  query GetUseRouteDateData($routeId: Int!) {
    route(id: $routeId) {
      id
      startTimeLocal
      endTimeLocal
    }
  }
`

export const useRouteDate = (id?: Route['id'] | null) => {
  const { data } = useGetUseRouteDateDataQuery({
    skip: !id,
    fetchPolicy: 'cache-first',
    variables: {
      routeId: id || -1,
    },
  })

  const route = data?.route

  const date = useMemo(() => routeDate(route), [route])

  return date
}
