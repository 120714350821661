import { IonInput, useIonPicker } from '@ionic/react'
import { parseDatetime } from 'helpers/datetime'
import { roundTo } from 'helpers/rounding'
import { useToday } from 'hooks/useToday'
import { cloneDeep } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { useEffect, useMemo, useRef } from 'react'
import { UseControllerProps, useController } from 'react-hook-form'
import styled from 'styled-components'

type IonInputProps = React.ComponentProps<typeof IonInput>

const HiddenInput = styled.input`
  position: absolute !important;
  overflow: hidden !important;
  opacity: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
`

export type TimePickerProps =
  UseControllerProps<any, any> &
  Omit<IonInputProps, 'value' | 'onIonBlur'> & {
    showDay?: boolean
    startDate?: string
    endDate?: string
    timezone?: string
    initialScrollValue?: string
    minuteStep?: number
    onIonBlur?: (evt: Parameters<NonNullable<IonInputProps['onIonBlur']>>[0] | null) => void
  }

type PickerPresentArgs = Parameters<ReturnType<typeof useIonPicker>[0]>[0]

export const TimePicker = (props: TimePickerProps) => {
  const {
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    minuteStep,
    showDay,
    startDate,
    endDate,
    timezone: timezoneArg,
    initialScrollValue,
    ...inputProps
  } = props

  const today = useToday(timezoneArg)
  const timezone = today.zoneName
  const todayAsISO = today.toISODate()

  const ref = useRef<HTMLIonInputElement | null>(null)

  const {
    field: {
      ref: formFieldRef, onChange, value,
    },
  } = useController({
    control,
    defaultValue: defaultValue !== undefined ? defaultValue : '',
    name,
    rules,
    shouldUnregister,
  })

  useEffect(() => {
    formFieldRef({
      focus: () => { ref.current?.setFocus() },
    })
  }, [formFieldRef])

  const humanValue = useMemo(() => {
    if (!value) return ''
    const datetime = parseDatetime(value as string, timezone)

    let format = 'h:mm a'
    if (datetime.toISODate() !== todayAsISO) {
      format = `EEE MMM d, ${format}`
    }

    return datetime.toFormat(format)
  }, [todayAsISO, value])

  const pickerBaseConfig = useMemo(() => {
    const config: PickerPresentArgs = {
      columns: [],
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Done',
          handler: (selected) => {
            const [year, month, day] = (selected.date.value || DateTime.now().setZone(timezone).toISODate()).split('-').map(parseFloat)
            const meridian = selected.meridian.value
            const hour = meridian.toLowerCase() === 'pm' ? selected.hour.value + 12 : selected.hour.value
            const minute = selected.minute.value
            const dateTime = DateTime.local(year, month, day, hour, minute, { zone: timezone })
            onChange(dateTime.toUTC().toISO())
          },
        },
      ],
      onDidDismiss: () => {
        pickerIsVisible.current = false
        ref.current?.setBlur()
        if (inputProps.onIonBlur) {
          inputProps.onIonBlur(null)
        }
      },
    }

    if (showDay !== false) {
      const days: { text: string, value: string }[] = []

      const startDateTime = (
        startDate ? DateTime.fromISO(startDate) : today.minus({ days: 7 })
      ).setZone(timezone, { keepLocalTime: true }).startOf('day')
      const endDateTime = (
        endDate ? DateTime.fromISO(endDate) : today.plus({ days: 7 })
      ).setZone(timezone, { keepLocalTime: true }).endOf('day')

      const interval = Interval.fromDateTimes(startDateTime, endDateTime)

      let cursor = interval.start.startOf('day')
      while (cursor < interval.end) {
        const asISO = cursor.toISODate()

        if (todayAsISO === asISO) {
          days.push({
            text: 'Today',
            value: asISO,
          })
        } else {
          days.push({
            text: cursor.toFormat('EEE MMM d'),
            value: asISO,
          })
        }

        cursor = cursor.plus({ days: 1 })
      }

      if (days.length > 0) {
        config.columns.push({
          name: 'date',
          columnWidth: '140px',
          options: days,
        })
      }
    }

    config.columns.push({
      name: 'hour',
      columnWidth: '40px',
      options: generateHours().map((hour) => ({
        text: hour.toString(),
        value: hour === 12 ? 0 : hour,
      })),
    })

    config.columns.push({
      name: 'minute',
      columnWidth: '40px',
      options: generateMinutes(minuteStep).map((minute) => ({
        text: minute.toString().padStart(2, '0'),
        value: minute,
      })),
    })

    config.columns.push({
      name: 'meridian',
      columnWidth: '50px',
      options: [{
        value: 'AM',
        text: 'AM',
      }, {
        value: 'PM',
        text: 'PM',
      }],
    })

    return config
  }, [today, showDay, startDate, endDate, minuteStep])

  const pickerIsVisible = useRef<boolean>(false)

  const [presentIonPicker] = useIonPicker()

  const presentPicker = () => {
    ref.current?.setBlur()
    if (pickerIsVisible.current) return false

    const scrollTo = parseDatetime(value || initialScrollValue, timezone) || DateTime.utc().setZone(timezone)

    const config = cloneDeep(pickerBaseConfig)

    const parts: Record<string, any> = {
      hour: scrollTo.hour >= 12 ? scrollTo.hour - 12 : scrollTo.hour,
      minute: roundTo(scrollTo.minute, minuteStep || 1),
      meridian: scrollTo.toFormat('a'),
      date: scrollTo.toISODate(),
    }

    for (const column of config.columns) {
      const toFind = parts[column.name]
      const findItem = column.options.findIndex((item) => item.value === toFind)
      if (findItem > -1) {
        column.selectedIndex = findItem
      }
    }

    pickerIsVisible.current = true
    return presentIonPicker(config)
  }

  // HiddenInput handles the normal form control flow
  return (
    <>
      <HiddenInput
        onFocus={(evt) => {
          evt.target.blur()
          presentPicker()
        }}
      />

      <IonInput
        {...inputProps}
        readonly
        ref={ref}
        value={humanValue}
        onIonFocus={(evt) => {
          presentPicker()
          if (pickerIsVisible.current && inputProps.onIonFocus) {
            inputProps.onIonFocus(evt)
          }
        }}
        onIonBlur={(evt) => {
          if (inputProps.onIonBlur) {
            inputProps.onIonBlur(evt)
          }
        }}
        onIonChange={(evt) => {
          if (inputProps.onIonChange) {
            evt.detail.value = value
            inputProps.onIonChange(evt)
          }
        }}
      />
    </>
  )
}

const generateHours = () => {
  const arr = fillArray(1, 11)
  arr.unshift(12)
  return arr
}

const generateMinutes = (step = 1) => fillArray(0, 59, step)

const fillArray = (first: number, last: number, step = 1) => {
  const size = Math.ceil((last - first + 1) / step)
  const emptyArray = new Array(size).fill(true)
  return emptyArray.map((_, index) => first + (index * step))
}
