import { useOrganization } from 'hooks/useOrganization'

export type LogoProps = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'>

export const Logo = (props?: LogoProps) => {
  const organization = useOrganization()
  const logo = organization?.logo

  if (!logo) return null
  return <img width="45" {...props} src={logo} />
}
