import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { NavigateRouteButton, NavigateRouteButtonProps } from 'components/routes/NavigateRouteButton'
import gql from 'graphql-tag'
import { useGetNavigateAssignmentButtonQuery } from './__generated__/NavigateAssignmentButton'

gql`
  query GetNavigateAssignmentButton($assignmentId: Int!) {
    assignment(id: $assignmentId) {
      id
      route {
        ...NavigateRouteButtonData
      }
    }
  }
`

export const NavigateAssignmentButton = ({ id, ...rest }: NavigateRouteButtonProps) => {
  const { data, error } = useGetNavigateAssignmentButtonQuery({
    fetchPolicy: 'cache-first',
    variables: {
      assignmentId: id,
    },
  })

  if (error) {
    return <InlineErrorMessage message={`An error occured: ${error}`} />
  }

  const assignment = data?.assignment

  if (!assignment) {
    return null
  }

  const routeId = assignment.route?.id

  return !routeId ? null : (
    <NavigateRouteButton
      {...rest}
      id={routeId}
    />
  )
}
