import { RouterOutlet } from 'components/core'
import { useSession } from 'hooks/useSession'
import { useEffect } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { EnterPhonePage } from './EnterPhone'
import { ValidatePhonePage } from './Validate'
// import { SignInContextProvider } from './SignInContext'

export const Login = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const { loggedIn } = useSession()

  useEffect(() => {
    if (loggedIn) {
      return history.replace('/')
    }
  }, [])

  return (
    <RouterOutlet>
      <Route exact path={match.url} component={EnterPhonePage} />
      <Route exact path={`${match.url}/validate`} component={ValidatePhonePage} />
    </RouterOutlet>
  )
}
