import { gql } from '@apollo/client'
import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { Loading } from 'components/common/Loading'
import { TokenState, useSession } from 'hooks/useSession'
import { decode } from 'jsonwebtoken'
import { isEqual, isObject, omit } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { GetShortLinkQuery, useGetShortLinkQuery } from './__generated__/ShortUrl'

type ShortUrlProps = {
  slug: string
}

gql`
  query GetShortLink($where: QueryShortLinkWhereInput!) {
    shortLink(where: $where) {
      slug
      redirect
      expires
      authorization {
        token
      }
    }
  }
`

const VALID_ORIGINS = [
  window.location.origin,
  'https://crew.cretesuite.com',
  'https://preview.cretesuite.com',
]

const cleanLocalUrl = (url: string) => {
  const urlParts = new URL(url)
  const isLocal = VALID_ORIGINS.includes(urlParts.origin)
  if (!isLocal) return

  urlParts.hostname = window.location.hostname
  urlParts.port = window.location.port
  urlParts.protocol = window.location.protocol

  return urlParts.toString()
}

export const ShortUrl = ({ slug }: ShortUrlProps) => {
  const { token, setToken } = useSession()
  const [url, setUrl] = useState<string | null | undefined>(undefined)
  const initialToken = useRef(token)

  const { loading, error, data } = useGetShortLinkQuery({
    fetchPolicy: 'cache-first',
    variables: {
      where: {
        slug,
      },
    },
  })

  const processToken = (newTokenValue: string | null | undefined) => {
    if (!newTokenValue) return

    const currentToken = initialToken.current

    const newToken: TokenState = {
      value: newTokenValue,
      mode: currentToken?.mode || 'persistent',
    }

    // if we don't have auth yet, just use the one provided
    if (!currentToken) {
      setToken(newToken)
      return
    }

    try {
      const currentJWT = decode(currentToken.value)
      const newJWT = decode(newToken.value)

      if (isObject(currentJWT) && isObject(newJWT)) {
        const sameAuth = isEqual(
          omit(currentJWT, ['exp', 'iat']),
          omit(newJWT, ['exp', 'iat'])
        )

        // if auth is for same user, extend to longest exp
        if (sameAuth) {
          const longestAuth = (currentJWT.exp || 0) > (newJWT.exp || 0) ? currentToken : newToken
          setToken(longestAuth)
          return
          // if auth is NOT for current user, setToken only for this session
        }
        // TODO: this should session once history replacement works, see TODO below
        // https://github.com/ionic-team/ionic-framework/issues/23743
        //
        // newToken.mode = 'session'
        setToken(newToken)
      }
    } catch {
      setToken(newToken)
    }
  }

  const processUrl = (shortLink: NonNullable<GetShortLinkQuery['shortLink']>) => {
    let redirectUrl = shortLink.redirect

    if (!redirectUrl) {
      setUrl(null)
      return
    }

    if (redirectUrl.startsWith('https://m.cretesuite.com/operator/')) {
      const assignmentMatch = redirectUrl.match(/route\/\d+\/assignments\/(\d+)/)
      const redirectPath = assignmentMatch ? `/assignments/${assignmentMatch[1]}` : '/'
      redirectUrl = window.location.origin + redirectPath
    }

    const localUrl = cleanLocalUrl(redirectUrl)
    if (localUrl) {
      setUrl(localUrl)
    }

    window.location.replace(redirectUrl)
  }

  useEffect(() => {
    if (loading) return
    if (!data?.shortLink) {
      setUrl(null)
      return
    }

    processToken(data.shortLink?.authorization?.token)
    processUrl(data.shortLink)
  }, [loading, data?.shortLink])

  // TODO: this should be the redirect below
  // https://github.com/ionic-team/ionic-framework/issues/23743
  useEffect(() => {
    if (url) {
      window.location.replace(url)
    }
  }, [url])

  return (
    <>
      {
        // url ?
        //   <Redirect to={ url } />
        // : error ?
        error ? (
          <div className="ion-padding">
            <InlineErrorMessage message="Sorry, an error occurred. Please refresh the page and try again." />
          </div>
        )
          : url === null ? (
            <div className="ion-padding">
              <InlineErrorMessage message="Sorry, that link is not valid. Please contact us for assistance" />
            </div>
          )
            :
            <Loading enabled style={{ color: 'var(--ion-color-step-800)' }} />
      }
    </>
  )
}

export default ShortUrl
