import * as Types from 'schema';

import { gql } from '@apollo/client';
export type AddressFieldsSlimFragment = { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, timezone?: string | null };

export type AddressFieldsFragment = { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null };

export const AddressFieldsSlimFragmentDoc = gql`
    fragment AddressFieldsSlim on Address {
  id
  street
  street2
  city
  state
  timezone
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  id
  street
  street2
  city
  state
  lat
  lng
  timezone
}
    `;