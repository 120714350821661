import { useLoading } from 'hooks/useLoading'

import {
  Button,
  Buttons,
  Card,
  CardContent,
  Content,
  Header,
  Title,
  Toolbar,
} from 'components/core'

import { useCreation } from 'ahooks'
import { sortBy } from 'lodash'
import { DateTime } from 'luxon'
import { FC, useEffect, useMemo } from 'react'
import { TimeClockTasks, useTimeClockTasks } from '../../../hooks/timeclock/useTimeClockTasks'

type HOCProps = {
  tasks: TimeClockTasks
}

export const modalWithTasks = <P extends Record<any, any>>(Component: FC<P & HOCProps>) => (props: P) => {
  const { showLoading, hideLoading } = useLoading()

  const asOf = useCreation(() => DateTime.utc().toISO(), [])
  const { data, error, loading } = useTimeClockTasks({
    variables: {
      noteRequired: {
        asOf,
      },
    },
  })
  const tasks = useMemo(() => sortBy(data || [], 'sort'), [data])

  useEffect(() => {
    if (loading) {
      showLoading()
    } else {
      hideLoading()
    }
  }, [loading])

  if (!loading) {
    return <Component {...props} tasks={tasks} />
  }

  return (
    <>
      <Header>
        <Toolbar>
          <Title>Error</Title>
          <Buttons slot="end">
            <Button onClick={props.onDismiss}> Close </Button>
          </Buttons>
        </Toolbar>
      </Header>
      <Content fullscreen>
        {error && (
          <>
            <Card>
              <CardContent>
                Tasks could not be loaded. Please ensure you are online. If this issue persists, please contact your admistrator.
                {error.message}
              </CardContent>
            </Card>

            <Button onClick={props.onDismiss} expand='block'>Close</Button>
          </>
        )}
      </Content>
    </>
  )
}
