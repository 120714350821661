import { QueryResult } from '@apollo/client'
import { useMemo } from 'react'

type Transformer<TData, NewData> = (data: TData | undefined) => NewData | undefined

type TransformedQueryResult<NewData, TData, TVariables> = (
  Omit<QueryResult<TData, TVariables>, 'data' | 'previousData'> &
  Pick<QueryResult<NewData, TVariables>, 'data' | 'previousData'>
)

export const useQueryResultTransformer = <TData, TVariables, NewData>(
  result: QueryResult<TData, TVariables>,
  transformer: Transformer<TData, NewData>
): TransformedQueryResult<NewData, TData, TVariables> => ({
  ...result,
  data: useMemo(() => transformer(result.data), [result.data]),
  previousData: useMemo(() => transformer(result.previousData), [result.previousData]),
})
