import { pickBy } from 'lodash'
import { Address } from 'schema'
import { NavigateOnApple } from './navigateOnAppleLink'
import { NavigateOnGoogle } from './navigateOnGoogleLink'
import { NavigateOnHammer } from './navigateOnHammerLink'
import { NavigateOnWaze } from './navigateOnWaze'
import { NavigationRegister } from './types'

export type NavigationApp = 'apple' | 'google' | 'hammer' | 'waze'

export const NAVIGATORS: Record<NavigationApp, NavigationRegister> = {
  apple: NavigateOnApple,
  google: NavigateOnGoogle,
  hammer: NavigateOnHammer,
  waze: NavigateOnWaze,
}

export const navigators = () => (
  pickBy(NAVIGATORS, (register) => {
    if (!register.available) return true
    return register.available()
  }) as typeof NAVIGATORS
)

export const getNavigator = (appPreference: NavigationApp = 'google') => NAVIGATORS[appPreference]

export const defaultNavigationApp = () => 'google'

export const navigateLink = (addresses: Partial<Address>[], appPreference: NavigationApp = 'google') => NAVIGATORS[appPreference].fn(addresses)
