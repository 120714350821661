import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodEstimateQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetCodEstimateQuery = { __typename?: 'Query', estimateInvoice?: { __typename?: 'InvoiceEstimate', id: string, total: number } | null };


export const GetCodEstimateDocument = gql`
    query GetCODEstimate($id: Int!) {
  estimateInvoice(where: {id: $id}) {
    id
    total
  }
}
    `;

/**
 * __useGetCodEstimateQuery__
 *
 * To run a query within a React component, call `useGetCodEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodEstimateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCodEstimateQuery(baseOptions: Apollo.QueryHookOptions<GetCodEstimateQuery, GetCodEstimateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodEstimateQuery, GetCodEstimateQueryVariables>(GetCodEstimateDocument, options);
      }
export function useGetCodEstimateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodEstimateQuery, GetCodEstimateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodEstimateQuery, GetCodEstimateQueryVariables>(GetCodEstimateDocument, options);
        }
export type GetCodEstimateQueryHookResult = ReturnType<typeof useGetCodEstimateQuery>;
export type GetCodEstimateLazyQueryHookResult = ReturnType<typeof useGetCodEstimateLazyQuery>;
export type GetCodEstimateQueryResult = Apollo.QueryResult<GetCodEstimateQuery, GetCodEstimateQueryVariables>;
export function refetchGetCodEstimateQuery(variables: GetCodEstimateQueryVariables) {
      return { query: GetCodEstimateDocument, variables: variables }
    }