import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignmentCardSlimDataFragment = { __typename?: 'Assignment', id: number, acknowledged?: boolean | null, active: boolean, route?: { __typename?: 'Route', id: number, status: Types.RouteStatus, active: boolean, startTimeLocal: Types.Scalars['DateTime'], endTimeLocal: Types.Scalars['DateTime'], equipment?: { __typename?: 'Equipment', id: number, name: string } | null, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, scheduledArrivalTime?: Types.Scalars['DateTime'] | null, order?: { __typename?: 'Order', id: number, status: string } | null }> } | null };

export type GetAssignmentCardSlimDataQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['Int'];
}>;


export type GetAssignmentCardSlimDataQuery = { __typename?: 'Query', assignment?: { __typename?: 'Assignment', id: number, acknowledged?: boolean | null, active: boolean, route?: { __typename?: 'Route', id: number, status: Types.RouteStatus, active: boolean, startTimeLocal: Types.Scalars['DateTime'], endTimeLocal: Types.Scalars['DateTime'], equipment?: { __typename?: 'Equipment', id: number, name: string } | null, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, scheduledArrivalTime?: Types.Scalars['DateTime'] | null, order?: { __typename?: 'Order', id: number, status: string } | null }> } | null } | null };

export const AssignmentCardSlimDataFragmentDoc = gql`
    fragment AssignmentCardSlimData on Assignment {
  id
  acknowledged
  active
  route {
    id
    status
    active
    startTimeLocal
    endTimeLocal
    equipment {
      id
      name
    }
    waypoints {
      id
      scheduledArrivalTime
      order {
        id
        status
      }
    }
  }
}
    `;
export const GetAssignmentCardSlimDataDocument = gql`
    query GetAssignmentCardSlimData($assignmentId: Int!) {
  assignment(id: $assignmentId) {
    ...AssignmentCardSlimData
  }
}
    ${AssignmentCardSlimDataFragmentDoc}`;

/**
 * __useGetAssignmentCardSlimDataQuery__
 *
 * To run a query within a React component, call `useGetAssignmentCardSlimDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentCardSlimDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentCardSlimDataQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetAssignmentCardSlimDataQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentCardSlimDataQuery, GetAssignmentCardSlimDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentCardSlimDataQuery, GetAssignmentCardSlimDataQueryVariables>(GetAssignmentCardSlimDataDocument, options);
      }
export function useGetAssignmentCardSlimDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentCardSlimDataQuery, GetAssignmentCardSlimDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentCardSlimDataQuery, GetAssignmentCardSlimDataQueryVariables>(GetAssignmentCardSlimDataDocument, options);
        }
export type GetAssignmentCardSlimDataQueryHookResult = ReturnType<typeof useGetAssignmentCardSlimDataQuery>;
export type GetAssignmentCardSlimDataLazyQueryHookResult = ReturnType<typeof useGetAssignmentCardSlimDataLazyQuery>;
export type GetAssignmentCardSlimDataQueryResult = Apollo.QueryResult<GetAssignmentCardSlimDataQuery, GetAssignmentCardSlimDataQueryVariables>;
export function refetchGetAssignmentCardSlimDataQuery(variables: GetAssignmentCardSlimDataQueryVariables) {
      return { query: GetAssignmentCardSlimDataDocument, variables: variables }
    }