import { formatPhone } from 'helpers/formatPhone'
import { Phone } from 'schema'
import styled from 'styled-components'

export type PhoneNumberProps = React.HTMLAttributes<HTMLElement> & {
  phone: Pick<Phone, 'number'>
  clickable?: boolean
}

export const PhoneNumber = styled((props: PhoneNumberProps) => {
  const { clickable, phone, ...rest } = props
  const { number } = phone
  if (!number) return null

  const formatted = formatPhone(number)

  return (
    <a
      {...rest}
      href={clickable ? `tel:${number}` : undefined}
    >
      {formatted}
    </a>
  )
})`
  text-decoration: none;
`
