import {
  IonButton,
  IonCol, IonDatetime, IonGrid, IonIcon, IonLabel, IonRow, useIonAlert,
} from '@ionic/react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { search } from 'ionicons/icons'
import styled from 'styled-components'
import { DatetimeRange } from 'helpers/datetime'

const Separator = styled.div`
  width: 10px;
  text-align: center;
  margin-top: 10px;
`
const DateInput = styled(IonDatetime)`
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px; 
  --padding-bottom: 10px;
`

interface DateRangePickerProps {
  dateRange: DatetimeRange;
  setDateRange: (val: DatetimeRange) => void;
  showSearchButton?: boolean;
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const { dateRange, setDateRange, showSearchButton } = props

  const [currentDateRange, setCurrentDateRange] = useState<DatetimeRange>(dateRange)
  const [startDate, setStartDate] = useState<DateTime>(dateRange.startDate)
  const [endDate, setEndDate] = useState<DateTime>(dateRange.endDate)

  const [presentAlert] = useIonAlert()

  const minYear = '2020'
  const maxYear = DateTime.local().toFormat('yyyy')

  const alertProps = {
    header: 'Invalid Date Range',
    message: 'Start date must be before end date',
    buttons: ['OK'],
  }

  useEffect(() => {
    if (startDate > endDate) {
      presentAlert(alertProps)
      setStartDate(currentDateRange.startDate)
      setEndDate(currentDateRange.endDate)
    } else if (!currentDateRange.startDate.equals(startDate) || !currentDateRange.endDate.equals(endDate)) {
      setDateRange({ startDate, endDate })
      setCurrentDateRange({ startDate, endDate })
    } else {
      setCurrentDateRange({ startDate, endDate })
    }
  }, [startDate, endDate])

  return (

    <IonGrid>
      <IonRow>
        <IonCol size='12' size-sm="1.3">
          <IonLabel>
            <h2 style={{ margin: '9px 10px 0px 10px' }}>
              <strong>Dates</strong>
            </h2>
          </IonLabel>
        </IonCol>
        <IonCol size='10' size-sm="8">
          <div style={{ display: 'flex', marginLeft: '5px' }}>
            <DateInput
              displayFormat="MM/DD/YYYY"
              pickerFormat="MMMM DD YYYY"
              min={minYear}
              max={maxYear}
              value={startDate.toISO()}
              onIonChange={(e) => { if (e.detail.value) { setStartDate(DateTime.fromISO(e.detail.value)) } }}
            />
            <Separator> - </Separator>
            <DateInput
              displayFormat="MM/DD/YYYY"
              pickerFormat="MMMM DD YYYY"
              min={minYear}
              max={maxYear}
              value={endDate.toISO()}
              onIonChange={(e) => { if (e.detail.value) { setEndDate(DateTime.fromISO(e.detail.value)) } }}
            />
          </div>
        </IonCol>
        {
          showSearchButton &&
          (
            <IonCol size='2' size-sm="2.7" style={{ textAlign: 'right' }}>
              <IonButton
                size="small"
                color="none"
                onClick={() => setDateRange({ startDate, endDate })}
              >
                <IonIcon
                  icon={search}
                  color="primary"
                />
              </IonButton>
            </IonCol>
          )
        }
      </IonRow>
    </IonGrid>
  )
}

export default DateRangePicker
