import { uniq } from 'lodash'
import { formatAddress } from '../formatAddress'
import { NavigateFunction, NavigationRegister } from './types'

export const navigateOnHammerLink: NavigateFunction = (addresses) => {
  if (addresses.length === 0) return null

  const points = uniq(
    addresses.map((address) => {
      if (address.lat && address.lng) {
        return [address.lat, address.lng].join(',')
      }
      return formatAddress(address)
    })
  )

  if (points.length === 1) {
    points.unshift('current-location')
  }

  return `https://hammerapp.com/app/route/${points.join('/')}/`
}

export const NavigateOnHammer: NavigationRegister = {
  title: 'HAMMER',
  fn: navigateOnHammerLink,
  supportedCount: 5,
}
