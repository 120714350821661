import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShortLinkQueryVariables = Types.Exact<{
  where: Types.QueryShortLinkWhereInput;
}>;


export type GetShortLinkQuery = { __typename?: 'Query', shortLink?: { __typename?: 'ShortLink', slug: string, redirect: string, expires?: Types.Scalars['DateTime'] | null, authorization?: { __typename?: 'Authorization', token?: string | null } | null } | null };


export const GetShortLinkDocument = gql`
    query GetShortLink($where: QueryShortLinkWhereInput!) {
  shortLink(where: $where) {
    slug
    redirect
    expires
    authorization {
      token
    }
  }
}
    `;

/**
 * __useGetShortLinkQuery__
 *
 * To run a query within a React component, call `useGetShortLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortLinkQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetShortLinkQuery(baseOptions: Apollo.QueryHookOptions<GetShortLinkQuery, GetShortLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShortLinkQuery, GetShortLinkQueryVariables>(GetShortLinkDocument, options);
      }
export function useGetShortLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShortLinkQuery, GetShortLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShortLinkQuery, GetShortLinkQueryVariables>(GetShortLinkDocument, options);
        }
export type GetShortLinkQueryHookResult = ReturnType<typeof useGetShortLinkQuery>;
export type GetShortLinkLazyQueryHookResult = ReturnType<typeof useGetShortLinkLazyQuery>;
export type GetShortLinkQueryResult = Apollo.QueryResult<GetShortLinkQuery, GetShortLinkQueryVariables>;
export function refetchGetShortLinkQuery(variables: GetShortLinkQueryVariables) {
      return { query: GetShortLinkDocument, variables: variables }
    }