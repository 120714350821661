/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment TimeSheetHistory_TimeSheet on TimeSheet {\n    id\n    shifts {\n      id\n      startTime\n      endTime\n      timeClocks {\n        id\n        startTime\n        endTime\n        task {\n          id\n          name\n          sort\n          color\n        }\n      }\n    }\n  }\n": types.TimeSheetHistory_TimeSheetFragmentDoc,
    "\n  fragment TimeClockDetail_TimeClock on TimeClock {\n    id\n    startTime\n    endTime\n    task {\n      id\n      name\n      color\n    }\n  }\n": types.TimeClockDetail_TimeClockFragmentDoc,
    "\n  fragment TimeClockDetail_TimeClockShift on TimeClockShift {\n    id\n    startTime\n    endTime\n    timeClocks {\n      id\n      startTime\n      endTime\n      task {\n        id\n        name\n        color\n      }\n    }\n  }\n": types.TimeClockDetail_TimeClockShiftFragmentDoc,
    "\n  fragment UseTimeClock_TimeClock on TimeClock {\n    id\n    startTime\n    endTime\n    task {\n      id\n      name\n      payable\n    }\n    shift {\n      id\n      startTime\n      endTime\n    }\n  }\n": types.UseTimeClock_TimeClockFragmentDoc,
    "\n  query GetTimeClock {\n    me {\n      id\n      ... on Operator {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n": types.GetTimeClockDocument,
    "\n  query GetTimeClockTasks($where: QueryTimeClockTasksWhereInput, $noteRequired: TimeClockIsNotRequiredWhereInput) {\n    timeClockTasks(where: $where) {\n      id\n      name\n      isNoteRequired(where: $noteRequired)\n      noteRequired\n      payable\n      sort\n    }\n  }\n": types.GetTimeClockTasksDocument,
    "\n  mutation ChangeTask($data: TimesheetChangeTaskInput!) {\n    timeClock {\n      changeTask(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n": types.ChangeTaskDocument,
    "\n  mutation ClockIn($data: TimesheetClockInInput!) {\n    timeClock {\n      clockIn(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n": types.ClockInDocument,
    "\n  mutation ClockOut($data: TimesheetClockOutInput!) {\n    timeClock {\n      clockOut(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n": types.ClockOutDocument,
    "\n  query GetTimeSheet ( $where: PersonTimeSheetWhereInput) {\n    me {\n      id\n      ... on Operator {\n        timeSheet( where: $where ) {\n          id\n          startTime\n          endTime\n          ...TimeSheetHistory_TimeSheet\n        }\n      }\n    }\n  }\n": types.GetTimeSheetDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TimeSheetHistory_TimeSheet on TimeSheet {\n    id\n    shifts {\n      id\n      startTime\n      endTime\n      timeClocks {\n        id\n        startTime\n        endTime\n        task {\n          id\n          name\n          sort\n          color\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TimeSheetHistory_TimeSheet on TimeSheet {\n    id\n    shifts {\n      id\n      startTime\n      endTime\n      timeClocks {\n        id\n        startTime\n        endTime\n        task {\n          id\n          name\n          sort\n          color\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TimeClockDetail_TimeClock on TimeClock {\n    id\n    startTime\n    endTime\n    task {\n      id\n      name\n      color\n    }\n  }\n"): (typeof documents)["\n  fragment TimeClockDetail_TimeClock on TimeClock {\n    id\n    startTime\n    endTime\n    task {\n      id\n      name\n      color\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TimeClockDetail_TimeClockShift on TimeClockShift {\n    id\n    startTime\n    endTime\n    timeClocks {\n      id\n      startTime\n      endTime\n      task {\n        id\n        name\n        color\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TimeClockDetail_TimeClockShift on TimeClockShift {\n    id\n    startTime\n    endTime\n    timeClocks {\n      id\n      startTime\n      endTime\n      task {\n        id\n        name\n        color\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UseTimeClock_TimeClock on TimeClock {\n    id\n    startTime\n    endTime\n    task {\n      id\n      name\n      payable\n    }\n    shift {\n      id\n      startTime\n      endTime\n    }\n  }\n"): (typeof documents)["\n  fragment UseTimeClock_TimeClock on TimeClock {\n    id\n    startTime\n    endTime\n    task {\n      id\n      name\n      payable\n    }\n    shift {\n      id\n      startTime\n      endTime\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTimeClock {\n    me {\n      id\n      ... on Operator {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTimeClock {\n    me {\n      id\n      ... on Operator {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTimeClockTasks($where: QueryTimeClockTasksWhereInput, $noteRequired: TimeClockIsNotRequiredWhereInput) {\n    timeClockTasks(where: $where) {\n      id\n      name\n      isNoteRequired(where: $noteRequired)\n      noteRequired\n      payable\n      sort\n    }\n  }\n"): (typeof documents)["\n  query GetTimeClockTasks($where: QueryTimeClockTasksWhereInput, $noteRequired: TimeClockIsNotRequiredWhereInput) {\n    timeClockTasks(where: $where) {\n      id\n      name\n      isNoteRequired(where: $noteRequired)\n      noteRequired\n      payable\n      sort\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ChangeTask($data: TimesheetChangeTaskInput!) {\n    timeClock {\n      changeTask(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ChangeTask($data: TimesheetChangeTaskInput!) {\n    timeClock {\n      changeTask(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClockIn($data: TimesheetClockInInput!) {\n    timeClock {\n      clockIn(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ClockIn($data: TimesheetClockInInput!) {\n    timeClock {\n      clockIn(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClockOut($data: TimesheetClockOutInput!) {\n    timeClock {\n      clockOut(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ClockOut($data: TimesheetClockOutInput!) {\n    timeClock {\n      clockOut(data: $data) {\n        timeClock {\n          ...UseTimeClock_TimeClock\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTimeSheet ( $where: PersonTimeSheetWhereInput) {\n    me {\n      id\n      ... on Operator {\n        timeSheet( where: $where ) {\n          id\n          startTime\n          endTime\n          ...TimeSheetHistory_TimeSheet\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTimeSheet ( $where: PersonTimeSheetWhereInput) {\n    me {\n      id\n      ... on Operator {\n        timeSheet( where: $where ) {\n          id\n          startTime\n          endTime\n          ...TimeSheetHistory_TimeSheet\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;