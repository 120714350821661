import { uniq } from 'lodash'
import { formatAddress } from '../formatAddress'
import { NavigateFunction, NavigationRegister } from './types'

export const navigateOnWaze: NavigateFunction = (addresses) => {
  if (addresses.length === 0) return null

  const points = uniq(
    addresses.map((address) => {
      if (address.lat && address.lng) {
        return { ll: [address.lat, address.lng].join(',') }
      }

      return { q: formatAddress(address) }
    })
  )

  if (points.length > 1) {
    return null
  }

  const parameters: Record<string, any> = {
    navigate: 'yes',
    ...points[0],
  }

  return `https://waze.com/ul?${new URLSearchParams(parameters).toString()}`
}

export const NavigateOnWaze: NavigationRegister = {
  title: 'Waze',
  fn: navigateOnWaze,
  supportedCount: 1,
}
