import { formatAssignmentLeaveTime } from 'helpers/formatAssignmentLeaveTime'
import { navigate, time as timeIcon } from 'ionicons/icons'
import { useFeature } from '../../../hooks/useFeatures'
import { DetailWithIcon, PresetDetailProps } from './DetailWithIcon'

export const LeaveTimeDetail = (
  { assignment, ...props }: PresetDetailProps & { assignment: Parameters<typeof formatAssignmentLeaveTime>[0] }
) => {
  const [reportAtTimeEnabled] = useFeature('assignment.showReportAtTime')

  return (
    <DetailWithIcon
      {...props}
      expectedWidth={100}
      header="Depart"
      body={formatAssignmentLeaveTime(assignment)}
      icon={reportAtTimeEnabled ? navigate : timeIcon}
    />
  )
}
