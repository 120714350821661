import { useNetwork } from 'ahooks'
import { useEffect } from 'react'
import { useToast } from '../../hooks/useToast'

export const OfflineBanner = (props: React.PropsWithChildren<any>) => {
  const network = useNetwork()
  const [present, dismiss] = useToast()

  useEffect(() => {
    if (network.online === false) {
      present({
        header: 'No Internet',
        color: 'warning',
        message: 'It appears you are not connected to the Internet. <br />Please reconnect to receive the latest updates.',
      })
    } else {
      dismiss()
    }
  }, [network.online])

  return (
    <>
      {props.children}
    </>
  )
}
