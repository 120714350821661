const USER_POOL_ID = 'us-east-2_aMKXsW6ey'
const CLIENT_ID = '4oq12jnk4kvokbmbhm2rvid2mo'

class CognitoError extends Error {
  type?: string

  constructor(message: string, type?: string) {
    super(message)
    this.type = type
    Object.setPrototypeOf(this, CognitoError.prototype)
  }
}

export const initiateAuth = async (phone: string) => {
  const username = phone

  const doIniateAuth = () => cognitoRequest({
    method: 'POST',
    headers: {
      'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
    },
    body: JSON.stringify({
      AuthFlow: 'CUSTOM_AUTH',
      AuthParameters: {
        USERNAME: username,
      },
      ClientId: CLIENT_ID,
      ClientMetadata: {},
    }),
  })

  try {
    return await doIniateAuth()
  } catch (err) {
    const response = await cognitoRequest({
      method: 'POST',
      headers: {
        'x-amz-target': 'AWSCognitoIdentityProviderService.SignUp',
      },
      body: JSON.stringify({
        AuthFlow: 'CUSTOM_AUTH',
        ClientId: CLIENT_ID,
        ClientMetadata: {},
        Password: (Math.ceil(Math.random() * 100000000) + 10000000).toString(),
        Username: username,
        UserAttributes: [
          {
            Name: 'phone_number',
            Value: phone,
          },
        ],
      }),
    })

    if (response?.UserConfirmed) {
      return doIniateAuth()
    }

    throw new Error('Invalid phone number')
  }
}

interface validateCodeProps {
  username: string
  code: string
  session?: string
}

export const validateCode = async ({ username, code, session }: validateCodeProps) => cognitoRequest({
  method: 'POST',
  headers: {
    'x-amz-target': 'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
  },
  body: JSON.stringify({
    ClientId: CLIENT_ID,
    ChallengeName: 'CUSTOM_CHALLENGE',
    ChallengeResponses: {
      USERNAME: username,
      ANSWER: code,
    },
    Session: session,
  }),
})

const cognitoRequest = async (options: RequestInit) => {
  const region = USER_POOL_ID.split('_')[0]

  const response = await fetch(`https://cognito-idp.${region}.amazonaws.com/`, {
    ...options,
    headers: {
      accept: '*/*',
      'accept-language': 'en-US,en;q=0.9',
      'cache-control': 'max-age=0',
      'content-type': 'application/x-amz-json-1.1',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'cross-site',
      'x-amz-user-agent': 'aws-amplify/5.0.4 js',
      ...(options.headers || {}),
    },
  })

  if (!response.ok) {
    const errBody = await response.text()
    let error: CognitoError

    try {
      const asJson = JSON.parse(errBody)
      error = new CognitoError(asJson.message, asJson.__type__)
    } catch {
      error = new CognitoError(`Cognito error: ${errBody}`)
    }
    throw error
  }

  return response.json()
}
