import { gql } from '@apollo/client'
import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { Link } from 'components/links/Link'
import { linkForWaypointDetails } from 'helpers/links/linkForWaypointDetails'
import React from 'react'
import { useLocation } from 'react-router'
import { Route } from 'schema'
import styled from 'styled-components'
import { ManualRouteProgressButton } from './ManualRouteProgressButton'
import { WaypointCard } from './WaypointCard'
import { WaypointNumber } from './WaypointNumber'
import { GetRouteOverviewDataQuery, useGetRouteOverviewDataQuery } from './__generated__/RouteOverview'

const WaypointsWrapper = styled.ul`
  margin: 0;
  padding: var(--ion-padding, 16px);
  width: 100%;

  --wp-current-color: var(--ion-color-primary);
  --wp-vertical-padding: 5px;
`

const WaypointCardStyled = styled(WaypointCard)`
  flex: 12 1 0;
  min-width: 0;
  margin: 0;
  margin-top: var(--wp-vertical-padding);
  margin-bottom: var(--wp-vertical-padding);
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
`

const WaypointTimelineWrap = styled.div`
  flex: 0 0 auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > * {
    flex: 0 0 auto;
  }

  &:before, &:after {
    flex-basis: var(--wp-vertical-padding);
    content: '';
    flex-grow: 1;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--wp-current-color);
  }
`

const WaypointListItemWrapper = styled.li`
  list-style: none;

  &:first-child {
    ${WaypointTimelineWrap} {
      &:before {
        border-left-width: 0px !important;
      }
    }
  }

  &:last-child {
    ${WaypointTimelineWrap} {
      &:after {
        border-left-width: 0px !important;
      }
    }
  }
`

const WaypointHorizontalConnector = styled.div`
  flex: 1 0 0;
  min-width: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--wp-current-color);
`

const WaypointItem = styled.div`
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: center;

  &.status-inProgress {
    ${WaypointCardStyled} {
      border-color: var(--wp-current-color);
    }
    ${WaypointTimelineWrap} {
      &:before {
        border-left-width: 3px;
      }
    }
    ${WaypointHorizontalConnector} {
      border-top-width: 3px;
    }
  }

  &.status-completed {
    ${WaypointCardStyled} {
      opacity: 0.7;
    }
    ${WaypointTimelineWrap} {
      &:before, &:after {
        border-left-width: 3px;
      }
    }
    ${WaypointHorizontalConnector} {
      border-top-width: 3px;
    }
  }
`

gql`
  fragment RouteOverviewData on Route {
    id
    active
    waypoints {
      ...WaypointCardSlimData
      address {
        ...AddressFields
      }
    }
  }
`

gql`
  query GetRouteOverviewData($routeId: Int!) {
    route(id: $routeId) {
      ...RouteOverviewData
    }
  }
`

type RouteData = NonNullable<GetRouteOverviewDataQuery['route']>
type WaypointData = NonNullable<RouteData['waypoints'][0]>

export interface RouteOverviewProps extends Omit<React.HTMLAttributes<HTMLUListElement>, 'id'> {
  id: Route['id']
  disableDetailLinks?: boolean
  hideNavigate?: boolean
}

export const RouteOverview = ({
  id,
  disableDetailLinks,
  hideNavigate,
  ...rest
}: RouteOverviewProps) => {
  const { data, loading: loadingRaw, error } = useGetRouteOverviewDataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      routeId: id,
    },
  })

  const route = data?.route
  const loading = !data && loadingRaw
  const notFound = loading === false && !route

  const waypoints = route?.waypoints || []

  if (error) {
    return <InlineErrorMessage message={`An error occured: ${error}`} />
  }
  if (notFound) {
    return <InlineErrorMessage message="An error occured while fetching the route" />
  }

  return (
    <>
      {hideNavigate !== true && route?.id && (
        <>
          <ManualRouteProgressButton id={route.id} />
        </>
      )}

      <WaypointsWrapper {...rest}>
        {waypoints.map((waypoint, index) => (
          <WaypointListItem
            key={waypoint.id}
            waypoint={waypoint}
            currentIndex={index}
            totalWaypointsCount={waypoints.length}
            disableDetailLinks={disableDetailLinks}
          />
        ))}
      </WaypointsWrapper>
    </>
  )
}

const WaypointListItem = ({
  waypoint,
  totalWaypointsCount,
  currentIndex,
  disableDetailLinks,
}: {
  waypoint: WaypointData,
  totalWaypointsCount: number,
  currentIndex: number,
  disableDetailLinks?: boolean
}) => {
  const location = useLocation()
  const detailsPath = linkForWaypointDetails(waypoint)
  const linkPath = detailsPath ? location.pathname + detailsPath : null
  const link = (disableDetailLinks || !linkPath) ? undefined : linkPath

  return (
    <WaypointListItemWrapper>
      <Link to={link} className="no-style">
        <WaypointItem className={`status-${waypoint.status}`}>
          <WaypointTimelineWrap>
            <WaypointNumber
              index={currentIndex}
              total={totalWaypointsCount}
              status={waypoint.status}
            />
          </WaypointTimelineWrap>
          <WaypointHorizontalConnector />
          <WaypointCardStyled id={waypoint.id} link={link || false} />
        </WaypointItem>
      </Link>
    </WaypointListItemWrapper>
  )
}
