import styled from 'styled-components'

export const DetailsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  > * {
    max-width: 50%;
    padding: 0 12px;
    min-width: 0px;
    flex: 0 1 auto;
  }
`
