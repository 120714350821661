import 'cross-fetch/polyfill'

import {
  IonApp,
  setupConfig,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'

/* Theme variables and overrides */
import 'assets/styles/theme/overrides.css'
import 'assets/styles/theme/variables.css'

import 'assets/styles/app.css'

import { useUpdateEffect } from 'ahooks'
import { ApolloProvider } from 'apollo'
import { OfflineBanner } from 'components/common/OfflineBanner'
import { EagerLoadData } from 'components/data/EagerLoadData'
import { LoadingProvider } from 'hooks/useLoading'
import { SessionProvider } from 'hooks/useSession'
import { useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Main } from './Main'
import { PWAManager } from './PWAManager'
import { useAppUpdate } from './hooks/useAppUpdate'
import { RouterOutletSiblingTeleporter } from './teleporters/RouterOutletSiblingTeleporter'

setupConfig({
  mode: 'ios',
  scrollAssist: false,
})

const AutoUpdateOnLocationChange = () => {
  const history = useHistory()
  const location = useLocation()
  const update = useAppUpdate()
  const updating = useRef<boolean>(false)
  useUpdateEffect(() => {
    if (updating.current) return
    if (update.available) {
      console.log('will update')
      updating.current = true
      update.load().then(() => {
        history.go(0)
      }).catch(() => { })
    }
  }, [location])
  return null
}

const App: React.FC = () => (
  <PWAManager>
    <IonApp>
      <LoadingProvider>
        <OfflineBanner>
          <ApolloProvider>
            <SessionProvider>
              <IonReactRouter>
                <AutoUpdateOnLocationChange />
                <EagerLoadData>
                  <RouterOutletSiblingTeleporter.Target />
                  <Main />
                </EagerLoadData>
              </IonReactRouter>
            </SessionProvider>
          </ApolloProvider>
        </OfflineBanner>
      </LoadingProvider>
    </IonApp>
  </PWAManager>
)

export default App
