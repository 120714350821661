import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { RouteOverview } from 'components/routes/RouteOverview'
import gql from 'graphql-tag'
import { assignmentStatus } from 'helpers/assignmentStatus'
import React from 'react'
import styled from 'styled-components'
import { useFeature } from '../../hooks/useFeatures'
import { useGetAssignmentRouteOverviewQuery } from './__generated__/AssignmentRouteOverview'

gql`
  fragment AssignmentRouteOverviewData on Assignment {
    id
    active
    acknowledged
    route {
      id
      active
      status
    }
  }
`

gql`
  query GetAssignmentRouteOverview($assignmentId: Int!) {
    assignment(id: $assignmentId) {
      ...AssignmentRouteOverviewData
    }
  }
`

const MustAcknowledgeInterstitial = styled.div.attrs({
  children: <>You must first acknowledge your  <br /> assignment to view order details.</>,
})`
  margin: 0 auto;
  margin-top: 40px;
  padding: var(--ion-padding, 16px);
  text-align: center;
  line-height: 1.8;
  font-size: 14px;
  font-weight: 700;
  opacity: 0.6;
`

export const AssignmentRouteOverview = ({ id, ...rest }: React.ComponentProps<typeof RouteOverview>) => {
  const [hideIfUnacknowledged] = useFeature('assignment.hideDetailsTillAcknowledgement')
  const { data, error } = useGetAssignmentRouteOverviewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      assignmentId: id,
    },
  })

  if (error) {
    return <InlineErrorMessage message={`An error occured: ${error}`} />
  }

  const assignment = data?.assignment

  if (!assignment) {
    return null
  }

  const routeId = assignment.route?.id

  const status = assignmentStatus(assignment)
  const isCancelled = status.slug === 'cancelled'

  if (
    hideIfUnacknowledged &&
    status.needsAcknowledgement &&
    !isCancelled &&
    !status.inProgress
  ) {
    return <MustAcknowledgeInterstitial />
  }

  return !routeId ? null : (
    <RouteOverview
      {...rest}
      id={routeId}
      disableDetailLinks={isCancelled}
      hideNavigate={status.needsAcknowledgement || isCancelled}
    />
  )
}
