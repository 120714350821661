import { useSession } from 'hooks/useSession'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useToast } from '../../hooks/useToast'

export const Logout = () => {
  const { logout } = useSession()
  const [presentToast] = useToast()
  const history = useHistory()

  useEffect(() => {
    logout()

    presentToast({
      color: 'success',
      message: 'You have been logged out',
      duration: 3000,
    })

    history.replace('/auth/login')
  }, [])

  return null
}
