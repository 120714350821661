import { compact } from 'lodash'
import { Address } from 'schema'

export interface FormatAddressOptions {
  multiline?: boolean
  includeStreet2?: boolean
}

function nullIfEmpty<T>(array: T[] | null | undefined): T[] | null {
  if (!array || array.length === 0) {
    return null
  }
  return array
}

export const formatAddressLines = (address: Partial<Omit<Address, '__typename'>>, opts: FormatAddressOptions = {}) => {
  const line1 = nullIfEmpty(compact([
    address.street,
    opts.includeStreet2 ? address.street2 : null,
  ]))?.join(' ')

  const line2 = nullIfEmpty(compact([
    address.city,
    nullIfEmpty(compact([
      address.state,
      address.zip,
    ]))?.join(' '),
  ]))?.join(', ')

  return compact(
    opts.multiline ?
      compact([line1, line2]) :
      [nullIfEmpty(compact([line1, line2]))?.join(', ')]
  )
}

export const formatAddress = (address: Partial<Omit<Address, '__typename'>>, opts: FormatAddressOptions = {}) => formatAddressLines(address, opts).join('\n')
