import { SkeletonText } from 'components/core'
import { AssignmentStatus } from 'helpers/assignmentStatus'
import { formatUserRelativeTime } from 'helpers/datetime'
import { compact } from 'lodash'
import { DateTime } from 'luxon'
import styled from 'styled-components'

const DateWrap = styled.div`
  padding: 14px;
  min-width: 75px;
  min-height: 75px;

  background-color: var(--vulcan-color-current-status);
  color: var(--ion-card-background);
  display: block;

  &.loading {
    background-color: var(--ion-color-step-100);
  }

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  > * {
    flex: 0 0 auto;
  }
`

const DayOfWeek = styled.div`
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: Menlo, monospace, var(--ion-font-family, inherit);
  margin-bottom: 1px;

  > ion-skeleton-text {
    width: 40px;
    height: 24px;
  }
`

const Date = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--ion-font-family, inherit);

  > ion-skeleton-text {
    width: 40px;
    height: 12px;
  }
`

const Divider = styled.div`
  margin: 6px 0 7px;
  background-color: #fff;
  height: 1px;
  width: 80%;
  opacity: 0.3
`

const Time = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Courier, monospace, var(--ion-font-family, inherit);
`

interface DateBadgeCoreProps {
  datetime: DateTime
  status: AssignmentStatus
  showTime?: boolean
  loading?: boolean
}

interface DateBadeLoadingProps extends Partial<DateBadgeCoreProps> {
  loading: boolean
}

export type AssignmentDateBadgeProps = React.HTMLAttributes<HTMLDivElement> & (
  DateBadeLoadingProps | DateBadgeCoreProps
)

export const AssignmentDateBadge = ({
  datetime, status, showTime, loading, ...rest
}: AssignmentDateBadgeProps) => {
  const className = compact([rest.className, loading === true ? 'loading' : `status-${status}`]).join(' ')

  return (
    <DateWrap {...rest} className={className}>
      <DayOfWeek>
        {!loading && datetime?.toFormat('ccc')}
        {loading && <SkeletonText animated />}
      </DayOfWeek>
      <Date>
        {!loading && datetime?.toFormat('LLL dd')}
        {loading && <SkeletonText animated />}
      </Date>
      {showTime && (
        <>
          <Divider />
          <Time>{datetime && formatUserRelativeTime(datetime).replace(' ', '')}</Time>
        </>
      )}

    </DateWrap>
  )
}
