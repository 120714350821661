import { useUpdate } from 'ahooks'
import { useEffect, useState } from 'react'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export const useServiceWorker = (path: string) => {
  const forceRender = useUpdate()
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>()

  const logPrefix = `[service-worker ${path}]:`

  const registerValidSW = (swUrl: string) => {
    navigator.serviceWorker
      .register(swUrl)
      .then(setRegistration)
      .catch((error) => {
        console.error(`${logPrefix} Error during service worker registration:`, error)
      })
  }

  const checkLocalValidServiceWorker = (swUrl: string) => {
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type')
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((incorrectRegistration) => {
            incorrectRegistration.unregister().then(() => {
              window.location.reload()
            })
          })
        } else {
          // Service worker found. Proceed as normal.
          registerValidSW(swUrl)
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log(`${logPrefix} No internet connection found. App is running in offline mode.`)
      })
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return
    if (!('serviceWorker' in navigator)) return
    if (!path) return

    const publicUrl = new URL(process.env.PUBLIC_URL || '', window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    const onLoad = () => {
      const swUrl = `${process.env.PUBLIC_URL}/${path}`
      if (isLocalhost) {
        // Is localhost, checks to ensure we have the correct service worker
        checkLocalValidServiceWorker(swUrl)
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl)
      }

      navigator.serviceWorker.ready.then(() => {
        forceRender()

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        if (isLocalhost) {
          // eslint-disable-next-line no-console
          console.log(`${logPrefix} This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA.`)
        }
      })
      navigator.serviceWorker.addEventListener('controllerchange', (event) => {
        if ((event?.currentTarget as ServiceWorkerContainer)?.controller?.scriptURL === swUrl) {
          forceRender()
        }
      })
    }

    // in case this component is mounted after window load
    if (document.readyState === 'complete') {
      onLoad()
      return
    }

    window.addEventListener('load', onLoad)
    return () => {
      window.removeEventListener('load', onLoad)
    }
  }, [])

  useEffect(() => {
    if (!registration) return
    if (registration.active) {
      registration.active.onstatechange = forceRender
    }

    registration.onupdatefound = () => {
      if (isLocalhost) {
        // eslint-disable-next-line no-console
        console.log(`${logPrefix} a new service-worker was found`)
      }
      forceRender()
      const installingWorker = registration.installing
      if (!installingWorker) return
      installingWorker.onstatechange = forceRender
    }
  }, [registration])

  return registration
}
