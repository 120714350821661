import { IonList } from '@ionic/react'
import styled from 'styled-components'

export const List = styled(IonList) <{ hideBorderOnLastItem?: boolean }>`
  ${(props) => props.hideBorderOnLastItem && `
    > ion-item:last-child {
      --inner-border-width: 0;
    }
  `}
`
