import { Icon } from 'components/core'
import { caretDownOutline } from 'ionicons/icons'
import { ComponentProps } from 'react'
import styled from 'styled-components'

export type CollapseIconProps = Omit<ComponentProps<typeof Icon>, 'icon'> & {
  open?: boolean
}

export const CollapseIcon = styled(Icon).attrs({
  icon: caretDownOutline,
}) <CollapseIconProps>`
  transition: transform .48s;
  ${(props) => props.open === true && `
    transform: rotate(180deg);
  `}
`
