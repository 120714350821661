import * as Types from 'schema';

import { gql } from '@apollo/client';
import { AddressFieldsSlimFragmentDoc } from '../../../gql-docs/fragments/__generated__/AddressFields';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WaypointCardSlimDataFragment = { __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, name?: string | null, scheduledArrivalTimeLocal?: Types.Scalars['DateTime'] | null, scheduledDepartureTimeLocal?: Types.Scalars['DateTime'] | null, actualArrivalTimeLocal?: Types.Scalars['DateTime'] | null, actualDepartureTimeLocal?: Types.Scalars['DateTime'] | null, estimatedArrivalTimeLocal?: Types.Scalars['DateTime'] | null, routeId: number, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, timezone?: string | null }, order?: { __typename?: 'Order', id: number, status: string, customer?: { __typename?: 'Customer', id: number, abbreviation: string, name: string } | null } | null };

export type GetWaypointCardSlimDataQueryVariables = Types.Exact<{
  waypointId: Types.Scalars['Int'];
}>;


export type GetWaypointCardSlimDataQuery = { __typename?: 'Query', routeWaypoint?: { __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, name?: string | null, scheduledArrivalTimeLocal?: Types.Scalars['DateTime'] | null, scheduledDepartureTimeLocal?: Types.Scalars['DateTime'] | null, actualArrivalTimeLocal?: Types.Scalars['DateTime'] | null, actualDepartureTimeLocal?: Types.Scalars['DateTime'] | null, estimatedArrivalTimeLocal?: Types.Scalars['DateTime'] | null, routeId: number, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, timezone?: string | null }, order?: { __typename?: 'Order', id: number, status: string, customer?: { __typename?: 'Customer', id: number, abbreviation: string, name: string } | null } | null } | null };

export const WaypointCardSlimDataFragmentDoc = gql`
    fragment WaypointCardSlimData on RouteWaypoint {
  id
  status
  name
  scheduledArrivalTimeLocal
  scheduledDepartureTimeLocal
  actualArrivalTimeLocal
  actualDepartureTimeLocal
  estimatedArrivalTimeLocal
  routeId
  address {
    ...AddressFieldsSlim
  }
  order {
    id
    status
    customer {
      id
      abbreviation
      name
    }
  }
}
    ${AddressFieldsSlimFragmentDoc}`;
export const GetWaypointCardSlimDataDocument = gql`
    query GetWaypointCardSlimData($waypointId: Int!) {
  routeWaypoint(id: $waypointId) @client {
    ...WaypointCardSlimData
  }
}
    ${WaypointCardSlimDataFragmentDoc}`;

/**
 * __useGetWaypointCardSlimDataQuery__
 *
 * To run a query within a React component, call `useGetWaypointCardSlimDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaypointCardSlimDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaypointCardSlimDataQuery({
 *   variables: {
 *      waypointId: // value for 'waypointId'
 *   },
 * });
 */
export function useGetWaypointCardSlimDataQuery(baseOptions: Apollo.QueryHookOptions<GetWaypointCardSlimDataQuery, GetWaypointCardSlimDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaypointCardSlimDataQuery, GetWaypointCardSlimDataQueryVariables>(GetWaypointCardSlimDataDocument, options);
      }
export function useGetWaypointCardSlimDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaypointCardSlimDataQuery, GetWaypointCardSlimDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaypointCardSlimDataQuery, GetWaypointCardSlimDataQueryVariables>(GetWaypointCardSlimDataDocument, options);
        }
export type GetWaypointCardSlimDataQueryHookResult = ReturnType<typeof useGetWaypointCardSlimDataQuery>;
export type GetWaypointCardSlimDataLazyQueryHookResult = ReturnType<typeof useGetWaypointCardSlimDataLazyQuery>;
export type GetWaypointCardSlimDataQueryResult = Apollo.QueryResult<GetWaypointCardSlimDataQuery, GetWaypointCardSlimDataQueryVariables>;
export function refetchGetWaypointCardSlimDataQuery(variables: GetWaypointCardSlimDataQueryVariables) {
      return { query: GetWaypointCardSlimDataDocument, variables: variables }
    }