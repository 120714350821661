import * as Types from 'schema';

import { gql } from '@apollo/client';
import { AddressFieldsSlimFragmentDoc } from '../../../gql-docs/fragments/__generated__/AddressFields';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignmentCardFullDataFragment = { __typename?: 'Assignment', id: number, active: boolean, acknowledged?: boolean | null, acknowledgedAt?: Types.Scalars['DateTime'] | null, operator: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null }, route?: { __typename?: 'Route', id: number, instructions?: string | null, startTimeLocal: Types.Scalars['DateTime'], endTimeLocal: Types.Scalars['DateTime'], status: Types.RouteStatus, active: boolean, equipment?: { __typename?: 'Equipment', id: number, name: string } | null, assignments: Array<{ __typename?: 'Assignment', id: number, active: boolean, roleDetails: { __typename?: 'AssignmentRole', slug: string, name: string, sort: number }, operator: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, phones: Array<{ __typename?: 'Phone', id: number, number: string }> } }>, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, scheduledArrivalTime?: Types.Scalars['DateTime'] | null, order?: { __typename?: 'Order', id: number, status: string, instructions?: string | null, linesOnSite: boolean, site?: { __typename?: 'Site', id: number, name?: string | null, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, timezone?: string | null } } | null, planned?: { __typename?: 'OrderDetails', id: number, inventory: Array<{ __typename?: 'OrderInventoryItem', id: number, quantity?: number | null, item: { __typename?: 'InventoryItem', id: number, name: string, consumable: boolean, displayUnit?: string | null, visibility: { __typename?: 'InventoryItemVisibilities', operatorAssignment: Types.InventoryItemVisibility } } }> } | null } | null }> } | null };

export type GetAssignmentCardFullDataQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['Int'];
}>;


export type GetAssignmentCardFullDataQuery = { __typename?: 'Query', assignment?: { __typename?: 'Assignment', id: number, active: boolean, acknowledged?: boolean | null, acknowledgedAt?: Types.Scalars['DateTime'] | null, operator: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null }, route?: { __typename?: 'Route', id: number, instructions?: string | null, startTimeLocal: Types.Scalars['DateTime'], endTimeLocal: Types.Scalars['DateTime'], status: Types.RouteStatus, active: boolean, equipment?: { __typename?: 'Equipment', id: number, name: string } | null, assignments: Array<{ __typename?: 'Assignment', id: number, active: boolean, roleDetails: { __typename?: 'AssignmentRole', slug: string, name: string, sort: number }, operator: { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, phones: Array<{ __typename?: 'Phone', id: number, number: string }> } }>, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, scheduledArrivalTime?: Types.Scalars['DateTime'] | null, order?: { __typename?: 'Order', id: number, status: string, instructions?: string | null, linesOnSite: boolean, site?: { __typename?: 'Site', id: number, name?: string | null, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, timezone?: string | null } } | null, planned?: { __typename?: 'OrderDetails', id: number, inventory: Array<{ __typename?: 'OrderInventoryItem', id: number, quantity?: number | null, item: { __typename?: 'InventoryItem', id: number, name: string, consumable: boolean, displayUnit?: string | null, visibility: { __typename?: 'InventoryItemVisibilities', operatorAssignment: Types.InventoryItemVisibility } } }> } | null } | null }> } | null } | null };

export type AcknowledgeAssignmentMutationVariables = Types.Exact<{
  where: Types.AcknowledgeAssignmentInput;
}>;


export type AcknowledgeAssignmentMutation = { __typename?: 'Mutation', acknowledgeAssignment?: { __typename?: 'Assignment', id: number, acknowledged?: boolean | null, acknowledgedAt?: Types.Scalars['DateTime'] | null } | null };

export const AssignmentCardFullDataFragmentDoc = gql`
    fragment AssignmentCardFullData on Assignment {
  id
  active
  acknowledged
  acknowledgedAt
  operator {
    id
    firstName
    lastName
  }
  route {
    id
    instructions
    startTimeLocal
    endTimeLocal
    status
    active
    equipment {
      id
      name
    }
    assignments {
      id
      active
      roleDetails {
        slug
        name
        sort
      }
      operator {
        id
        firstName
        lastName
        phones {
          id
          number
        }
      }
    }
    waypoints {
      id
      scheduledArrivalTime
      order {
        id
        status
        instructions
        linesOnSite
        site {
          id
          name
          address {
            ...AddressFieldsSlim
          }
        }
        planned {
          id
          inventory {
            id
            quantity
            item {
              id
              name
              consumable
              displayUnit
              visibility {
                operatorAssignment
              }
            }
          }
        }
      }
    }
  }
}
    ${AddressFieldsSlimFragmentDoc}`;
export const GetAssignmentCardFullDataDocument = gql`
    query GetAssignmentCardFullData($assignmentId: Int!) {
  assignment(id: $assignmentId) {
    ...AssignmentCardFullData
  }
}
    ${AssignmentCardFullDataFragmentDoc}`;

/**
 * __useGetAssignmentCardFullDataQuery__
 *
 * To run a query within a React component, call `useGetAssignmentCardFullDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentCardFullDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentCardFullDataQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetAssignmentCardFullDataQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentCardFullDataQuery, GetAssignmentCardFullDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentCardFullDataQuery, GetAssignmentCardFullDataQueryVariables>(GetAssignmentCardFullDataDocument, options);
      }
export function useGetAssignmentCardFullDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentCardFullDataQuery, GetAssignmentCardFullDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentCardFullDataQuery, GetAssignmentCardFullDataQueryVariables>(GetAssignmentCardFullDataDocument, options);
        }
export type GetAssignmentCardFullDataQueryHookResult = ReturnType<typeof useGetAssignmentCardFullDataQuery>;
export type GetAssignmentCardFullDataLazyQueryHookResult = ReturnType<typeof useGetAssignmentCardFullDataLazyQuery>;
export type GetAssignmentCardFullDataQueryResult = Apollo.QueryResult<GetAssignmentCardFullDataQuery, GetAssignmentCardFullDataQueryVariables>;
export function refetchGetAssignmentCardFullDataQuery(variables: GetAssignmentCardFullDataQueryVariables) {
      return { query: GetAssignmentCardFullDataDocument, variables: variables }
    }
export const AcknowledgeAssignmentDocument = gql`
    mutation AcknowledgeAssignment($where: AcknowledgeAssignmentInput!) {
  acknowledgeAssignment(where: $where) {
    id
    acknowledged
    acknowledgedAt
  }
}
    `;
export type AcknowledgeAssignmentMutationFn = Apollo.MutationFunction<AcknowledgeAssignmentMutation, AcknowledgeAssignmentMutationVariables>;

/**
 * __useAcknowledgeAssignmentMutation__
 *
 * To run a mutation, you first call `useAcknowledgeAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeAssignmentMutation, { data, loading, error }] = useAcknowledgeAssignmentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAcknowledgeAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<AcknowledgeAssignmentMutation, AcknowledgeAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcknowledgeAssignmentMutation, AcknowledgeAssignmentMutationVariables>(AcknowledgeAssignmentDocument, options);
      }
export type AcknowledgeAssignmentMutationHookResult = ReturnType<typeof useAcknowledgeAssignmentMutation>;
export type AcknowledgeAssignmentMutationResult = Apollo.MutationResult<AcknowledgeAssignmentMutation>;
export type AcknowledgeAssignmentMutationOptions = Apollo.BaseMutationOptions<AcknowledgeAssignmentMutation, AcknowledgeAssignmentMutationVariables>;