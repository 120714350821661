import { IonIcon } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import React from 'react'
import styled from 'styled-components'

export const MoreIcon = styled((props: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    <IonIcon icon={chevronForwardOutline} />
  </div>
))`
  align-self: center;
  font-size: 22px;
`
