import { formatAssignmentLeaveTime } from 'helpers/formatAssignmentLeaveTime'
import { time as timeIcon } from 'ionicons/icons'
import { useMemo } from 'react'
import { parseDatetime } from '../../../helpers/datetime'
import { useReportAtDuration } from '../../../hooks/useReportAtDuration'
import { DetailWithIcon, PresetDetailProps } from './DetailWithIcon'

export const ReportTimeDetail = (
  { assignment, ...props }: PresetDetailProps & { assignment: Parameters<typeof formatAssignmentLeaveTime>[0] }
) => {
  const reportAtDuration = useReportAtDuration()

  const modifiedAssigment = useMemo(() => {
    const route = assignment?.route
    const startTimeLocal = route?.startTimeLocal
    const firstWaypoint = assignment?.route?.waypoints?.[0]

    if (!startTimeLocal) return assignment
    if (!firstWaypoint) return assignment
    if (firstWaypoint.scheduledArrivalTime !== null) return assignment

    const reportAtTime = parseDatetime(startTimeLocal).minus(reportAtDuration).toISO()

    return {
      ...assignment,
      route: {
        ...assignment.route,
        startTimeLocal: reportAtTime,
      },
    }
  }, [assignment, reportAtDuration])

  return (
    <DetailWithIcon
      {...props}
      expectedWidth={100}
      header="Report At"
      body={formatAssignmentLeaveTime(modifiedAssigment)}
      icon={timeIcon}
    />
  )
}
