import { API_URL } from 'constants/environment'

import { ApolloLink, HttpLink } from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'

import { authLink } from './authLink'
import { silenceNetworkErrorsLink } from './silenceNetworkErrorsLink'

export const links = ApolloLink.from([
  authLink,
  silenceNetworkErrorsLink,
  new RetryLink({
    delay: {
      initial: 1000,
    },
    attempts: {
      max: 3,
    },
  }),
  new HttpLink({
    uri: API_URL,
  }),
])
