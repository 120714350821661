import { AssignmentCardFull } from 'components/assignments/AssignmentCardFull'
import { AssignmentRouteOverview } from 'components/assignments/AssignmentRouteOverview'
import { NavigateAssignmentButton } from 'components/assignments/NavigateAssignmentButton'
import { popPath } from 'helpers/url'
import { StandardLayout } from 'layouts/StandardLayout'
import { useState } from 'react'
import { useLocation } from 'react-router'

interface AssignmentShowProps {
  assignmentId: number
}

export const AssignmentShow = (props: AssignmentShowProps) => {
  const { assignmentId } = props

  const [title, setTitle] = useState<string>('')
  const location = useLocation()

  return (
    <StandardLayout
      defaultBackTo={popPath(location.pathname)}
      title={title}
      headerEndButtons={<NavigateAssignmentButton id={assignmentId} />}
    >
      <AssignmentCardFull id={assignmentId} setTitle={(val) => setTitle(val)} />
      <AssignmentRouteOverview id={assignmentId} style={{ paddingTop: 0 }} />
    </StandardLayout>
  )
}

export default AssignmentShow
