import { get as lodashGet } from 'lodash'

export const byFieldAsc = (field: string) => byFieldPathAsc([field])

export const byFieldDesc = (field: string) => byFieldPathDesc([field])

export const byFieldPathAsc = (fieldPath: string[]) => byFieldPathDirectional(fieldPath, 1)

export const byFieldPathDesc = (fieldPath: string[]) => byFieldPathDirectional(fieldPath, -1)

const byFieldPathDirectional = (fieldPath: string[], direction: 1 | -1) => (a: any, b: any) => {
  let aVal = lodashGet(a, fieldPath)
  let bVal = lodashGet(b, fieldPath)

  if (aVal === bVal) return 0
  if (bVal === undefined) return -1 * direction
  if (aVal === undefined) return 1 * direction

  if (typeof aVal?.toLowerCase === 'function') {
    aVal = aVal.toLowerCase()
  }
  if (typeof bVal?.toLowerCase === 'function') {
    bVal = bVal.toLowerCase()
  }

  return aVal < bVal ? -1 * direction : 1 * direction
}

export const bySortField = byFieldAsc('sort')
