import { LocationState } from 'history'
import { compact } from 'lodash'
import React, { createContext, useContext } from 'react'
import { Link as RRLink } from 'react-router-dom'
import { SetOptional } from 'type-fest'

type RRLinkProps<S = LocationState> = Parameters<RRLink<S>>[0]
type LinkProps<S = LocationState> = SetOptional<RRLinkProps<S>, 'to'>

const LinkContext = createContext<boolean>(false)

// eslint-disable-next-line @typescript-eslint/ban-types
type AnchorAttributesWithNavigate = React.AnchorHTMLAttributes<HTMLAnchorElement> & { navigate?: Function }

// An extension to react-router Link that ensures only 1 <a> tag is rendered
// in any given heirarchy, and turns the rest into span tags that with onClick handlers
export function Link<S = LocationState>(props: LinkProps<S>) {
  const currentContext = useContext(LinkContext)

  const { to, ...rest } = props

  if (!to) {
    return <LinkAsSpan {...rest} />
  }

  if (currentContext) {
    return <RRLink to={to} component={currentContext ? LinkAsSpan : undefined} {...rest} />
  }
  return (
    <LinkContext.Provider value>
      <RRLink to={to} {...rest} />
    </LinkContext.Provider>
  )
}

const LinkAsSpan = React.forwardRef<HTMLElement, AnchorAttributesWithNavigate>((props, ref) => {
  const {
    navigate,
    onClick,
    className,
    download,
    href,
    hrefLang,
    media,
    ping,
    rel,
    target,
    type,
    referrerPolicy,
    ...rest
  } = props

  const noAction = navigate === undefined && onClick === undefined

  const classNames = compact([
    className,
    noAction ? undefined : 'as-link',
  ]).join(' ')

  return (
    <span
      ref={ref}
      {...rest}
      className={classNames}
      onClick={(evt) => {
        if (onClick) {
          onClick(evt as React.MouseEvent<HTMLAnchorElement, MouseEvent>)
        } else if (navigate) {
          evt.stopPropagation()
          evt.preventDefault()
          navigate()
        }
      }}
    >{props.children}</span>
  )
})
