import { menuController } from '@ionic/core'
import { useIonRouter } from '@ionic/react'
import {
  Button, Buttons, Content, Header, Icon, Item, Label, List, Menu, Title, Toolbar,
} from 'components/core'
import { useNavigationAppActionSheet } from 'hooks/useNavigationAppActionSheet'
import { closeOutline, logOutOutline, navigateOutline } from 'ionicons/icons'
import { useEffect } from 'react'
import styled from 'styled-components'
import { RouterOutletSiblingTeleporter } from 'teleporters/RouterOutletSiblingTeleporter'
import { VerticalFlex } from './VerticalFlex'

interface SettingsMenuProps {
  open?: boolean
  onClose?: () => void
}

const menuId = 'settingsMenu'

const MenuIcon = styled(Icon)`
  font-size: 1.2em;
  margin-right: 10px;
`

const MenuItem = styled(Item)`
  cursor: pointer;
`

const CloseLink = styled.div.attrs((props) => ({
  children: 'Close',
}))`
  flex: 0 0 auto;
  text-align: center;
  padding: var(--ion-padding, 16px);
  cursor: pointer;

  > a {
    font-size: 0.8em;
    color: var(--ion-color-step-500);
    text-decoration: none;
    padding: 3px 8px;
  }
`

export const SettingsMenu = (props: SettingsMenuProps) => {
  const onClose = props.onClose || (() => { })
  const router = useIonRouter()
  const { present: presentNavigationActionSheet } = useNavigationAppActionSheet()

  useEffect(() => {
    if (props.open === true) {
      menuController.open(menuId)
    } else if (props.open === false) {
      menuController.close(menuId)
    }
  }, [props.open])

  return (
    <RouterOutletSiblingTeleporter.Source>
      <Menu
        side="end"
        menuId={menuId}
        contentId="primary"
        onIonWillClose={(evt) => {
          onClose()
        }}
      >
        <Header>
          <Toolbar color="light">
            <Title>Settings</Title>

            <Buttons slot="end">
              <Button onClick={onClose}><Icon icon={closeOutline} size="small" /></Button>
            </Buttons>
          </Toolbar>
        </Header>
        <Content>
          <VerticalFlex>
            <List>
              <MenuItem onClick={() => presentNavigationActionSheet()}>
                <MenuIcon icon={navigateOutline} /> <Label>Set Navigation App</Label>
              </MenuItem>
              <MenuItem onClick={() => router.push('/auth/logout', 'root', 'replace')}>
                <MenuIcon icon={logOutOutline} /><Label>Logout</Label>
              </MenuItem>
            </List>

            <CloseLink onClick={onClose} />
          </VerticalFlex>
        </Content>
      </Menu>
    </RouterOutletSiblingTeleporter.Source>
  )
}
