import * as Types from 'schema';

import { gql } from '@apollo/client';
import { AddressFieldsFragmentDoc } from '../../../gql-docs/fragments/__generated__/AddressFields';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NavigateRouteButtonDataFragment = { __typename?: 'Route', id: number, status: Types.RouteStatus, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null } }> };

export type GetNavigateRouteButtonDataQueryVariables = Types.Exact<{
  routeId: Types.Scalars['Int'];
}>;


export type GetNavigateRouteButtonDataQuery = { __typename?: 'Query', route?: { __typename?: 'Route', id: number, status: Types.RouteStatus, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null } }> } | null };

export const NavigateRouteButtonDataFragmentDoc = gql`
    fragment NavigateRouteButtonData on Route {
  id
  status
  waypoints {
    id
    status
    address {
      ...AddressFields
    }
  }
}
    ${AddressFieldsFragmentDoc}`;
export const GetNavigateRouteButtonDataDocument = gql`
    query GetNavigateRouteButtonData($routeId: Int!) {
  route(id: $routeId) {
    ...NavigateRouteButtonData
  }
}
    ${NavigateRouteButtonDataFragmentDoc}`;

/**
 * __useGetNavigateRouteButtonDataQuery__
 *
 * To run a query within a React component, call `useGetNavigateRouteButtonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigateRouteButtonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigateRouteButtonDataQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetNavigateRouteButtonDataQuery(baseOptions: Apollo.QueryHookOptions<GetNavigateRouteButtonDataQuery, GetNavigateRouteButtonDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavigateRouteButtonDataQuery, GetNavigateRouteButtonDataQueryVariables>(GetNavigateRouteButtonDataDocument, options);
      }
export function useGetNavigateRouteButtonDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavigateRouteButtonDataQuery, GetNavigateRouteButtonDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavigateRouteButtonDataQuery, GetNavigateRouteButtonDataQueryVariables>(GetNavigateRouteButtonDataDocument, options);
        }
export type GetNavigateRouteButtonDataQueryHookResult = ReturnType<typeof useGetNavigateRouteButtonDataQuery>;
export type GetNavigateRouteButtonDataLazyQueryHookResult = ReturnType<typeof useGetNavigateRouteButtonDataLazyQuery>;
export type GetNavigateRouteButtonDataQueryResult = Apollo.QueryResult<GetNavigateRouteButtonDataQuery, GetNavigateRouteButtonDataQueryVariables>;
export function refetchGetNavigateRouteButtonDataQuery(variables: GetNavigateRouteButtonDataQueryVariables) {
      return { query: GetNavigateRouteButtonDataDocument, variables: variables }
    }