import {
  IonChip, IonCol, IonGrid, IonRow,
} from '@ionic/react'
import { getActivityColor } from 'helpers/colorCoding'
import styled from 'styled-components'
import { Duration } from 'luxon'
import { TIME_SHIFT_HOURS_FORMAT } from 'helpers/datetime'

interface TimeByActivityProps {
  color: string
  name: string
  time: number
}

const TimeSheetChip = styled(IonChip)<{ color: string; }>`
  content: ${(props) => props.color};
  background-color: #f5f5f5;
  border: 1px solid ${(props) => getActivityColor(props.color).lightColor};
  color: ${(props) => getActivityColor(props.color).textColor};
`

const TimeByActivity = (props:TimeByActivityProps) => {
  const { color, name, time } = props
  return (
    <TimeSheetChip color={color}>
      <IonGrid>
        <IonRow>
          <IonCol size="auto">{name}</IonCol>
          <IonCol><strong>{Duration.fromObject({ minutes: time }).toFormat(TIME_SHIFT_HOURS_FORMAT)}</strong>  </IonCol>
        </IonRow>
      </IonGrid>
    </TimeSheetChip>

  )
}

export default TimeByActivity
