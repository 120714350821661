import { RouteWaypoint } from 'schema'
import { RecursivePartial } from 'types/RecursivePartial'

export const linkForWaypointDetails = (waypoint: RecursivePartial<RouteWaypoint>) => {
  if (waypoint?.order?.id) {
    return `/orders/${waypoint?.order?.id}`
  }

  return null
}
