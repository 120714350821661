import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignmentRouteOverviewDataFragment = { __typename?: 'Assignment', id: number, active: boolean, acknowledged?: boolean | null, route?: { __typename?: 'Route', id: number, active: boolean, status: Types.RouteStatus } | null };

export type GetAssignmentRouteOverviewQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['Int'];
}>;


export type GetAssignmentRouteOverviewQuery = { __typename?: 'Query', assignment?: { __typename?: 'Assignment', id: number, active: boolean, acknowledged?: boolean | null, route?: { __typename?: 'Route', id: number, active: boolean, status: Types.RouteStatus } | null } | null };

export const AssignmentRouteOverviewDataFragmentDoc = gql`
    fragment AssignmentRouteOverviewData on Assignment {
  id
  active
  acknowledged
  route {
    id
    active
    status
  }
}
    `;
export const GetAssignmentRouteOverviewDocument = gql`
    query GetAssignmentRouteOverview($assignmentId: Int!) {
  assignment(id: $assignmentId) {
    ...AssignmentRouteOverviewData
  }
}
    ${AssignmentRouteOverviewDataFragmentDoc}`;

/**
 * __useGetAssignmentRouteOverviewQuery__
 *
 * To run a query within a React component, call `useGetAssignmentRouteOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentRouteOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentRouteOverviewQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetAssignmentRouteOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentRouteOverviewQuery, GetAssignmentRouteOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentRouteOverviewQuery, GetAssignmentRouteOverviewQueryVariables>(GetAssignmentRouteOverviewDocument, options);
      }
export function useGetAssignmentRouteOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentRouteOverviewQuery, GetAssignmentRouteOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentRouteOverviewQuery, GetAssignmentRouteOverviewQueryVariables>(GetAssignmentRouteOverviewDocument, options);
        }
export type GetAssignmentRouteOverviewQueryHookResult = ReturnType<typeof useGetAssignmentRouteOverviewQuery>;
export type GetAssignmentRouteOverviewLazyQueryHookResult = ReturnType<typeof useGetAssignmentRouteOverviewLazyQuery>;
export type GetAssignmentRouteOverviewQueryResult = Apollo.QueryResult<GetAssignmentRouteOverviewQuery, GetAssignmentRouteOverviewQueryVariables>;
export function refetchGetAssignmentRouteOverviewQuery(variables: GetAssignmentRouteOverviewQueryVariables) {
      return { query: GetAssignmentRouteOverviewDocument, variables: variables }
    }