import * as analytics from 'helpers/analytics'
import { decode } from 'jsonwebtoken'
import { isObject } from 'lodash'
import React, {
  ReactChild, useContext, useEffect, useRef, useState,
} from 'react'

import { gql, useApolloClient } from '@apollo/client'
import { OrganizationProvider } from './useOrganization'
import { UserSettingsProvider } from './useUserSettings'

import { CurrentUserQueryQuery, useAuthorizeMutation, useCurrentUserQueryLazyQuery } from './__generated__/useSession'
import { useToast } from './useToast'

export let Token: string | null = null // eslint-disable-line

export type TokenState = null | {
  value: string
  mode: 'persistent' | 'session'
}

export interface Session {
  loggedIn: boolean
  token: TokenState
  setToken: (value: React.SetStateAction<TokenState>) => void
  user?: CurrentUserQueryQuery['me'],
  logout: () => void
  login: (idJwtToken: string) => Promise<{ success: boolean, error?: Error }>
}

const initialContext: Session = {
  loggedIn: false,
  token: null,
  setToken: () => { },
  logout: () => null,
  login: (_) => Promise.resolve({ success: false }),
}

export const SessionContext = React.createContext(initialContext)

export const useSession = () => useContext(SessionContext)

export interface SessionProviderProps {
  children?: ReactChild
}

gql`
  query CurrentUserQuery {
    me {
      id
      firstName
      lastName

      organization {
        id
        name
        logo
        primaryColor
      }
    }
  }
`

gql`
  mutation Authorize($jwt: String!) {
    authorize(jwt: $jwt, scope: "operator") {
      token
    }
  }
`

const tokenIsValid = (token: string): boolean => {
  if (!token) return false
  const jwt = decode(token)

  if (!isObject(jwt)) {
    return true
  }

  return !jwt.exp || jwt.exp > Math.floor(Date.now() / 1000)
}

const getInitialToken = (): Session['token'] => {
  const persistedToken = localStorage.getItem('token')

  if (persistedToken && tokenIsValid(persistedToken)) {
    return {
      value: persistedToken,
      mode: 'persistent',
    }
  }
  const sessionToken = sessionStorage.getItem('token')

  if (sessionToken && tokenIsValid(sessionToken)) {
    return {
      value: sessionToken,
      mode: 'session',
    }
  }

  return null
}

export const SessionProvider: React.FC = ({ children }) => {
  const apollo = useApolloClient()
  const [present] = useToast()

  const [token, setTokenState] = useState<Session['token']>(getInitialToken)
  Token = token?.value || null

  const setToken: Session['setToken'] = (newTokenAction) => {
    setTokenState((previousState) => {
      const newToken = typeof newTokenAction === 'function' ? newTokenAction(previousState) : newTokenAction
      const value = newToken?.value || null

      const mode = newToken?.mode || 'persistent'
      const storage = mode === 'session' ? sessionStorage : localStorage

      if (value) {
        storage.setItem('token', value)
      } else {
        storage.removeItem('token')
      }

      Token = value

      if (!value) {
        return null
      }

      return { mode, value }
    })
  }

  const currentUserId = useRef<number>()

  const [loadUser, { data: userQueryResponse }] = useCurrentUserQueryLazyQuery({
    onCompleted: (data) => {
      if (currentUserId.current && data.me?.id) {
        const newUser = currentUserId.current !== data.me.id
        if (newUser) {
          apollo.clearStore()
        }
      }
    },
  })

  const [authorizeMutation] = useAuthorizeMutation({ fetchPolicy: 'no-cache' })

  const user = userQueryResponse?.me || undefined
  currentUserId.current = user?.id

  useEffect(() => {
    if (token) {
      try {
        loadUser()
      } catch (err: any) {
        present({
          message: `ERROR Authenticating: ${err.message}`,
          color: 'danger',
          duration: 4,
        })
        setToken(null)
      }
    }
  }, [token])

  useEffect(() => {
    if (!user) return
    analytics.trackUser(`${user.id}`)
  }, [user])

  const logout = () => {
    setToken(null)
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
    apollo.clearStore()
    localStorage.clear()
    sessionStorage.clear()
  }

  const login = async (idJwtToken: string) => {
    try {
      const resp = await authorizeMutation({
        variables: {
          jwt: idJwtToken,
        },
      })

      if (resp.errors) {
        throw new Error((resp.errors || []).map(({ message }) => message).join('\n'))
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const token = resp.data?.authorize?.token
      if (!token) {
        throw new Error('No auth token available')
      }
      setToken({
        value: token,
        mode: 'persistent',
      })
      return { success: true }
    } catch (error: any) {
      return {
        success: false,
        error: new Error(error),
      }
    }
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const session: Session = {
    loggedIn: Boolean(token),
    token,
    setToken,
    user,
    login,
    logout,
  }

  return (
    <OrganizationProvider organization={user?.organization || null}>
      <SessionContext.Provider value={session}>
        <UserSettingsProvider>
          {children}
        </UserSettingsProvider>
      </SessionContext.Provider>
    </OrganizationProvider>
  )
}
