import React from 'react'

import { useAutoRefetchOnEnter } from 'hooks/useAutoRefetchOnEnter'
import { useSession } from 'hooks/useSession'

import {
  Card,
  Content,
  Refresher,
  RefresherContent,
} from 'components/core'

import { AssignmentCardSlim } from 'components/assignments/AssignmentCardSlim'
import { ToastErrorMessage } from 'components/common/ErrorMessage'
import { Loading } from 'components/common/Loading'
import { Link } from 'react-router-dom'

import { VerticalFlex } from 'components/common/VerticalFlex'
import gql from 'graphql-tag'
import { GreetingHeader } from '../../components/common/GreetingHeader'
import { SettingsFab } from '../../components/common/SettingsFab'
import { useFeature } from '../../hooks/useFeatures'
import { useGetOperatorWithAssignmentsQuery } from './__generated__/Index'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssignmentsIndexProps { }

gql`
  query GetOperatorWithAssignments($operatorId: Int!) {
    operators(where: { id: { equals: $operatorId }}) {
      id

      assignments(where: { visible: { equals: true } }) {
        ...AssignmentCardSlimData
      }
    }
  }
`

type OnIonRefreshFn = NonNullable<React.ComponentProps<typeof Refresher>['onIonRefresh']>

export const AssignmentsIndex = (props: AssignmentsIndexProps) => {
  const { user } = useSession()
  const [timeclocksEnabled] = useFeature('timeclocks.enabled')

  const {
    data, loading: loadingRaw, error, refetch,
  } = useAutoRefetchOnEnter(
    useGetOperatorWithAssignmentsQuery({
      skip: !user,
      fetchPolicy: 'cache-and-network',
      variables: {
        operatorId: user?.id || -1,
      },
    })
  )

  const assignments = data?.operators[0]?.assignments || []

  const loading = assignments.length === 0 && loadingRaw

  const onIonRefresh: OnIonRefreshFn = (event) => {
    refetch().finally(() => {
      event.detail.complete()
    })
  }

  // const assignmentsGrouped = useMemo(() => (
  //   groupBy(assignments, (assignment) => {
  //     const status = assignmentStatus(assignment)
  //     if (status.slug === 'cancelled') {
  //       return 'Cancelled'
  //     }
  //     if (status.slug === 'completed') {
  //       return 'Completed'
  //     }
  //     if (status.slug === 'inProgress') {
  //       return 'In Progress'
  //     }

  //     return 'Upcoming'
  //   })
  // ), [assignments])

  return (
    <>
      <Content>
        <Refresher slot="fixed" onIonRefresh={onIonRefresh}>
          <RefresherContent />
        </Refresher>

        <VerticalFlex>
          <div>
            <GreetingHeader subtitle='Here are your assignments' />

            {
              error && <ToastErrorMessage message={error.toString()} />
            }

            <Loading enabled={loading} />

            {
              // <Row>
              //   { Object.entries(assignmentsGrouped).map(([status, assignments]) => (
              //     <Col size="12">
              //       <AssignmentSubSectionTitle>{ status }</AssignmentSubSectionTitle>
              //       {  assignments.map((assignment) => (
              //         <Link to={ `/assignments/${assignment.id}` } key={assignment.id} className="no-style">
              //           <AssignmentCardSlim id={assignment.id} />
              //         </Link>
              //       ))}
              //     </Col>
              //   ))}
              // </Row>
            }

            {assignments.map((assignment) => (
              <Link to={`/assignments/${assignment.id}`} key={assignment.id} className="no-style">
                <AssignmentCardSlim id={assignment.id} />
              </Link>
            ))}

            {!loading && assignments.length === 0 && (
              <Card>
                <h6 className="ion-padding ion-text-center">
                  You have no assignments at this time.
                </h6>
              </Card>
            )}
          </div>
        </VerticalFlex>

        {!timeclocksEnabled && <SettingsFab />}
      </Content>
    </>
  )
}

export default AssignmentsIndex
