import { filterVisibleInventory } from 'helpers/filterVisibleInventory'
import { sortSchedule } from 'helpers/sortting/sortSchedule'
import {
  compact,
  flatten,
  get as getPropertyAtPath,
  groupBy,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isObject,
  mapValues,
  merge,
  partition,
  pick,
  remove,
  set as setPropertyAtPath,
  trim,
  uniqBy,
} from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { useLoading } from 'hooks/useLoading'
import { useSession } from 'hooks/useSession'

import {
  Button,
  Buttons,
  Content,
  Label as CoreLabel,
  Header,
  Icon,
  Item,
  List,
  Title,
  Toolbar,
} from 'components/core'

import { CollapseIcon } from 'components/common/CollapseIcon'
import { CollapseItem } from 'components/common/CollapseItem'
import { Loading } from 'components/common/Loading'

import { Input } from 'components/form/Input'
import { Textarea } from 'components/form/Textarea'
import { TimePicker } from 'components/form/Time'
import { useFieldArray, useForm } from 'react-hook-form'

import gql from 'graphql-tag'
import { byFieldAsc } from 'helpers/sortting'
import { pencilOutline } from 'ionicons/icons'
import {
  CommentsInput, MutationUpdateOrderArgs, OrderScheduleUpsertInput, User,
} from 'schema'
import { ModalButton } from '../components/common/ModalButton'
import { useFeature } from '../hooks/useFeatures'
import { useToast } from '../hooks/useToast'
import { WorkTicketSignatureModal } from './WorkTicketSignatureModal'
import {
  GetOrderWorkTicketDataQuery, GetWorkTicketInventoryQuery, useGetOrderWorkTicketDataQuery, useGetWorkTicketInventoryQuery, useSubmitWorkTicketMutation,
} from './__generated__/WorkTicketModal'

const METADATA_WORKTICKET_KEY = 'workticketComment'

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

const submitButtonStyles = `
  margin-top: 30px;
  min-width: 50%;
`

const SubmitButton = styled(Button).attrs(() => ({
  size: 'medium',
}))`
  ${submitButtonStyles}
`

const WorkTicketSignatureButton = styled(ModalButton).attrs(() => ({
  size: 'medium',
}))`
  ${submitButtonStyles}
`

const Label = styled(CoreLabel)`
  &.label-stacked {
    font-size: 15px;
  }
`

gql`
  fragment InventoryItemData on InventoryItem {
    id
    name
    branchId
    category
    displayUnit
    visibility {
      operatorWorkTicket
      operatorAssignment
      customerWorkTicket
    }
  }
`

gql`
  fragment OrderDetailsWorkTicketData on OrderDetails {
    id
    inventory {
      id
      quantity
      item {
        ...InventoryItemData
      }
    }
    schedule {
      id
      scheduleIndex
      startTime
      endTime
      step
      stepDetails {
        slug
        name
      }
      metrics {
        key
        value
      }
    }
  }

  fragment OrderWorkTicketData on Order {
    id
    dateOfServiceLocal
    status
    branchId
    comments {
      id
      personId
      text
      metadata {
        key
        value
      }
    }
    site {
      id
      address {
        id
        timezone
      }
    }
    signature {
      id
      metadata {
        key
        value
      }
    }
    planned {
      ...OrderDetailsWorkTicketData
    }
    actuals {
      subType
      ...OrderDetailsWorkTicketData
    }
  }
`

gql`
  query GetOrderWorkTicketData($orderId: Int!) {
    order(id: $orderId) {
      ...OrderWorkTicketData
    }
  }
`

gql`
  query GetWorkTicketInventory {
    inventory(where: { active: { equals: true } }) {
      ...InventoryItemData
    }
  }
`

gql`
  mutation SubmitWorkTicket(
    $data: OrderUpdateInput!
    $where: OrderWhereUniqueInput!
  ) {
    updateOrder(data: $data, where: $where) {
      ...OrderShowData
    }
  }
`

type OrderData = NonNullable<GetOrderWorkTicketDataQuery['order']>

type OrderInventoryItem = NonNullable<OrderData['planned']>['inventory'][0]

interface FormValues {
  arrivalTime?: string
  departureTime?: string
  comment?: string
  pours: {
    scheduleIndex: number
    startTime?: string
    endTime?: string
    cubicYards?: string
  }[]
  inventory: {
    itemId: number
    quantity?: string
  }[]
}

interface WorkTicketModalProps {
  id: number,
  isVisible?: boolean
  onDismiss?: () => void
}

const useGetWorkTicketData = (id: WorkTicketModalProps['id']) => {
  const orderResponse = useGetOrderWorkTicketDataQuery({
    fetchPolicy: 'network-only',
    variables: { orderId: id },
  })

  const order = orderResponse?.data?.order

  const inventoryResponse = useGetWorkTicketInventoryQuery({
    fetchPolicy: 'cache-first',
  })

  const inventory = useMemo(() => {
    if (!order) return []

    return (inventoryResponse.data?.inventory || []).filter((item) => (
      item.branchId === order.branchId
    ))
  }, [inventoryResponse.data, order])

  const loading = orderResponse.loading || inventoryResponse.loading
  const error = orderResponse.error || inventoryResponse.error

  const data = isEmpty(orderResponse.data) ? undefined : {
    ...orderResponse.data,
    inventory,
  }

  return { data, loading, error }
}

export const WorkTicketModal = ({ id, ...rest }: WorkTicketModalProps) => {
  const { data, loading: loadingRaw, error } = useGetWorkTicketData(id)

  const inventory = data?.inventory
  const order = augmentOrderWithAlwaysVisibleInventory(data?.order, inventory || [])

  const loading = !data && loadingRaw
  const notFound = loading === false && !order

  if (error) {
    console.error(`ERROR: WorkTicketModal ${error.name}: ${error.message}`)
  }

  return (
    <>
      <Header>
        <Toolbar>
          <Title>Work Ticket</Title>
          <Buttons slot="end">
            <Button onClick={rest.onDismiss}> {notFound ? 'Close' : 'Save'} </Button>
          </Buttons>
        </Toolbar>
      </Header>
      <Content fullscreen>
        {loading && (
          <>
            <Loading />
          </>
        )}
        {notFound && (
          <>
            <ErrorMessage> Sorry an error occurred, please refresh the page and try again </ErrorMessage>
          </>
        )}
        {order && <WorkTicketModalLoaded key={order.id} order={order} {...rest} />}
      </Content>
    </>
  )
}

const isWorkTicketSigned = (order: NonNullable<GetOrderWorkTicketDataQuery['order']>) => (
  Boolean(
    order?.signature?.metadata?.find(({ key, value }) => (
      key === 'workTicket' && value === true
    ))
  )
)

const useShouldRequireSignature = (order: NonNullable<GetOrderWorkTicketDataQuery['order']>) => {
  const [requireSignatureEnabled] = useFeature('workTicket.requireSignature')
  const wasSignedOnLoad = useRef(isWorkTicketSigned(order))
  if (!requireSignatureEnabled) return false
  return !wasSignedOnLoad.current
}

const WorkTicketModalLoaded = ({
  order,
  isVisible,
  onDismiss,
}: Omit<WorkTicketModalProps, 'id'> & { order: NonNullable<GetOrderWorkTicketDataQuery['order']> }) => {
  const { user } = useSession()
  const { withLoading } = useLoading()
  const [presentToast] = useToast()
  const requireSignature = useShouldRequireSignature(order)

  const timezone = order.site?.address?.timezone || undefined

  const { defaultValues, prefillValues } = useFormValuesFromOrder(order)

  const {
    control,
    handleSubmit,
    getValues,
    formState: {
      errors,
      isSubmitSuccessful,
      isSubmitting,
      isDirty,
    },
  } = useForm<FormValues>({
    defaultValues,
  })

  const { fields: pourFields } = useFieldArray({
    control,
    name: 'pours',
    keyName: 'scheduleIndex',
  })

  const { fields: inventoryFields } = useFieldArray({
    control,
    name: 'inventory',
    keyName: 'itemId',
  })

  const inventoryItemsById = useMemo(() => {
    const byId: Record<string, OrderInventoryItem['item']> = {}

    const collectItem = ({ item }: OrderInventoryItem) => {
      byId[item.id] = item
    }

    order.planned?.inventory?.forEach(collectItem)
    order.actuals?.forEach((details) => {
      details.inventory?.forEach(collectItem)
    })

    return byId
  }, [order])

  const [
    inventorySystemFields,
    inventoryAdditionalFields,
  ] = useMemo(() => {
    const decoratedFields = inventoryFields.map((field, index) => ({ field, index, item: inventoryItemsById[field.itemId] }))
    return partition(
      decoratedFields,
      ({ item }) => item?.category === 'system'
    )
  }, [inventoryFields, inventoryItemsById])

  const [updateOrderMutation] = useSubmitWorkTicketMutation()

  const savePartialValues = () => {
    if (isSubmitting) return
    if (isSubmitSuccessful) return
    if (!isDirty) return

    const variables = formValuesToMutationPayload(getValues(), order, user?.id)

    // Don't change order status for wip save
    variables.data.status = undefined
    return updateOrderMutation({ variables })
  }

  useEffect(() => {
    if (!isVisible) {
      savePartialValues()
    }
  }, [isVisible])

  const onFormSubmit = withLoading(async (data: FormValues) => {
    const variables = formValuesToMutationPayload(data, order, user?.id)

    try {
      await updateOrderMutation({ variables })

      presentToast({
        color: 'success',
        message: 'Work Ticket was submitted successfully. Thanks!',
        duration: 4000,
      })

      if (onDismiss) {
        onDismiss()
      }
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  const validateDateGreaterThan = (field: string) => (
    (value: any) => {
      if (!value) {
        return 'Please enter a time for this field'
      }

      const lastValue = getValues(field as any)

      if (lastValue > value) {
        return 'You must a select a time that occurs after the field above.'
      }

      return true
    }
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormErrorMessage = ({ field }: { field: string }) => {
    const msg = getPropertyAtPath(errors, field)?.message
    if (msg) {
      return <ErrorMessage>{msg}</ErrorMessage>
    }
    return null
  }

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <List>
          <Item>
            <Label position="stacked">Arrived on Site</Label>
            <FormErrorMessage field="arrivalTime" />
            <TimePicker
              name="arrivalTime"
              control={control}
              placeholder="Time you arrived on site."
              initialScrollValue={prefillValues?.arrivalTime}
              minuteStep={5}
              timezone={timezone}
              rules={{
                required: 'Please enter the time you arrived on site.',
              }}
            />
          </Item>

          {pourFields.map((field, index) => {
            const pourSuffix = pourFields.length > 1 ? `#${index + 1}` : undefined
            const fieldPrefix = `pours.${index}`

            return (
              <React.Fragment key={field.scheduleIndex}>
                <Item>
                  <Label position="stacked">Started Pour {pourSuffix}</Label>
                  <FormErrorMessage field={`${fieldPrefix}.startTime`} />
                  <TimePicker
                    name={`${fieldPrefix}.startTime`}
                    control={control}
                    placeholder="Time you started the pour."
                    initialScrollValue={prefillValues?.pours[index]?.startTime}
                    minuteStep={5}
                    timezone={timezone}
                    rules={{
                      required: 'Please enter the time you started the pour.',
                      validate: validateDateGreaterThan(index === 0 ? 'arrivalTime' : `pours.${index - 1}.endTime`),
                    }}
                  />
                </Item>

                <Item>
                  <Label position="stacked">Finished Pour {pourSuffix}</Label>
                  <FormErrorMessage field={`${fieldPrefix}.endTime`} />
                  <TimePicker
                    name={`${fieldPrefix}.endTime`}
                    control={control}
                    placeholder="Time you finished the pour."
                    initialScrollValue={prefillValues?.pours[index]?.endTime}
                    minuteStep={5}
                    timezone={timezone}
                    rules={{
                      required: 'Please enter the time you finished the pour.',
                      validate: validateDateGreaterThan(`${fieldPrefix}.startTime`),
                    }}
                  />
                </Item>

                <Item>
                  <Label position="stacked">Cubic Yards Pumped {pourSuffix && `Pour ${pourSuffix}`}</Label>
                  <FormErrorMessage field={`${fieldPrefix}.cubicYards`} />
                  <Input
                    name={`${fieldPrefix}.cubicYards`}
                    control={control}
                    inputmode="decimal"
                    type="text"
                    placeholder="How many yards were pumped."
                    rules={{
                      required: 'Please enter how many yards were pumped.',
                    }}
                  />
                </Item>
              </React.Fragment>
            )
          })}

          <Item>
            <Label position="stacked">Departed Site At</Label>
            <FormErrorMessage field="departureTime" />
            <TimePicker
              name="departureTime"
              control={control}
              placeholder="Time you departed the site."
              initialScrollValue={prefillValues?.departureTime}
              minuteStep={5}
              timezone={timezone}
              rules={{
                required: 'Please enter the time you departed the site.',
                validate: validateDateGreaterThan(pourFields.length === 0 ? 'arrivalTime' : `pours.${pourFields.length - 1}.endTime`),
              }}
            />
          </Item>

          {inventorySystemFields.map(({ field, index }) => {
            const fieldPrefix = `inventory.${index}`
            const item = inventoryItemsById[field.itemId]
            return (
              <Item key={field.itemId}>
                <Label position="stacked">System - {item.name}</Label>
                <FormErrorMessage field={`${fieldPrefix}.quantity`} />
                <Input
                  name={`${fieldPrefix}.quantity`}
                  control={control}
                  inputmode="numeric"
                  type="text"
                  placeholder={(item?.displayUnit ?
                    `How many ${item?.displayUnit} were used.`
                    :
                    'How much was used.'
                  )}
                />
              </Item>
            )
          })}

          {inventoryAdditionalFields.length > 0 && (
            <CollapseItem
              defaultOpen={false}
              placeholder={inventoryAdditionalFields.map(({ item }) => item.name).join(', ')}
              header={
                // eslint-disable-next-line react/no-unstable-nested-components
                (isOpen) => (
                  <Label position="stacked">
                    <div style={{ display: 'flex' }}>
                      <div style={{ flex: 'auto' }}> Additional items </div>
                      <div>
                        <CollapseIcon open={isOpen} />
                      </div>
                    </div>
                  </Label>
                )
              }
            >
              <List hideBorderOnLastItem>
                {inventoryAdditionalFields.map(({ field, index }) => {
                  const fieldPrefix = `inventory.${index}`
                  const item = inventoryItemsById[field.itemId]
                  return (
                    <Item key={field.itemId}>
                      <Label position="stacked">{item.name}</Label>
                      <FormErrorMessage field={`${fieldPrefix}.quantity`} />
                      <Input
                        name={`${fieldPrefix}.quantity`}
                        control={control}
                        inputmode="numeric"
                        type="number"
                        placeholder={item?.displayUnit ?
                          `How many ${item?.displayUnit} were used.`
                          :
                          `Enter a number for ${item.name.toLowerCase()}.`}
                      />
                    </Item>
                  )
                })}
              </List>
            </CollapseItem>
          )}

          <Item>
            <Label position="stacked">Comments</Label>
            <Textarea
              name="comment"
              control={control}
              placeholder="Additional info regarding this job."
              autoGrow
              style={{ minHeight: '60px' }}
            />
          </Item>
        </List>
      </form>
      <div className="ion-text-center ion-padding">
        {
          requireSignature ? (
            <WorkTicketSignatureButton
              expand="block"
              content={(
                <>
                  <Icon slot="start" icon={pencilOutline} />
                  Collect Ticket Signature
                </>
              )}
              onClick={async () => (
                new Promise<void>((resolve, reject) => {
                  const handleCompletedForm = async () => {
                    await savePartialValues()
                    resolve()
                  }
                  handleSubmit(handleCompletedForm, reject)()
                })
              )}
            >
              <WorkTicketSignatureButton.Modal
                onDidDismiss={() => {
                  const didSign = isWorkTicketSigned(order)
                  if (didSign) {
                    handleSubmit(onFormSubmit)()
                  }
                }}
              >
                <WorkTicketSignatureModal id={order.id} />
              </WorkTicketSignatureButton.Modal>
            </WorkTicketSignatureButton>
          ) : (
            <SubmitButton
              expand="block"
              type="submit"
              onClick={handleSubmit(onFormSubmit)}
            >
              Submit Ticket
            </SubmitButton>
          )
        }
      </div>
    </>
  )
}

const formValuesToMutationPayload = (data: FormValues, order: OrderData, userId?: number) => {
  const scheduleUpdate: OrderScheduleUpsertInput[] = []

  const plannedEntry = (index: number) => (order?.planned?.schedule || []).find(({ scheduleIndex }) => index === scheduleIndex)

  const allTimesRaw = compact(flatten([
    data.arrivalTime,
    ...data.pours.map(({ startTime, endTime }) => [startTime, endTime]),
    data.departureTime,
  ]))

  if (data.arrivalTime) {
    const arrivalEntry = plannedEntry(0)

    scheduleUpdate.push({
      data: {
        step: arrivalEntry?.step || 'prep',
        startTime: data.arrivalTime,
        endTime: allTimesRaw[1],
      },
    })
  }

  data.pours.forEach((pour) => {
    const pourEntry = plannedEntry(pour.scheduleIndex)
    const cubicYards = pour.cubicYards && parseFloat(pour.cubicYards)
    scheduleUpdate.push({
      data: {
        step: pourEntry?.step || 'pour',
        startTime: pour.startTime || undefined,
        endTime: pour.endTime || undefined,
        metrics: isNumber(cubicYards) ? {
          upsert: [
            {
              key: 'cubicYards',
              value: cubicYards,
            },
          ],
        } : undefined,
      },
    })
  })

  if (data.departureTime) {
    const departureEntry = plannedEntry(order?.planned?.schedule.length || -1)

    scheduleUpdate.push({
      data: {
        step: departureEntry?.step || 'clean',
        startTime: allTimesRaw[allTimesRaw.length - 2],
        endTime: data.departureTime,
      },
    })
  }

  remove(scheduleUpdate, ({ data: { startTime, endTime, metrics } }) => (
    isNil(startTime) && isNil(endTime) && isEmpty(metrics)
  ))

  const commentsInput: CommentsInput = {}
  const commentText = data.comment?.trim()
  if (commentText) {
    const existingComment = existingWorkTicketComment(order?.comments, userId)

    if (existingComment) {
      commentsInput.update = [{
        data: {
          text: commentText,
        },
        where: {
          id: existingComment.id,
        },
      }]
    } else {
      commentsInput.create = [{
        personId: userId,
        text: commentText,
        metadata: {
          create: [{
            key: METADATA_WORKTICKET_KEY,
            value: true,
          }],
        },
      }]
    }
  }

  const update: MutationUpdateOrderArgs = {
    data: {
      revision: -42,
      status: ['reviewed', 'cancelled'].includes(order?.status) ? undefined : 'turned_in',
      comments: commentsInput,
      actuals: {
        upsert: [{
          data: {
            inventory: {
              set: compact(data.inventory.map((item) => {
                const quantity = trim(item.quantity)
                if (!quantity) return null
                return {
                  itemId: item.itemId,
                  quantity: parseInt(quantity),
                }
              })),
            },
            schedule: {
              set: scheduleUpdate,
            },
          },
          where: {
            subType: 'operator',
          },
        }],
      },
    },
    where: {
      id: order.id,
    },
  }

  return update
}

const formValuesFromOrderDetails = (details: OrderData['planned'], plannedDetails?: OrderData['planned']): FormValues | undefined => {
  if (!details) return

  let { schedule } = details

  // This block ensures `schedule` gets filled with all necessary
  // steps from the planned values, which is important for the arrival/departure time below
  if (plannedDetails) {
    const scheduleFilledWithPlannedSteps = plannedDetails?.schedule.map((planned) => {
      const plannedEntry = pick(planned, ['step', 'scheduleIndex'])
      const entry = details?.schedule?.find((entry2) => isEqual(pick(entry2, Object.keys(plannedEntry)), plannedEntry))
      if (entry) {
        return entry
      }
      return plannedEntry
    })
    schedule = scheduleFilledWithPlannedSteps as any
  }

  const scheduleSorted = sortSchedule(schedule).map((entry) => (
    {
      ...entry,
      metricsByKey: Object.fromEntries((entry?.metrics || []).map((metric) => [metric.key, metric.value])),
    }
  ))

  const data = {
    ...details,
    schedule: scheduleSorted,
    scheduleByStep: groupBy(scheduleSorted, 'step'),
  }

  const inventory = (() => {
    const allInventoryItems = (
      filterVisibleInventory('operatorWorkTicket', [
        ...details.inventory,
        ...(plannedDetails?.inventory || []),
      ])
        .map(({ item }) => item)
        .sort(byFieldAsc('name'))
    )

    const inventoryItems = uniqBy(allInventoryItems, (item) => item.id)

    const qtyByItemId = Object.fromEntries(
      details.inventory.map((orderItem) => [orderItem.item.id, orderItem.quantity])
    )

    if (plannedDetails) {
      const primerId = inventoryItems.find((item) => item.name.toLowerCase() === 'primer')?.id
      if (primerId) {
        const currentQty = qtyByItemId[primerId]
        if (isNil(currentQty)) {
          qtyByItemId[primerId] = 1
        }
      }
    }

    return inventoryItems.map(({ id }) => ({
      itemId: id,
      quantity: isNil(qtyByItemId[id]) ? undefined : qtyByItemId[id]?.toString(),
    }))
  })()

  return {
    arrivalTime: getPropertyAtPath(data, 'schedule[0].startTime'),
    departureTime: getPropertyAtPath(data, `schedule[${data.schedule.length - 1}].endTime`),
    pours: (data.scheduleByStep.pour || []).map((pour) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scheduleIndex: pour.scheduleIndex!,
      startTime: pour.startTime || undefined,
      endTime: pour.endTime || undefined,
      cubicYards: getPropertyAtPath(pour, 'metricsByKey.cubicYards')?.toString(),
    })),
    inventory,
  }
}

const augmentOrderWithAlwaysVisibleInventory = (
  order: OrderData | undefined | null,
  inventory: GetWorkTicketInventoryQuery['inventory']
) => {
  if (!order) return order

  const cloned = { ...order }

  const currentInventory = cloned.planned?.inventory || []
  const existingIds = currentInventory.map(({ item }) => item.id)

  const alwaysVisibleInventory = inventory.filter((item) => item.visibility.operatorWorkTicket === 'always')

  alwaysVisibleInventory.forEach((item, index) => {
    if (existingIds.includes(item.id)) return

    currentInventory.push({
      id: index * -1,
      quantity: undefined,
      item,
    })
  })

  setPropertyAtPath(cloned, ['planned', 'inventory'], currentInventory)

  return cloned
}

const existingWorkTicketComment = (comments?: OrderData['comments'], userId?: User['id']) => {
  if (!comments) return

  return comments.find((comment) => (
    comment.metadata.some((meta) => meta.key === METADATA_WORKTICKET_KEY) &&
    comment.personId === userId
  ))
}

const useFormValuesFromOrder = (order?: OrderData) => {
  const { user } = useSession()

  const memoized = useMemo(() => {
    const plannedValues = formValuesFromOrderDetails(order?.planned)
    const operatorDetails = order?.actuals.find((actual) => actual.subType === 'operator')
    const operatorValues = formValuesFromOrderDetails(operatorDetails, order?.planned)

    const defaultValues = merge(
      setAllPropertiesAsEmptyString(plannedValues),
      operatorValues
    ) as FormValues

    const existingComment = existingWorkTicketComment(order?.comments, user?.id)?.text
    if (existingComment) {
      defaultValues.comment = `${existingComment}\n\n`
    }

    return {
      defaultValues,
      prefillValues: plannedValues,
    }
  }, [order, user])

  return memoized
}

const ID_KEYS = ['scheduleIndex', 'itemId']

const setAllPropertiesAsEmptyString = (obj: any): any => (
  mapValues(obj, (value, key) => {
    if (ID_KEYS.includes(key)) return value
    return isArray(value) ? value.map(setAllPropertiesAsEmptyString) : isObject(value) ? setAllPropertiesAsEmptyString(value) : ''
  })
)
