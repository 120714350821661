// https://github.com/nolimits4web/swiper/issues/4493
export const shouldAnimateRouter = () => !isIosChrome()

const isIosChrome = () => {
  const userAgent = window?.navigator?.userAgent || ''
  const isChromeForIOs = /CriOS/i.test(userAgent)
  if (isChromeForIOs) {
    const iOsMatch = userAgent.match(
      // eslint-disable-next-line no-useless-escape
      /(.+)(iPhone|iPad|iPod)(.+)OS[\s|\_](\d+)\_?(\d+)?[\_]?(\d+)?.+/i
    )
    if (iOsMatch && iOsMatch.length >= 6) {
      const iOsVersionMajor = parseInt(iOsMatch[4])
      const iOsVersionMinor = parseInt(iOsMatch[5])
      if (iOsVersionMajor >= 14 && iOsVersionMinor >= 5) {
        return true
      }
    }
  }
  return false
}
