import { get, merge, set } from 'lodash'

import { FieldReadFunction } from '@apollo/client'

import { InvalidationPolicyCache, InvalidationPolicyCacheConfig, RenewalPolicy } from '@nerdwallet/apollo-cache-policies'

import { Duration } from 'luxon'
import generatedConfig from './generated-config'

const fieldReadFunctionById = (typeName: string, idField = 'id'): FieldReadFunction => (_, { args, toReference }) => toReference({
  __typename: typeName,
  [idField]: args?.[idField],
})

const fieldReadFunctions: Record<string, FieldReadFunction> = {
  'Query.fields.assignment': fieldReadFunctionById('Assignment'),
  'Query.fields.order': fieldReadFunctionById('Order'),
  'Query.fields.route': fieldReadFunctionById('Route'),
  'Query.fields.routeWaypoint': fieldReadFunctionById('RouteWaypoint'),
}

const cacheConfig: InvalidationPolicyCacheConfig = merge(
  generatedConfig,
  {
    typePolicies: {
      Query: {
        fields: {
          events: {
            keyArgs: ['where'],
          },
        },
      },
      Contact: {
        fields: {
          preferences: {
            merge: true,
          },
        },
      },
    },
    invalidationPolicies: {
      timeToLive: Duration.fromObject({ days: 5 }).toMillis(),
      renewalPolicy: RenewalPolicy.WriteOnly,
    },
  }
)

Object.entries(fieldReadFunctions).forEach(([fieldPath, readFn]) => {
  const path = `typePolicies.${fieldPath}`
  const current = get(cacheConfig, path)
  set(cacheConfig, path, { ...current, read: readFn })
})

export const cache = new InvalidationPolicyCache(cacheConfig)
