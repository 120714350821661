import { API_URL } from 'constants/environment'

import {
  ApolloClient,
  ApolloClientOptions,
  ApolloProvider as ApolloProviderOrig,
} from '@apollo/client'
import { disableFragmentWarnings } from 'graphql-tag'
import { ComponentProps, useEffect, useState } from 'react'
import { links } from './links'
import { initPersistedCache } from './storage'

disableFragmentWarnings()

const clientOptions: Omit<ApolloClientOptions<any>, 'cache'> = {
  uri: API_URL,
  link: links,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
}

export type ApolloProviderProps = Omit<ComponentProps<typeof ApolloProviderOrig>, 'client'>

export const ApolloProvider: React.FC<ApolloProviderProps> = (props: ApolloProviderProps) => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null)

  useEffect(() => {
    const init = async () => {
      const { cache } = await initPersistedCache()

      setClient(
        new ApolloClient({
          cache,
          ...clientOptions,
        })
      )
    }

    init().catch(console.error)
  }, [])

  if (!client) {
    return null
  }

  return <ApolloProviderOrig {...props} client={client} />
}
