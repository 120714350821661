import styled from 'styled-components'

import { useLoading } from 'hooks/useLoading'

import {
  Button,
  Buttons,
  Content,
  Header,
  Icon,
  Item,
  Label,
  List,
  SelectOption,
  Title,
  Toolbar,
} from 'components/core'

import { useMutation } from '@apollo/client'
import { repeatOutline } from 'ionicons/icons'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Select } from '../../components/form/Select'
import { Textarea } from '../../components/form/Textarea'
import { graphql } from '../../gql'
import { updateTimeClockQueryInCache } from '../../helpers/apollo/updateTimeClockQueryInCache'
import { useTimeClock } from '../../hooks/timeclock/useTimeClock'
import { TimeClockTasks } from '../../hooks/timeclock/useTimeClockTasks'
import { useToast } from '../../hooks/useToast'
import { modalWithTasks } from './helpers/modalWithTasks'
import { useTimeClockMutationErrorHandler } from './helpers/useTimeClockMutationErrorHandler'

const ChangeTaskMutation = graphql(`
  mutation ChangeTask($data: TimesheetChangeTaskInput!) {
    timeClock {
      changeTask(data: $data) {
        timeClock {
          ...UseTimeClock_TimeClock
        }
      }
    }
  }
`)

const ChangeTaskButton = styled(Button).attrs(() => {
  const attrs = {
    children: <>
      <Icon slot="start" icon={repeatOutline} />
      Change Task
    </>,
    size: 'medium',
  }
  return attrs
})`
  margin-top: 30px;
  min-width: 50%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

interface FormValues {
  task: number
  note: string
}

type ChangeTaskModalProps = {
  onDismiss?: () => void
}

const ChangeTaskModalWithTasks = ({ onDismiss, tasks }: ChangeTaskModalProps & { tasks: TimeClockTasks }) => {
  const { withLoading } = useLoading()
  const [presentToast] = useToast()

  const { data: currentTimeClock } = useTimeClock()
  const currentTask = currentTimeClock?.task

  const {
    control, watch, handleSubmit, formState: { errors },
  } = useForm<FormValues>()

  const [changeTask] = useMutation(ChangeTaskMutation, { update: updateTimeClockQueryInCache })
  const mutationErrorHandler = useTimeClockMutationErrorHandler()

  const selectableTasks = useMemo(() => (
    (tasks || []).filter((task) => task.id !== currentTask?.id)
  ), [tasks, currentTask])
  const selectedTaskId = watch('task')
  const selectedTask = selectableTasks.find((task) => task.id === selectedTaskId)
  const inNoteRequired = selectedTask?.isNoteRequired

  const onFormSubmit = withLoading(async (data: FormValues) => {
    try {
      const { errors: mutationErrors } = await changeTask({
        variables: {
          data: {
            note: data.note,
            task: {
              id: data.task,
            },
          },
        },
      })

      mutationErrorHandler(mutationErrors, { throw: true })

      presentToast({
        color: 'success',
        message: `You have switched to ${selectedTask?.name}`,
        duration: 3000,
      })

      if (onDismiss) {
        onDismiss()
      }
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormErrorMessage = ({ field }: { field: keyof FormValues }) => {
    const msg = errors[field]?.message
    if (msg) {
      return <ErrorMessage>{msg}</ErrorMessage>
    }
    return null
  }

  return (
    <>
      <Header>
        <Toolbar>
          <Title>Clock In</Title>
          <Buttons slot="end">
            <Button onClick={onDismiss}> Close </Button>
          </Buttons>
        </Toolbar>
      </Header>
      <Content fullscreen>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <List>
            {selectableTasks.length > 0 && (
              <Item>
                <Label position="stacked">Task</Label>
                <FormErrorMessage field="task" />
                <Select
                  name="task"
                  control={control}
                  rules={{
                    required: 'Please select what task you are working on.',
                  }}
                  placeholder={
                    currentTask ?
                      `Current task: ${currentTask.name}`
                      :
                      'Select current task'
                  }
                  interface="action-sheet"
                  interfaceOptions={{
                    header: 'Select a task',
                  }}
                >
                  {selectableTasks?.map((task) => (
                    <SelectOption key={task.name} value={task.id}>{task.name}</SelectOption>
                  ))}
                </Select>
              </Item>
            )}
            <Item>
              <Label position="stacked">Comments {inNoteRequired ? '( required )' : undefined}</Label>
              <FormErrorMessage field="note" />
              <Textarea
                autoGrow
                name="note"
                control={control}
                placeholder="Description of work being performed."
                rules={inNoteRequired ? {
                  required: 'This task requires you add a comment.',
                } : undefined}
              />
            </Item>
          </List>

          <div className="ion-text-center">
            <ChangeTaskButton type="submit" />
          </div>
        </form>
      </Content>
    </>
  )
}

export const ChangeTaskModal = modalWithTasks(ChangeTaskModalWithTasks)
