import * as Types from 'schema';

import { gql } from '@apollo/client';
import { WaypointCardSlimDataFragmentDoc } from './WaypointCard';
import { AddressFieldsFragmentDoc } from '../../../gql-docs/fragments/__generated__/AddressFields';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RouteOverviewDataFragment = { __typename?: 'Route', id: number, active: boolean, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, name?: string | null, scheduledArrivalTimeLocal?: Types.Scalars['DateTime'] | null, scheduledDepartureTimeLocal?: Types.Scalars['DateTime'] | null, actualArrivalTimeLocal?: Types.Scalars['DateTime'] | null, actualDepartureTimeLocal?: Types.Scalars['DateTime'] | null, estimatedArrivalTimeLocal?: Types.Scalars['DateTime'] | null, routeId: number, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null }, order?: { __typename?: 'Order', id: number, status: string, customer?: { __typename?: 'Customer', id: number, abbreviation: string, name: string } | null } | null }> };

export type GetRouteOverviewDataQueryVariables = Types.Exact<{
  routeId: Types.Scalars['Int'];
}>;


export type GetRouteOverviewDataQuery = { __typename?: 'Query', route?: { __typename?: 'Route', id: number, active: boolean, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, name?: string | null, scheduledArrivalTimeLocal?: Types.Scalars['DateTime'] | null, scheduledDepartureTimeLocal?: Types.Scalars['DateTime'] | null, actualArrivalTimeLocal?: Types.Scalars['DateTime'] | null, actualDepartureTimeLocal?: Types.Scalars['DateTime'] | null, estimatedArrivalTimeLocal?: Types.Scalars['DateTime'] | null, routeId: number, address: { __typename?: 'Address', id: number, street?: string | null, street2?: string | null, city?: string | null, state?: string | null, lat?: number | null, lng?: number | null, timezone?: string | null }, order?: { __typename?: 'Order', id: number, status: string, customer?: { __typename?: 'Customer', id: number, abbreviation: string, name: string } | null } | null }> } | null };

export const RouteOverviewDataFragmentDoc = gql`
    fragment RouteOverviewData on Route {
  id
  active
  waypoints {
    ...WaypointCardSlimData
    address {
      ...AddressFields
    }
  }
}
    ${WaypointCardSlimDataFragmentDoc}
${AddressFieldsFragmentDoc}`;
export const GetRouteOverviewDataDocument = gql`
    query GetRouteOverviewData($routeId: Int!) {
  route(id: $routeId) {
    ...RouteOverviewData
  }
}
    ${RouteOverviewDataFragmentDoc}`;

/**
 * __useGetRouteOverviewDataQuery__
 *
 * To run a query within a React component, call `useGetRouteOverviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteOverviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteOverviewDataQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetRouteOverviewDataQuery(baseOptions: Apollo.QueryHookOptions<GetRouteOverviewDataQuery, GetRouteOverviewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteOverviewDataQuery, GetRouteOverviewDataQueryVariables>(GetRouteOverviewDataDocument, options);
      }
export function useGetRouteOverviewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteOverviewDataQuery, GetRouteOverviewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteOverviewDataQuery, GetRouteOverviewDataQueryVariables>(GetRouteOverviewDataDocument, options);
        }
export type GetRouteOverviewDataQueryHookResult = ReturnType<typeof useGetRouteOverviewDataQuery>;
export type GetRouteOverviewDataLazyQueryHookResult = ReturnType<typeof useGetRouteOverviewDataLazyQuery>;
export type GetRouteOverviewDataQueryResult = Apollo.QueryResult<GetRouteOverviewDataQuery, GetRouteOverviewDataQueryVariables>;
export function refetchGetRouteOverviewDataQuery(variables: GetRouteOverviewDataQueryVariables) {
      return { query: GetRouteOverviewDataDocument, variables: variables }
    }