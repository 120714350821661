import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssignmentForActiveCheckQueryQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['Int'];
}>;


export type GetAssignmentForActiveCheckQueryQuery = { __typename?: 'Query', assignment?: { __typename?: 'Assignment', id: number, active: boolean, operator: { __typename?: 'Operator', id: number } } | null };


export const GetAssignmentForActiveCheckQueryDocument = gql`
    query GetAssignmentForActiveCheckQuery($assignmentId: Int!) {
  assignment(id: $assignmentId) {
    id
    active
    operator {
      id
    }
  }
}
    `;

/**
 * __useGetAssignmentForActiveCheckQueryQuery__
 *
 * To run a query within a React component, call `useGetAssignmentForActiveCheckQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentForActiveCheckQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentForActiveCheckQueryQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetAssignmentForActiveCheckQueryQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentForActiveCheckQueryQuery, GetAssignmentForActiveCheckQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentForActiveCheckQueryQuery, GetAssignmentForActiveCheckQueryQueryVariables>(GetAssignmentForActiveCheckQueryDocument, options);
      }
export function useGetAssignmentForActiveCheckQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentForActiveCheckQueryQuery, GetAssignmentForActiveCheckQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentForActiveCheckQueryQuery, GetAssignmentForActiveCheckQueryQueryVariables>(GetAssignmentForActiveCheckQueryDocument, options);
        }
export type GetAssignmentForActiveCheckQueryQueryHookResult = ReturnType<typeof useGetAssignmentForActiveCheckQueryQuery>;
export type GetAssignmentForActiveCheckQueryLazyQueryHookResult = ReturnType<typeof useGetAssignmentForActiveCheckQueryLazyQuery>;
export type GetAssignmentForActiveCheckQueryQueryResult = Apollo.QueryResult<GetAssignmentForActiveCheckQueryQuery, GetAssignmentForActiveCheckQueryQueryVariables>;
export function refetchGetAssignmentForActiveCheckQueryQuery(variables: GetAssignmentForActiveCheckQueryQueryVariables) {
      return { query: GetAssignmentForActiveCheckQueryDocument, variables: variables }
    }