import styled from 'styled-components'

import { assignmentDate } from 'helpers/assignmentDate'
import { assignmentStatus } from 'helpers/assignmentStatus'

import gql from 'graphql-tag'

import { ToastErrorMessage } from 'components/common/ErrorMessage'
import { MoreIcon } from 'components/common/MoreIcon'
import { Card as CoreCard } from 'components/core'
import { useFeature } from '../../hooks/useFeatures'
import { AssignmentDateBadge } from './AssignmentDateBadge'
import { useGetAssignmentCardSlimDataQuery } from './__generated__/AssignmentCardSlim'
import { DetailsWrap } from './details/DetailsWrap'
import { EquipmentDetail } from './details/EquipmentDetail'
import { LeaveTimeDetail } from './details/LeaveTimeDetail'
import { ReportTimeDetail } from './details/ReportTimeDetail'

const Card = styled(CoreCard)`
  display: flex;
  padding-right: 5px;
`

const DividerIcon = styled.div`
  flex: 0 0 auto;
  width: 0;
  height: 0;
  align-self: center;
  margin-top: -5px;
  border: 10px solid transparent;
  border-left-width: 8px;
  border-right-width: 0;
  border-left-color: var(--vulcan-color-current-status);
`

const ContentWrap = styled.div`
  flex: 1 1 auto;
  padding: 10px;
`

const StatusText = styled.div`
  font-size: 18px;
  color: var(--vulcan-color-current-status);
  font-weight: 600;
  margin-bottom: 7px;
  letter-spacing: 1px;
`

gql`
  fragment AssignmentCardSlimData on Assignment {
    id
    acknowledged
    active
    route {
      id
      status
      active
      startTimeLocal
      endTimeLocal
      equipment {
        id
        name
      }
      waypoints {
        id
        scheduledArrivalTime
        order {
          id
          status
        }
      }
    }
  }
`

gql`
  query GetAssignmentCardSlimData($assignmentId: Int!) {
    assignment(id: $assignmentId) {
      ...AssignmentCardSlimData
    }
  }
`

export const AssignmentCardSlim = ({ id }: { id: number }) => {
  const [reportAtTimeEnabled] = useFeature('assignment.showReportAtTime')

  const { data, error } = useGetAssignmentCardSlimDataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      assignmentId: id,
    },
  })

  if (error) {
    return <ToastErrorMessage message={`An error occured while fetching the assignment: ${error.message}`} />
  }

  const assignment = data?.assignment

  if (!assignment?.route) {
    return null
  }

  const datetime = assignmentDate(assignment)
  if (!datetime) {
    return null
  }

  const status = assignmentStatus(assignment)

  const { equipment } = assignment.route

  return (
    <Card className={`status-${status.slug}`}>
      <AssignmentDateBadge datetime={datetime} status={status.slug} />
      <DividerIcon />
      <ContentWrap>
        <StatusText>{status.text} </StatusText>

        <DetailsWrap>
          {reportAtTimeEnabled ?
            <ReportTimeDetail assignment={assignment} style={{ flexShrink: 0 }} />
            :
            <LeaveTimeDetail assignment={assignment} style={{ flexShrink: 0 }} />}
          <EquipmentDetail equipment={equipment} />
        </DetailsWrap>
      </ContentWrap>

      <MoreIcon />
    </Card>
  )
}
