import { IonIcon } from '@ionic/react'
import { SkeletonText } from 'components/core'
import { compact } from 'lodash'
import styled from 'styled-components'

const DetailHeader = styled.div`
  font-weight: var(--vulcan-emphasized-weight);

  ion-skeleton-text {
    min-width: 60% !important;
    width: 60% !important;
  }
`

const DetailBody = styled.div``

const DetailWrap = styled.div`
  display: flex;
  align-items: top;

  ion-icon {
    display: block;
    flex: 0 0 auto;
    font-size: 1.5em;
    margin-right: 6px;
    margin-top: 6px;
  }

  font-size: 16px;
  ion-skeleton-text {
    height: 16px;
  }

  ion-skeleton-text {
    width: 100%;
  }

  &.size-small {
    font-size: 13px;

    ion-icon {
      margin-right: 6px;
      margin-top: 6px;
    }

    ion-skeleton-text {
      height: 12px;
    }
  }

  &.size-medium {
    font-size: 16px;

    ion-icon {
      margin-top: 8px;
      margin-right: 8px;
    }

    ion-skeleton-text {
      height: 16px;
    }
  }

  &.size-large {
    font-size: 20px;

    ion-icon {
      margin-top: 10px;
      margin-right: 10px;
    }

    ion-skeleton-text {
      height: 20px;
    }
  }

  &.size-banner {
    font-size: 20px;

    ion-icon {
      margin-top: 10px;
      margin-right: 10px;
    }

    ion-skeleton-text {
      height: 20px;
    }

    ${DetailHeader} {
      font-weight: normal;
      font-size: 15px;
    }

    ${DetailBody} {
      font-size: 22px;
      font-weight: 800;
    }
  }
`

export interface DetailWithIconProps extends React.HTMLAttributes<HTMLDivElement> {
  header: React.ReactNode
  body: React.ReactNode
  loading?: boolean
  expectedWidth?: number
  size?: 'small' | 'medium' | 'large' | 'banner'
  icon?: string
}

export interface PresetDetailProps extends
  React.HTMLAttributes<HTMLDivElement>,
  Omit<DetailWithIconProps, 'header' | 'body' | 'icon'> {
}

export const DetailWithIcon = (props: DetailWithIconProps) => {
  const {
    header, body, icon, size, loading, expectedWidth, ...rest
  } = props
  const sizeClass = `size-${size || 'small'}`
  const className = compact([rest.className, sizeClass]).join(' ')

  const style = rest.style || {}

  if (loading && expectedWidth && !style.minWidth) {
    style.minWidth = expectedWidth
  }

  return (
    <DetailWrap {...rest} style={style} className={className}>
      {icon && <IonIcon icon={icon} />}

      <div style={{ width: '100%' }}>
        <DetailHeader>{loading ? <SkeletonText animated /> : header} </DetailHeader>
        <DetailBody>{loading ? <SkeletonText animated /> : body} </DetailBody>
      </div>
    </DetailWrap>
  )
}
