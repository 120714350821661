import { NormalizedCacheObject } from '@apollo/client'
import { Storage } from '@ionic/storage'
import { CachePersistor, IonicStorageWrapper } from 'apollo3-cache-persist'

import { cache } from './cache'

let persistor: CachePersistor<NormalizedCacheObject>

const SCHEMA_VERSION = '2' // Must be a string.
const SCHEMA_VERSION_KEY = 'com.cretesuite.api.schemaversion'

export const initPersistedCache = async () => {
  if (!persistor) {
    persistor = await newPersistor()
  }

  return {
    persistor,
    cache: persistor.cache.cache,
  }
}

const newPersistor = async () => {
  const storage = new Storage()
  await storage.create()

  const localStore = new IonicStorageWrapper(storage)
  const cachePersistor = new CachePersistor({
    cache,
    storage: localStore,
  })

  const currentVersion = await localStore.getItem(SCHEMA_VERSION_KEY)
  if (currentVersion === SCHEMA_VERSION) {
    await cachePersistor.restore()
  } else {
    await cachePersistor.purge()
    await localStore.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return cachePersistor
}
