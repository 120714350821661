import { QueryHookOptions, useQuery } from '@apollo/client'
import { ResultOf } from '@graphql-typed-document-node/core'
import { graphql } from '../../gql/full'
import { parseDatetime } from '../../helpers/datetime'
import { useQueryResultTransformer } from '../useQueryResultTransformer'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UseTimeClock_TimeClockFragment = graphql(`
  fragment UseTimeClock_TimeClock on TimeClock {
    id
    startTime
    endTime
    task {
      id
      name
      payable
    }
    shift {
      id
      startTime
      endTime
    }
  }
`)

export const UseTimeClockQuery = graphql(`
  query GetTimeClock {
    me {
      id
      ... on Operator {
        timeClock {
          ...UseTimeClock_TimeClock
        }
      }
    }
  }
`)

type TimeClockRaw = ResultOf<typeof UseTimeClock_TimeClockFragment>

type TimeClockStatus = 'clockedOut' | 'clockedIn' | 'onBreak'

const TimeClockStatusMap: Record<TimeClockStatus, {
  slug: TimeClockStatus,
  name: string,
  isActive: boolean,
}> = {
  clockedIn: {
    slug: 'clockedIn',
    name: 'Clocked In',
    isActive: true,
  },
  clockedOut: {
    slug: 'clockedOut',
    name: 'Clocked Out',
    isActive: false,
  },
  onBreak: {
    slug: 'onBreak',
    name: 'On Break',
    isActive: true,
  },
}

const timeClockStatusSlug = (timeClock: TimeClockRaw | null | undefined): TimeClockStatus => {
  if (!timeClock) return 'clockedOut'
  if (timeClock.endTime) return 'clockedOut'
  if (timeClock.task?.payable === false) return 'onBreak'
  return 'clockedIn'
}

const timeClockStatus = (timeClock: TimeClockRaw | null | undefined) => (
  TimeClockStatusMap[timeClockStatusSlug(timeClock)]
)

export type TimeClock = NonNullable<ReturnType<typeof useTimeClock>['data']>

export const useTimeClock = (options?: QueryHookOptions<ResultOf<typeof UseTimeClockQuery>, any>) => {
  const response = useQuery(UseTimeClockQuery, options)
  return useQueryResultTransformer(response, (data) => {
    if (!data?.me) return null
    if (!('timeClock' in data.me)) return
    const { timeClock } = data.me
    const status = timeClockStatus(timeClock)

    const {
      startTime, endTime, shift, ...rest
    } = timeClock || {}

    return {
      ...rest,
      status,
      startTime: parseDatetime(startTime),
      endTime: parseDatetime(endTime),
      shift: {
        ...shift,
        startTime: parseDatetime(shift?.startTime),
        endTime: parseDatetime(shift?.endTime),
      },
    }
  })
}
