/* eslint-disable */
import { DateTime } from 'luxon'

const n: 'numeric' = 'numeric'
const s: 'short' = 'short'
const l: 'long' = 'long'

export const DATE_SHORT_NO_YEAR = {
  month: n,
  day: n,
}

export const TIME_SIMPLE_WITH_ZONE = {
  ...DateTime.TIME_SIMPLE,
  timeZoneName: s,
}
