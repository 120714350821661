import * as Types from 'schema';

import { gql } from '@apollo/client';
import { AssignmentCardSlimDataFragmentDoc } from '../../../components/assignments/__generated__/AssignmentCardSlim';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOperatorWithAssignmentsQueryVariables = Types.Exact<{
  operatorId: Types.Scalars['Int'];
}>;


export type GetOperatorWithAssignmentsQuery = { __typename?: 'Query', operators: Array<{ __typename?: 'Operator', id: number, assignments: Array<{ __typename?: 'Assignment', id: number, acknowledged?: boolean | null, active: boolean, route?: { __typename?: 'Route', id: number, status: Types.RouteStatus, active: boolean, startTimeLocal: Types.Scalars['DateTime'], endTimeLocal: Types.Scalars['DateTime'], equipment?: { __typename?: 'Equipment', id: number, name: string } | null, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, scheduledArrivalTime?: Types.Scalars['DateTime'] | null, order?: { __typename?: 'Order', id: number, status: string } | null }> } | null }> }> };


export const GetOperatorWithAssignmentsDocument = gql`
    query GetOperatorWithAssignments($operatorId: Int!) {
  operators(where: {id: {equals: $operatorId}}) {
    id
    assignments(where: {visible: {equals: true}}) {
      ...AssignmentCardSlimData
    }
  }
}
    ${AssignmentCardSlimDataFragmentDoc}`;

/**
 * __useGetOperatorWithAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetOperatorWithAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperatorWithAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperatorWithAssignmentsQuery({
 *   variables: {
 *      operatorId: // value for 'operatorId'
 *   },
 * });
 */
export function useGetOperatorWithAssignmentsQuery(baseOptions: Apollo.QueryHookOptions<GetOperatorWithAssignmentsQuery, GetOperatorWithAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOperatorWithAssignmentsQuery, GetOperatorWithAssignmentsQueryVariables>(GetOperatorWithAssignmentsDocument, options);
      }
export function useGetOperatorWithAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOperatorWithAssignmentsQuery, GetOperatorWithAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOperatorWithAssignmentsQuery, GetOperatorWithAssignmentsQueryVariables>(GetOperatorWithAssignmentsDocument, options);
        }
export type GetOperatorWithAssignmentsQueryHookResult = ReturnType<typeof useGetOperatorWithAssignmentsQuery>;
export type GetOperatorWithAssignmentsLazyQueryHookResult = ReturnType<typeof useGetOperatorWithAssignmentsLazyQuery>;
export type GetOperatorWithAssignmentsQueryResult = Apollo.QueryResult<GetOperatorWithAssignmentsQuery, GetOperatorWithAssignmentsQueryVariables>;
export function refetchGetOperatorWithAssignmentsQuery(variables: GetOperatorWithAssignmentsQueryVariables) {
      return { query: GetOperatorWithAssignmentsDocument, variables: variables }
    }