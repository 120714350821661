import SignaturePad, { Options } from 'lib/signature_pad/signature_pad'
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { UseControllerProps, useController } from 'react-hook-form'
import { useResizeDetector } from 'react-resize-detector'
import styled from 'styled-components'

const Placeholder = styled.div.attrs(() => ({
  children: <span>Sign Here</span>,
}))`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: var(--ion-color-step-700);
  font-size: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
`

export type SignatureProps =
  UseControllerProps<any, any> &
  {
    options?: Options
    style?: React.CSSProperties
  }

export const Signature = (props: SignatureProps) => {
  const {
    name,
    rules,
    control,
    defaultValue,
    shouldUnregister,
    style,
    options,
  } = props

  const resizer = useResizeDetector()
  const canvas = useRef<HTMLCanvasElement>(null)
  const [signature, setSignature] = useState<SignaturePad>()
  const [hasStarted, setHasStarted] = useState<boolean>(false)

  const {
    field: { onChange, value },
  } = useController({
    control,
    defaultValue: defaultValue !== undefined ? defaultValue : '',
    name,
    rules,
    shouldUnregister,
  })

  const onEnd: Options['onEnd'] = useCallback((evt) => {
    if (!signature) return
    onChange(signature?.toDataURL('image/svg+xml'))

    if (options?.onEnd) {
      options.onEnd(evt)
    }
  }, [options, signature])

  const onBegin: Options['onBegin'] = useCallback((evt) => {
    if (!signature) return
    setHasStarted(true)

    if (options?.onBegin) {
      options.onBegin(evt)
    }
  }, [options, signature])

  useEffect(() => {
    if (canvas.current) {
      setSignature(new SignaturePad(canvas.current, { ...options, onEnd, onBegin }))
    }
  }, [canvas.current])

  useEffect(() => {
    if (defaultValue && signature) {
      signature.fromDataURL(defaultValue, {
        ratio: Math.max(window.devicePixelRatio || 1, 1),
        width: resizer.width,
        height: resizer.height,
      })
    }
  }, [])

  useEffect(() => {
    if (resizer.width === undefined) return
    if (resizer.height === undefined) return
    if (!canvas.current) return

    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.current.width = resizer.width * ratio
    canvas.current.height = resizer.height * ratio
    canvas.current.getContext('2d')?.scale(ratio, ratio)
    canvas.current.style.width = `${resizer.width}px`
    canvas.current.style.height = `${resizer.height}px`
    signature?.clear()

    if (value) {
      signature?.fromDataURL(value, { ratio, width: canvas.current.width, height: canvas.current.height })
    }
  }, [resizer.width])

  useEffect(() => {
    if (value) setHasStarted(true)
  }, [value])

  return (
    <div ref={resizer.ref} style={{ minHeight: '150px', position: 'relative', ...style }}>
      <canvas ref={canvas} style={{ position: 'relative', zIndex: 4 }} />
      {!hasStarted && <Placeholder />}
    </div>
  )
}
