import { useEffect } from 'react'
import styled from 'styled-components'
import { useToast } from '../../hooks/useToast'

export interface ErrorMesageProps {
  message?: string
}

const ErrorWrapper = styled.div`
  color: #fff;
  background-color: var(--ion-color-danger);
  border-radius: var(--ion-border-radius);
  margin: 15px 0;

  div[slot="header"] {
    font-weight: 600;
    margin-bottom: 3px;
  }
`

export const InlineErrorMessage = ({ message }: ErrorMesageProps) => {
  if (!message) return null

  return <ErrorWrapper className="ion-padding"><div slot="header"> An ERROR occurred </div> {message} </ErrorWrapper>
}

export const ToastErrorMessage = ({ message }: ErrorMesageProps) => {
  const [present] = useToast()

  useEffect(() => {
    if (!message) return

    present({
      header: 'An ERROR occurred',
      message,
      color: 'danger',
      duration: 3000,
    })
  }, [message])

  return null
}
