import styled from 'styled-components'

const PendingLabel = styled.span`
  color: #f0ad4e;
`

interface ClockOutPendingLabelProps {
  text: string
}
const ClockOutPendingLabel = ({ text }: ClockOutPendingLabelProps) => (
  <PendingLabel>{text}</PendingLabel>
)

export default ClockOutPendingLabel
