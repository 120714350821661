import {
  ApolloLink,
  Observable,
} from '@apollo/client'

export const silenceNetworkErrorsLink = new ApolloLink((operation, forward) => new Observable((observer) => {
  let sub: any

  try {
    sub = forward(operation).subscribe({
      next: (result) => {
        observer.next(result)
      },
      error: (networkError) => {
        if (window.navigator.onLine) {
          observer.error(networkError)
        } else {
          observer.complete()
        }
      },
      complete: () => {
        observer.complete()
      },
    })
  } catch (e) {
    console.warn(`ERROR in silenceNetworkErrorsLink: ${e}`)
    observer.error(e)
  }

  return () => {
    if (sub) sub.unsubscribe()
  }
}))
