import { ResultOf } from '@graphql-typed-document-node/core'
import type { graphql as graphqlUnmasked } from './unmasked'
import type * as UnmaskedNamespace from './unmasked/graphql'
import { graphql as graphqlMasked } from './gql'

export const graphql = graphqlMasked as typeof graphqlUnmasked

type Unmasked = typeof UnmaskedNamespace

type GraphqlNodeName = {
  [Key in keyof Unmasked as Key]: (
    Key extends `${infer FragmentName}FragmentDoc` ? FragmentName :
    Key extends `${infer DocumentName}Document` ? DocumentName :
    never
  )
}[keyof Unmasked]

export type FullResultOf<
  Name extends GraphqlNodeName,
  FragmentName = `${Name}FragmentDoc`,
  DocumentName = `${Name}Document`
> = (
    FragmentName extends keyof Unmasked ? ResultOf<Unmasked[FragmentName]> :
    DocumentName extends keyof Unmasked ? ResultOf<Unmasked[DocumentName]> :
    never
  )
