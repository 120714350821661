import { InventoryItem, InventoryItemVisibilities } from 'schema'

type VisibilityKeys = keyof Omit<InventoryItemVisibilities, '__typename'>

type VisibleInventory<VisibilityKey extends VisibilityKeys> = Array<{
  item: {
    visibility: Pick<InventoryItem['visibility'], VisibilityKey>
  }
}>

export const filterVisibleInventory = <
  Key extends VisibilityKeys,
  T extends VisibleInventory<Key>
>(key: Key, inventory: T): T => (
  inventory.filter(({ item }) => item.visibility[key] !== 'hidden') as T
)
