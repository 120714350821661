import styled from 'styled-components'

export const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > * {
    flex: 1 0 auto;
    min-height: 0px;
  }
`
