import Color from 'color'

interface ActivityColor {
  lightColor: string
  lighterColor: string
  backgroundColor: string
  textColor: string
}
export const getActivityColor = (activityColor: string | undefined) : ActivityColor => {
  const color = Color(activityColor || '#AB47BC')
  return {
    textColor: color.hex(),
    backgroundColor: color.lightness(90).alpha(0.5).string(),
    lightColor: color.lightness(70).string(),
    lighterColor: color.lightness(90).alpha(0.5).string(),
  }
}
