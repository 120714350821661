import { DateTime } from 'luxon'
import { Maybe, Route } from 'schema'
import { middleDateTime, parseDatetime } from './datetime'

type DateFields = 'startTimeLocal' | 'endTimeLocal'

export type RouteWithDates = Pick<Route, DateFields>

export type RouteMaybeDates = {
  [K in keyof RouteWithDates]?: Maybe<RouteWithDates[K]>
}

export function routeDate(route: RouteWithDates): DateTime
export function routeDate(route: null | undefined): undefined
export function routeDate(route: RouteMaybeDates | null | undefined): DateTime | undefined
export function routeDate(route: any): undefined
export function routeDate(route: RouteWithDates | any): DateTime | undefined {
  const startTimeRaw = route?.startTimeLocal
  const endTimeRaw = route?.endTimeLocal

  if (!startTimeRaw || !endTimeRaw) return

  const startTime = parseDatetime(startTimeRaw as string)
  const endTime = parseDatetime(endTimeRaw as string)

  return middleDateTime(startTime, endTime).startOf('day')
}
