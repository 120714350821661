import styled from 'styled-components'

import { useLoading } from 'hooks/useLoading'

import {
  Button,
  Buttons,
  Content,
  Header,
  Icon,
  Item,
  Label,
  List,
  Title,
  Toolbar,
} from 'components/core'

import { useMutation } from '@apollo/client'
import { useCreation } from 'ahooks'
import { stopCircleOutline } from 'ionicons/icons'
import { DateTime } from 'luxon'
import { useForm } from 'react-hook-form'
import { Textarea } from '../../components/form/Textarea'
import { graphql } from '../../gql'
import { updateTimeClockQueryInCache } from '../../helpers/apollo/updateTimeClockQueryInCache'
import { useTimeClock } from '../../hooks/timeclock/useTimeClock'
import { useTimeClockTasks } from '../../hooks/timeclock/useTimeClockTasks'
import { useToast } from '../../hooks/useToast'
import { useTimeClockMutationErrorHandler } from './helpers/useTimeClockMutationErrorHandler'

const ClockOutMutation = graphql(`
  mutation ClockOut($data: TimesheetClockOutInput!) {
    timeClock {
      clockOut(data: $data) {
        timeClock {
          ...UseTimeClock_TimeClock
        }
      }
    }
  }
`)

const ClockOutButton = styled(Button).attrs(() => {
  const attrs = {
    children: <>
      <Icon slot="start" icon={stopCircleOutline} />
      Clock Out
    </>,
    size: 'medium',
  }
  return attrs
})`
  margin-top: 30px;
  min-width: 50%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

interface FormValues {
  task: number
  note: string
}

export const ClockOutModal = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  const { withLoading } = useLoading()
  const [presentToast] = useToast()

  const { data: currentTimeClock } = useTimeClock()
  const currentTask = currentTimeClock?.task

  const {
    control, watch, handleSubmit, formState: { errors },
  } = useForm<FormValues>()

  const asOf = useCreation(() => DateTime.utc().toISO(), [])
  const { data: tasks } = useTimeClockTasks({
    variables: {
      noteRequired: {
        asOf,
      },
    },
  })
  const [clockOut] = useMutation(ClockOutMutation, { update: updateTimeClockQueryInCache })
  const mutationErrorHandler = useTimeClockMutationErrorHandler()

  const selectedTask = tasks?.find((task) => task.id === currentTask?.id)
  const inNoteRequired = selectedTask?.isNoteRequired

  const onFormSubmit = withLoading(async (data: FormValues) => {
    try {
      const { errors: mutationErrors } = await clockOut({
        variables: {
          data: {
            note: data.note,
          },
        },
      })

      mutationErrorHandler(mutationErrors, { throw: true })

      presentToast({
        color: 'success',
        message: 'You have been clocked out.',
        duration: 3000,
      })

      if (onDismiss) {
        onDismiss()
      }
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occurred, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormErrorMessage = ({ field }: { field: keyof FormValues }) => {
    const msg = errors[field]?.message
    if (msg) {
      return <ErrorMessage>{msg}</ErrorMessage>
    }
    return null
  }

  return (
    <>
      <Header>
        <Toolbar>
          <Title>Clock Out</Title>
          <Buttons slot="end">
            <Button onClick={onDismiss}> Close </Button>
          </Buttons>
        </Toolbar>
      </Header>
      <Content fullscreen>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <List>
            <Item>
              <Label position="stacked">Comments {inNoteRequired ? '( required )' : undefined}</Label>
              <FormErrorMessage field="note" />
              <Textarea
                autoGrow
                name="note"
                control={control}
                placeholder="Comments related to clocking out"
                rules={inNoteRequired ? {
                  required: 'This task requires you add a comment in order to clock out.',
                } : undefined}
              />
            </Item>
          </List>

          <div className="ion-text-center">
            <ClockOutButton type="submit" />
          </div>
        </form>
      </Content>
    </>
  )
}
