import { QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client'
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'
import { set } from 'lodash'
import { graphql } from '../../gql/full'
import { byFieldAsc } from '../../helpers/sortting'
import { TimeClockTaskScope } from '../../schema'
import { useQueryResultTransformer } from '../useQueryResultTransformer'

const UseTimeClockTasksQuery = graphql(`
  query GetTimeClockTasks($where: QueryTimeClockTasksWhereInput, $noteRequired: TimeClockIsNotRequiredWhereInput) {
    timeClockTasks(where: $where) {
      id
      name
      isNoteRequired(where: $noteRequired)
      noteRequired
      payable
      sort
    }
  }
`)

export type TimeClockTasks = NonNullable<ReturnType<typeof useTimeClockTasks>['data']>

type TypedQueryHookOptions<Document extends TypedDocumentNode> = QueryHookOptions<ResultOf<Document>, VariablesOf<Document>>

type UseTimeClockTasksParams = TypedQueryHookOptions<typeof UseTimeClockTasksQuery>

export const useTimeClockTasks = (props?: UseTimeClockTasksParams) => {
  const variables = { ...props?.variables }
  set(variables, ['where', 'active', 'equals'], true)
  set(variables, ['where', 'scopes', 'has'], TimeClockTaskScope.Operator)
  const response = useQuery(UseTimeClockTasksQuery, { ...props, variables })
  return useQueryResultTransformer(response, (data) => (
    data?.timeClockTasks?.slice()?.sort(byFieldAsc('sort'))
  ))
}
