import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { ResultOf } from '@graphql-typed-document-node/core'
import { Accordion } from 'components/common/Accordion'
import { TimeClockLabel } from 'components/common/TimeClockLabel'
import { TIME_SHIFT_HOURS_FORMAT, TIME_SHIFT_ITEM_FORMAT } from 'helpers/datetime'
import { DateTime, Duration } from 'luxon'

import TimeShiftItem from './TimeShiftItem'
import ClockOutPendingLabel from './ClockOutPendingLabel'
import { graphql } from '../../../gql'

const TimeClockFragment = graphql(`
  fragment TimeClockDetail_TimeClock on TimeClock {
    id
    startTime
    endTime
    task {
      id
      name
      color
    }
  }
`)

const TimeClockShiftFragment = graphql(`
  fragment TimeClockDetail_TimeClockShift on TimeClockShift {
    id
    startTime
    endTime
    timeClocks {
      id
      startTime
      endTime
      task {
        id
        name
        color
      }
    }
  }
`)

interface CustomTimeClock extends ResultOf<typeof TimeClockFragment> {
  name?: string
  time?: number
  color?: string
}

interface TimeClockDetailProps {
  shift: ResultOf<typeof TimeClockShiftFragment>,
}

const TimeClockDetail = (props: TimeClockDetailProps) => {
  const {
    startTime, endTime, timeClocks,
  } = props.shift

  const now = DateTime.now().toISO()

  const startTimeState = DateTime.fromISO(startTime || now)
  const endTimeState = DateTime.fromISO(endTime || now)

  let totalWorkedHours = 0
  if (startTimeState && endTimeState) {
    totalWorkedHours = endTimeState.diff(startTimeState, 'minutes').minutes
  }

  let timeClocksState = [] as CustomTimeClock[]
  if (timeClocks && timeClocks.length > 0) {
    timeClocksState = timeClocks.map((timeClock) => ({
      ...timeClock,
      startTime: timeClock.startTime,
      id: timeClock?.task?.id || 0, // ensure id is always a number
      name: timeClock?.task?.name,
      color: timeClock?.task?.color,
    }))
  }

  return (
    <Accordion title={(
      <IonGrid>
        <IonRow>
          <IonCol>
            <TimeClockLabel bold>{startTimeState.toFormat(TIME_SHIFT_ITEM_FORMAT)}</TimeClockLabel>
          </IonCol>
          <IonCol>
            <TimeClockLabel bold>{
              endTime ?
                endTimeState.toFormat(TIME_SHIFT_ITEM_FORMAT)
                :
                <ClockOutPendingLabel text='Pending clock out' />
            }
            </TimeClockLabel>
          </IonCol>
          <IonCol size="3" style={{ textAlign: 'right' }}>
            <TimeClockLabel bold>{Duration.fromObject({ minutes: totalWorkedHours }).toFormat(TIME_SHIFT_HOURS_FORMAT)}</TimeClockLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    )}
    >
      {timeClocksState.map((timeClock) => (
        <TimeShiftItem
          key={timeClock.startTime}
          id={timeClock.id}
          name={timeClock.name}
          color={timeClock.color}
          startTime={timeClock.startTime}
          endTime={timeClock.endTime ? timeClock.endTime : undefined}
        />
      ))}

    </Accordion>
  )
}

export default TimeClockDetail
