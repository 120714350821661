import { Assignment, Order } from 'schema'

export type AssignmentStatus = 'attention' | 'confirmed' | 'inProgress' | 'completed' | 'cancelled'

export const assignmentStatus = (
  assignment: Partial<Pick<Assignment, 'active' | 'acknowledged'>> & {
    route?: null | Pick<NonNullable<Assignment['route']>, 'active' | 'status'> & {
      waypoints?: Array<{
        order?: null | Pick<Order, 'status'>
      }>
    },
  }
): {
  slug: AssignmentStatus,
  text: string,
  needsAcknowledgement: boolean,
  inProgress: boolean,
} => {
  const { route } = assignment
  const needsAcknowledgement = !assignment.acknowledged

  const hasIncompleteOrders = (route?.waypoints || []).some((wp) => wp?.order?.status === 'confirmed')

  const inProgress = (
    assignment.active === true &&
    route?.active === true
  ) && (
      route?.status === 'inProgress' ||
      (
        route?.status === 'completed' &&
        hasIncompleteOrders
      )
    )

  const slug = (assignment.active === false || route?.active === false || route?.status === 'cancelled') ? 'cancelled' :
    inProgress ? 'inProgress' :
      route?.status === 'completed' ? 'completed' :
        needsAcknowledgement ? 'attention' :
          'confirmed'

  const text = hasIncompleteOrders && inProgress ? 'Awaiting Work Ticket' :
    inProgress ? 'In Progress' :
      slug === 'completed' ? 'Completed' :
        needsAcknowledgement ? 'Please Acknowledge' :
          slug === 'cancelled' ? 'Cancelled' :
            'Confirmed'

  return {
    slug, text, needsAcknowledgement, inProgress,
  }
}
