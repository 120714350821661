import { useIonToast } from '@ionic/react'
import { castArray, compact, isString } from 'lodash'

export const useToast: typeof useIonToast = () => {
  const [present, dismiss] = useIonToast()

  const presentWithCssPosition: typeof present = (...args) => {
    const [messageOrOptions, duration] = args
    if (isString(messageOrOptions)) {
      return present(messageOrOptions, duration as any)
    }

    const options = {
      ...messageOrOptions,
      cssClass: compact([
        ...castArray(messageOrOptions.cssClass),
        `toast-position-${messageOrOptions.position || 'bottom'}`,
      ]),
    }

    return present(options)
  }

  return [presentWithCssPosition, dismiss]
}
