import { useLongPress } from 'ahooks'
import { getNavigator, NavigationApp } from 'helpers/navigate'
import { NavigationRegister } from 'helpers/navigate/types'
import { useUserSettings } from 'hooks/useUserSettings'
import { useRef } from 'react'
import { useNavigationAppActionSheet } from './useNavigationAppActionSheet'

export type UseNavigateHandlerProps = {
  link: (navigator: NavigationRegister) => string | null
}

export const useNavigateInteractionHandler = <T extends HTMLElement = HTMLElement>(props: UseNavigateHandlerProps) => {
  const ref = useRef<T>(null)
  const { navigationApp } = useUserSettings()

  const navigate = (app: NavigationApp) => {
    const link = props.link(getNavigator(app))
    if (link) {
      window.open(link, '_blank')
    }
    dismiss()
  }

  const { present, dismiss } = useNavigationAppActionSheet({
    onDidSelect: (app) => {
      navigate(app)
    },
  })

  useLongPress((evt) => {
    present({ skipSave: true })
  }, ref, {
    onClick: (evt) => {
      if (navigationApp === null) {
        present()
      } else {
        navigate(navigationApp)
      }
    },
  })

  return { ref }
}
