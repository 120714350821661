import React from 'react'

import {
  Content,
  Icon,
  Refresher,
  RefresherContent,
} from 'components/core'

import { Loading } from 'components/common/Loading'

import { VerticalFlex } from 'components/common/VerticalFlex'
import { TimeClockBanner } from 'components/timeclock/TimeClockBanner'
import {
  playCircleOutline, repeatOutline, stopCircleOutline,
} from 'ionicons/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { GreetingHeader } from '../../components/common/GreetingHeader'
import { LinkButton } from '../../components/common/LinkButton'
import { ModalButton } from '../../components/common/ModalButton'
import { useTimeClock } from '../../hooks/timeclock/useTimeClock'
import { useTimeClockTasks } from '../../hooks/timeclock/useTimeClockTasks'
import { ChangeTaskModal } from '../../modals/timeclock/ChangeTaskModal'
import { ClockInModal } from '../../modals/timeclock/ClockInModal'
import { ClockOutModal } from '../../modals/timeclock/ClockOutModal'

const TimeClockActionButton = styled(ModalButton).attrs(() => {
  const attrs = {
    className: 'ion-margin',
    expand: 'block',
  }
  return attrs
})`
  --padding-top: 20px;
  --padding-bottom: 20px;
  --padding-start: 45px;
  --padding-end: 45px;
`

const ClockInButton = styled(TimeClockActionButton).attrs(() => {
  const attrs = {
    content: <>
      <Icon slot="start" icon={playCircleOutline} />
      Clock In
    </>,
  }
  return attrs
})`
  --background: var(--vulcan-color-status-confirmed);
  --background-activated: var(--vulcan-color-status-confirmed);
  --background-focused: var(--vulcan-color-status-confirmed);
  --background-hover: var(--vulcan-color-status-confirmed);
`

const ChangeTaskButton = styled(TimeClockActionButton).attrs(() => {
  const attrs = {
    content: <>
      <Icon slot="start" icon={repeatOutline} />
      Change Task
    </>,
  }
  return attrs
})`
  --background: #054581;
  --background-activated: #054581;
  --background-focused: #054581;
  --background-hover: #054581;
`

const ClockOutButton = styled(TimeClockActionButton).attrs(() => {
  const attrs = {
    content: <>
      <Icon slot="start" icon={stopCircleOutline} />
      Clock Out
    </>,
  }
  return attrs
})`
  --background: var(--vulcan-color-status-cancelled);
  --background-activated: var(--vulcan-color-status-cancelled);
  --background-focused: var(--vulcan-color-status-cancelled);
  --background-hover: var(--vulcan-color-status-cancelled);
`

const FlexBreak = styled.div`
  flex: 1 0 100%;
  margin: 10px 0;

  @media (max-height: 450px) {
    display: none;
  }
`

const TimeClockBannerWrap = styled.div`
  flex-basis: 70%;
  max-width: 320px;
  margin-top: 10px;
`

const ActionButtonsWrap = styled.div`
  min-width: 50%;
  max-width: 320px;
`

const FlexContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  > * {
    flex: 1 0 100%;

    @media (max-height: 450px) {
      height: 100%;
      margin: 0 10px;
      flex-basis: calc(100vh - 145px);
      max-width: calc(100vh - 145px);
      flex-grow: 0;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TimeclockIndexProps { }

type OnIonRefreshFn = NonNullable<React.ComponentProps<typeof Refresher>['onIonRefresh']>

export const TimeclockIndex = (props: TimeclockIndexProps) => {
  const {
    data: timeClock,
    refetch: refetchTimeClock,
    loading: timeClockLoading,
  } = useTimeClock()
  const tasks = useTimeClockTasks({
    skip: timeClockLoading,
  })?.data

  const loading = timeClockLoading

  const onIonRefresh: OnIonRefreshFn = (event) => {
    refetchTimeClock().then(() => {
      event.detail.complete()
    })
  }

  return (
    <>
      <Content>
        <Refresher slot="fixed" onIonRefresh={onIonRefresh}>
          <RefresherContent />
        </Refresher>

        <VerticalFlex>
          <div>
            <GreetingHeader subtitle="What are you working on" />
            <Loading enabled={loading} />

            <FlexContent>

              <TimeClockBannerWrap>
                <TimeClockBanner timeClock={timeClock} />
              </TimeClockBannerWrap>

              <FlexBreak />

              <ActionButtonsWrap>
                {timeClock?.status.slug === 'clockedOut' ? (
                  <ClockInButton>
                    <ClockInButton.Modal>
                      <ClockInModal />
                    </ClockInButton.Modal>
                  </ClockInButton>
                ) : (
                  <>
                    {
                      Boolean(tasks?.length) && (
                        <ChangeTaskButton>
                          <ChangeTaskButton.Modal>
                            <ChangeTaskModal />
                          </ChangeTaskButton.Modal>
                        </ChangeTaskButton>
                      )
                    }
                    <ClockOutButton>
                      <ClockOutButton.Modal>
                        <ClockOutModal />
                      </ClockOutButton.Modal>
                    </ClockOutButton>
                  </>
                )}

                <FlexBreak />

                <Link to="/timeclock/history" className="">
                  <LinkButton>
                    View History
                  </LinkButton>
                </Link>

              </ActionButtonsWrap>

            </FlexContent>

          </div>
        </VerticalFlex>
      </Content>
    </>
  )
}
