import { gql } from '@apollo/client'
import { Button, Spinner } from 'components/core'
import { DateTime } from 'luxon'
import { ComponentProps, useMemo } from 'react'
import { Route, RouteWaypointStatus } from 'schema'
import styled from 'styled-components'
import { useGetManualRouteProgressButtonDataQuery, useUpdateRouteWaypointMutation } from './__generated__/ManualRouteProgressButton'

gql`
  query GetManualRouteProgressButtonData($routeId: Int!) {
    route(id: $routeId) {
      id
      equipment {
        telematics {
          gps {
            enabled
          }
          stats {
            enabled
          }
        }
      }
      waypoints {
        id
        status
        name
        scheduledArrivalTimeLocal
        scheduledDepartureTimeLocal
        actualArrivalTimeLocal
        actualDepartureTimeLocal
      }
    }
  }
`

gql`
  mutation UpdateRouteWaypoint(
    $data: RouteWaypointInput!,
    $where: RouteWaypointWhereUniqueInput!
  ) {
    updateRouteWaypoint(data: $data, where: $where) {
      id
      status
      actualArrivalTimeLocal
      actualDepartureTimeLocal
    }
  }
`

type SpinnerWrapProps = { enabled: boolean }

const SpinnerWrap = styled.div<SpinnerWrapProps>`
  transition: opacity 0.3s;
  opacity: ${(props) => (props.enabled ? '1' : '0')};
  margin-left: -30px;
  margin-right: 10px;

  ion-spinner {
    width: 20px;
    height: 20px;
  }
`

export interface ManualRouteProgressButtonProps extends Omit<ComponentProps<typeof Button>, 'id'> {
  id: Route['id']
}

type TimeAttr = 'actualArrivalTime' | 'actualDepartureTime'

const routeStartingCondition: 'arriveFirstWaypoint' | 'departFirstWaypoint' = ('arriveFirstWaypoint' as any)
const routeCompletionCondition: 'arriveLastWaypoint' | 'departLastWaypoint' = ('arriveLastWaypoint' as any)

export const ManualRouteProgressButton = ({
  id,
  ...rest
}: ManualRouteProgressButtonProps) => {
  const { data } = useGetManualRouteProgressButtonDataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      routeId: id,
    },
  })

  const [updateRouteWaypointMutation, { loading: mutationLoading }] = useUpdateRouteWaypointMutation()

  const updateWaypointTime = async (waypointId: number, attr: TimeAttr) => {
    await updateRouteWaypointMutation({
      variables: {
        data: {
          [attr]: DateTime.utc().toISO(),
        },
        where: {
          id: waypointId,
        },
      },
    })
  }

  const route = data?.route
  const waypoints = route?.waypoints || []
  const equipment = route?.equipment

  const nextStatusUpdate = useMemo(() => {
    const index = waypoints.findIndex((wp) => (
      wp.status !== RouteWaypointStatus.Completed
    ))
    if (index === -1) return null
    const waypoint = waypoints[index]

    const isFirstWaypoint = index === 0
    const isLastWaypoint = index === (waypoints.length - 1)

    const attrToUpdate: TimeAttr =
      waypoint.status === RouteWaypointStatus.InProgress ?
        'actualDepartureTime'
        : isFirstWaypoint && routeStartingCondition === 'departFirstWaypoint' ?
          'actualDepartureTime'
          : 'actualArrivalTime'

    const text = attrToUpdate === 'actualArrivalTime' ?
      `Arrive at ${waypoint.name || `Stop #${index + 1}`}` :
      `Depart ${waypoint.name || `Stop #${index + 1}`}`

    const toReturn = {
      text,
      attrToUpdate,
      waypoint,
    }

    if (
      isFirstWaypoint &&
      attrToUpdate === 'actualArrivalTime'
    ) {
      // Skip start of day "Arrive at Shop" if vehicle stats are enabled
      if (equipment?.telematics?.stats?.enabled === true) {
        return null
      }

      return toReturn
    }

    if (
      isLastWaypoint &&
      attrToUpdate === 'actualDepartureTime'
    ) {
      if (routeCompletionCondition === 'arriveLastWaypoint') {
        return null
      }

      return toReturn
    }

    // Skip if vehicle gps is enabled
    if (equipment?.telematics?.gps?.enabled === true) {
      return null
    }

    return toReturn
  }, [waypoints])

  if (!nextStatusUpdate) return null

  return (
    <Button
      expand="block"
      className="ion-margin"
      {...rest}
      onClick={() => {
        updateWaypointTime(nextStatusUpdate.waypoint.id, nextStatusUpdate.attrToUpdate)
      }}
    >
      <SpinnerWrap enabled={mutationLoading}>
        <Spinner />
      </SpinnerWrap>
      {nextStatusUpdate.text}
    </Button>
  )
}
