import {
  IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs as IonTabsRaw,
} from '@ionic/react'
import { Routes } from 'Routes'
import { useCreation } from 'ahooks'
import timeOutlineClockedIn from 'assets/images/time-outline-clocked-in.svg'
import {
  personOutline,
  timeOutline,
  todayOutline,
} from 'ionicons/icons'
import { Duration } from 'luxon'
import { Route, Switch } from 'react-router'
import styled from 'styled-components'
import { shouldAnimateRouter } from './helpers/shouldAnimateRouter'
import { useTimeClock } from './hooks/timeclock/useTimeClock'
import { useFeature } from './hooks/useFeatures'
import { AuthRoutes } from './pages/auth/AuthRoutes'

const IonTabs = styled(IonTabsRaw)`
  ion-tab-button.tab-selected {
    color:  var(--ion-color-step-700);
    --color:  var(--ion-color-step-700);
  }
`

const POLL_INTERVAL = Duration.fromObject({ minutes: 5 }).as('milliseconds')

const TimeClockTab = () => {
  const { data: timeClock } = useTimeClock({
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })
  const isActive = Boolean(timeClock?.status?.isActive)

  return (
    <>
      <IonIcon icon={isActive ? timeOutlineClockedIn : timeOutline} />
      <IonLabel>Time Clock</IonLabel>
    </>
  )
}

const Tabs = () => (
  <IonTabs>
    <IonRouterOutlet animated={shouldAnimateRouter()} id="primary">
      {Routes()}
    </IonRouterOutlet>

    <IonTabBar slot="bottom">
      <IonTabButton tab="assignments" href="/assignments">
        <IonIcon icon={todayOutline} />
        <IonLabel>Assignments</IonLabel>
      </IonTabButton>

      <IonTabButton tab="timeclock" href="/timeclock">
        <TimeClockTab />
      </IonTabButton>

      <IonTabButton tab="profile" href="/profile">
        <IonIcon icon={personOutline} />
        <IonLabel>Profile</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </IonTabs>
)

const LegacySinglePageLayout = () => (
  <IonRouterOutlet animated={shouldAnimateRouter()} id="primary">
    {Routes()}
  </IonRouterOutlet>
)

export const Main = () => {
  const [timeclocksEnabled] = useFeature('timeclocks.enabled')

  // React router refreshes on every page transition, even with render below
  // This ensures that a given route keeps the state of the component between
  // nested route changes
  const TabsSingleton = useCreation(() => (
    timeclocksEnabled ? <Tabs /> : <LegacySinglePageLayout />
  ), [timeclocksEnabled])
  const AuthSingleton = useCreation(() => <AuthRoutes />, [])

  return (
    <Switch>
      <Route path="/auth" render={() => AuthSingleton} />
      <Route path="/" render={() => TabsSingleton} />
    </Switch>
  )
}
