import React from 'react'
import styled from 'styled-components'

import {
  Col,
  Content,
  Grid,
  Page,
  Row,
} from 'components/core'
import { StandardLayout } from 'layouts/StandardLayout'

const Title = styled.div`
  margin-bottom: 15px;
  margin-top: 5px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
`

const VerticallyCentered = ({ children }: React.PropsWithChildren<any>) => (
  <Grid style={{ height: '100%' }}>
    <Row style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
    }}
    >
      <Col size="12">
        {children}
      </Col>
    </Row>
  </Grid>
)

type LoginLayoutProps = React.PropsWithChildren<{
  id: string
  backTo?: string
}>

export const LoginLayout = ({ id, backTo, children }: LoginLayoutProps) => (
  <Page id={id}>
    <StandardLayout defaultBackTo={backTo} hideHeader={Boolean(backTo) === false}>
      <Content fullscreen className="ion-padding ion-text-center">
        <VerticallyCentered>
          <div style={{ minHeight: '140px' }}>
            <img src="https://res.cloudinary.com/vulcan/image/upload/cretesuite/logo.svg" width="260px" />
          </div>
          <Title>Crew Login</Title>

          <div style={{ minHeight: '270px', marginBottom: backTo ? '46px' : undefined }}>
            {children}
          </div>
        </VerticallyCentered>
      </Content>
    </StandardLayout>
  </Page>
)

export default LoginLayout
