import { IonIcon } from '@ionic/react'
import { checkmarkOutline, flagOutline, locationOutline } from 'ionicons/icons'
import { omit } from 'lodash'
import React from 'react'
import { RouteWaypointStatus } from 'schema'
import styled from 'styled-components'

interface WaypointNumberPropsExplicit {
  number: number | 'start' | 'finish'
  status?: RouteWaypointStatus
}

interface WaypointNumberPropsInferred {
  index: number
  total: number
  status?: RouteWaypointStatus
}

export type WaypointNumberProps = React.HTMLAttributes<HTMLDivElement> & (
  WaypointNumberPropsExplicit | WaypointNumberPropsInferred
)

const Wrapper = styled.div`
  background-color: var(--wp-current-color, var(--ion-color-primary));
  border-radius: var(--ion-border-radius);
  color: var(--ion-color-primary-contrast);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: Menlo, monospace, var(--ion-font-family, inherit);
  font-weight: 800;

  ion-icon {
    font-size: 1.1em;
    --ionicon-stroke-width: 40px;
  }
`

export const WaypointNumber = (props: WaypointNumberProps) => {
  type nt = WaypointNumberPropsExplicit['number']

  const number: nt = 'number' in props ? props.number :
    props.index === 0 ? 'start' :
      props.index === (props.total - 1) ? 'finish' :
        props.index

  const rest = omit(props, ['number', 'total', 'index'])

  const inner =
    props.status === RouteWaypointStatus.Completed ?
      <IonIcon icon={checkmarkOutline} /> :
      number === 'start' ?
        <IonIcon icon={locationOutline} /> :
        number === 'finish' ?
          <IonIcon icon={flagOutline} /> :
          <> {number} </>

  return (
    <Wrapper {...rest}>
      {inner}
    </Wrapper>
  )
}
