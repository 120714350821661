import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CollectOrderSignatureMutationVariables = Types.Exact<{
  data: Types.CollectSignatureInput;
  where: Types.OrderWhereUniqueInput;
}>;


export type CollectOrderSignatureMutation = { __typename?: 'Mutation', collectOrderSignature?: { __typename?: 'Signature', id: number } | null };

export type OrderDataForSignatureFragment = { __typename?: 'Order', id: number, customer?: { __typename?: 'Customer', id: number } | null, contacts: Array<{ __typename?: 'OrderContact', id: number, default: boolean, contact: { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null }, role?: { __typename?: 'ContactRole', name: string, slug: string, sort: number } | null }>, terms: { __typename?: 'OrderTerms', id: number, accepted: boolean, needsAcceptance: boolean, content: { __typename?: 'Terms', id: number, title: string, body: string } } };

export type GetOrderDataForSignatureQueryVariables = Types.Exact<{
  orderId: Types.Scalars['Int'];
}>;


export type GetOrderDataForSignatureQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: number, customer?: { __typename?: 'Customer', id: number } | null, contacts: Array<{ __typename?: 'OrderContact', id: number, default: boolean, contact: { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null }, role?: { __typename?: 'ContactRole', name: string, slug: string, sort: number } | null }>, terms: { __typename?: 'OrderTerms', id: number, accepted: boolean, needsAcceptance: boolean, content: { __typename?: 'Terms', id: number, title: string, body: string } } } | null };

export type GetCustomerContactsQueryVariables = Types.Exact<{
  customerId: Types.Scalars['Int'];
}>;


export type GetCustomerContactsQuery = { __typename?: 'Query', customers: Array<{ __typename?: 'Customer', id: number, contacts: Array<{ __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null }> }> };

export const OrderDataForSignatureFragmentDoc = gql`
    fragment OrderDataForSignature on Order {
  id
  customer {
    id
  }
  contacts {
    id
    default
    contact {
      id
      firstName
      lastName
    }
    role {
      name
      slug
      sort
    }
  }
  terms {
    id
    accepted
    needsAcceptance
    content {
      id
      title
      body
    }
  }
}
    `;
export const CollectOrderSignatureDocument = gql`
    mutation CollectOrderSignature($data: CollectSignatureInput!, $where: OrderWhereUniqueInput!) {
  collectOrderSignature(data: $data, where: $where) {
    id
  }
}
    `;
export type CollectOrderSignatureMutationFn = Apollo.MutationFunction<CollectOrderSignatureMutation, CollectOrderSignatureMutationVariables>;

/**
 * __useCollectOrderSignatureMutation__
 *
 * To run a mutation, you first call `useCollectOrderSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectOrderSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectOrderSignatureMutation, { data, loading, error }] = useCollectOrderSignatureMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCollectOrderSignatureMutation(baseOptions?: Apollo.MutationHookOptions<CollectOrderSignatureMutation, CollectOrderSignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CollectOrderSignatureMutation, CollectOrderSignatureMutationVariables>(CollectOrderSignatureDocument, options);
      }
export type CollectOrderSignatureMutationHookResult = ReturnType<typeof useCollectOrderSignatureMutation>;
export type CollectOrderSignatureMutationResult = Apollo.MutationResult<CollectOrderSignatureMutation>;
export type CollectOrderSignatureMutationOptions = Apollo.BaseMutationOptions<CollectOrderSignatureMutation, CollectOrderSignatureMutationVariables>;
export const GetOrderDataForSignatureDocument = gql`
    query GetOrderDataForSignature($orderId: Int!) {
  order(id: $orderId) {
    ...OrderDataForSignature
  }
}
    ${OrderDataForSignatureFragmentDoc}`;

/**
 * __useGetOrderDataForSignatureQuery__
 *
 * To run a query within a React component, call `useGetOrderDataForSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDataForSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDataForSignatureQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderDataForSignatureQuery(baseOptions: Apollo.QueryHookOptions<GetOrderDataForSignatureQuery, GetOrderDataForSignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderDataForSignatureQuery, GetOrderDataForSignatureQueryVariables>(GetOrderDataForSignatureDocument, options);
      }
export function useGetOrderDataForSignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderDataForSignatureQuery, GetOrderDataForSignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderDataForSignatureQuery, GetOrderDataForSignatureQueryVariables>(GetOrderDataForSignatureDocument, options);
        }
export type GetOrderDataForSignatureQueryHookResult = ReturnType<typeof useGetOrderDataForSignatureQuery>;
export type GetOrderDataForSignatureLazyQueryHookResult = ReturnType<typeof useGetOrderDataForSignatureLazyQuery>;
export type GetOrderDataForSignatureQueryResult = Apollo.QueryResult<GetOrderDataForSignatureQuery, GetOrderDataForSignatureQueryVariables>;
export function refetchGetOrderDataForSignatureQuery(variables: GetOrderDataForSignatureQueryVariables) {
      return { query: GetOrderDataForSignatureDocument, variables: variables }
    }
export const GetCustomerContactsDocument = gql`
    query GetCustomerContacts($customerId: Int!) {
  customers(where: {id: {equals: $customerId}}) {
    id
    contacts(where: {active: {equals: true}}) {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetCustomerContactsQuery__
 *
 * To run a query within a React component, call `useGetCustomerContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerContactsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerContactsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>(GetCustomerContactsDocument, options);
      }
export function useGetCustomerContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>(GetCustomerContactsDocument, options);
        }
export type GetCustomerContactsQueryHookResult = ReturnType<typeof useGetCustomerContactsQuery>;
export type GetCustomerContactsLazyQueryHookResult = ReturnType<typeof useGetCustomerContactsLazyQuery>;
export type GetCustomerContactsQueryResult = Apollo.QueryResult<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>;
export function refetchGetCustomerContactsQuery(variables: GetCustomerContactsQueryVariables) {
      return { query: GetCustomerContactsDocument, variables: variables }
    }