import gql from 'graphql-tag'

import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { LoadingCard } from 'components/common/LoadingCard'
import { useSession } from 'hooks/useSession'
import { StandardLayout } from 'layouts/StandardLayout'
import { OrderShow } from 'pages/orders/Show'
import { useGetAssignmentForActiveCheckQueryQuery } from './__generated__/OrderShow'

interface AssignmentOrderShowProps {
  assignmentId: number
  orderId: number
}

gql`
  query GetAssignmentForActiveCheckQuery($assignmentId: Int!) {
    assignment(id: $assignmentId) {
      id
      active
      operator {
        id
      }
    }
  }
`

export const AssignmentOrderShow = (props: AssignmentOrderShowProps) => {
  const { user } = useSession()
  const { assignmentId, orderId } = props
  const { data, loading: loadingRaw, error } = useGetAssignmentForActiveCheckQueryQuery({
    fetchPolicy: 'cache-first',
    variables: { assignmentId },
  })

  const assignment = data?.assignment
  const loading = !data && loadingRaw
  const notAuthorized = (assignment && user) && user.id !== assignment.operator.id
  const notFound = (loading === false && !assignment) || notAuthorized

  if (loading) {
    return (
      <StandardLayout>
        <LoadingCard />
      </StandardLayout>
    )
  }

  if (error) {
    return (
      <StandardLayout title="Error" defaultBackTo="/">
        <InlineErrorMessage message={`An error occured: ${error}`} />
      </StandardLayout>
    )
  }

  if (notFound || notAuthorized || assignment?.active === false) {
    return (
      <StandardLayout title="Not Found" defaultBackTo="/">
        <InlineErrorMessage message="This order could not be found" />
      </StandardLayout>
    )
  }

  return <OrderShow orderId={orderId} />
}

export default AssignmentOrderShow
