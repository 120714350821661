import { OrderContact } from 'schema'
import { RecursivePartial } from 'types/RecursivePartial'

export function contactsForRole<T extends RecursivePartial<OrderContact>>(contacts: T[], roleSlug: string): T['contact'][] {
  const withExplicitRole = contacts.filter((contact) => contact.role?.slug === roleSlug).map(({ contact }) => contact)

  if (withExplicitRole.length > 0) {
    return withExplicitRole
  }

  const defaultContacts = contacts.filter((contact) => contact.default).map(({ contact }) => contact)

  return defaultContacts
}
