import { Loading } from 'components/common/Loading'
import { Card } from 'components/core'
import React from 'react'

interface LoadingCardProps extends React.ComponentProps<typeof Card> {
  loader?: React.ComponentProps<typeof Loading>
}

export const LoadingCard = ({ loader, ...rest }: LoadingCardProps) => (
  <Card {...rest}>
    <Loading enabled {...loader} />
  </Card>
)
