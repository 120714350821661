import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetManualRouteProgressButtonDataQueryVariables = Types.Exact<{
  routeId: Types.Scalars['Int'];
}>;


export type GetManualRouteProgressButtonDataQuery = { __typename?: 'Query', route?: { __typename?: 'Route', id: number, equipment?: { __typename?: 'Equipment', telematics: { __typename?: 'EquipmentTelematics', gps?: { __typename?: 'TelematicsGPS', enabled: boolean } | null, stats?: { __typename?: 'TelematicsStats', enabled: boolean } | null } } | null, waypoints: Array<{ __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, name?: string | null, scheduledArrivalTimeLocal?: Types.Scalars['DateTime'] | null, scheduledDepartureTimeLocal?: Types.Scalars['DateTime'] | null, actualArrivalTimeLocal?: Types.Scalars['DateTime'] | null, actualDepartureTimeLocal?: Types.Scalars['DateTime'] | null }> } | null };

export type UpdateRouteWaypointMutationVariables = Types.Exact<{
  data: Types.RouteWaypointInput;
  where: Types.RouteWaypointWhereUniqueInput;
}>;


export type UpdateRouteWaypointMutation = { __typename?: 'Mutation', updateRouteWaypoint?: { __typename?: 'RouteWaypoint', id: number, status: Types.RouteWaypointStatus, actualArrivalTimeLocal?: Types.Scalars['DateTime'] | null, actualDepartureTimeLocal?: Types.Scalars['DateTime'] | null } | null };


export const GetManualRouteProgressButtonDataDocument = gql`
    query GetManualRouteProgressButtonData($routeId: Int!) {
  route(id: $routeId) {
    id
    equipment {
      telematics {
        gps {
          enabled
        }
        stats {
          enabled
        }
      }
    }
    waypoints {
      id
      status
      name
      scheduledArrivalTimeLocal
      scheduledDepartureTimeLocal
      actualArrivalTimeLocal
      actualDepartureTimeLocal
    }
  }
}
    `;

/**
 * __useGetManualRouteProgressButtonDataQuery__
 *
 * To run a query within a React component, call `useGetManualRouteProgressButtonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualRouteProgressButtonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualRouteProgressButtonDataQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetManualRouteProgressButtonDataQuery(baseOptions: Apollo.QueryHookOptions<GetManualRouteProgressButtonDataQuery, GetManualRouteProgressButtonDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManualRouteProgressButtonDataQuery, GetManualRouteProgressButtonDataQueryVariables>(GetManualRouteProgressButtonDataDocument, options);
      }
export function useGetManualRouteProgressButtonDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManualRouteProgressButtonDataQuery, GetManualRouteProgressButtonDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManualRouteProgressButtonDataQuery, GetManualRouteProgressButtonDataQueryVariables>(GetManualRouteProgressButtonDataDocument, options);
        }
export type GetManualRouteProgressButtonDataQueryHookResult = ReturnType<typeof useGetManualRouteProgressButtonDataQuery>;
export type GetManualRouteProgressButtonDataLazyQueryHookResult = ReturnType<typeof useGetManualRouteProgressButtonDataLazyQuery>;
export type GetManualRouteProgressButtonDataQueryResult = Apollo.QueryResult<GetManualRouteProgressButtonDataQuery, GetManualRouteProgressButtonDataQueryVariables>;
export function refetchGetManualRouteProgressButtonDataQuery(variables: GetManualRouteProgressButtonDataQueryVariables) {
      return { query: GetManualRouteProgressButtonDataDocument, variables: variables }
    }
export const UpdateRouteWaypointDocument = gql`
    mutation UpdateRouteWaypoint($data: RouteWaypointInput!, $where: RouteWaypointWhereUniqueInput!) {
  updateRouteWaypoint(data: $data, where: $where) {
    id
    status
    actualArrivalTimeLocal
    actualDepartureTimeLocal
  }
}
    `;
export type UpdateRouteWaypointMutationFn = Apollo.MutationFunction<UpdateRouteWaypointMutation, UpdateRouteWaypointMutationVariables>;

/**
 * __useUpdateRouteWaypointMutation__
 *
 * To run a mutation, you first call `useUpdateRouteWaypointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRouteWaypointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRouteWaypointMutation, { data, loading, error }] = useUpdateRouteWaypointMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateRouteWaypointMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRouteWaypointMutation, UpdateRouteWaypointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRouteWaypointMutation, UpdateRouteWaypointMutationVariables>(UpdateRouteWaypointDocument, options);
      }
export type UpdateRouteWaypointMutationHookResult = ReturnType<typeof useUpdateRouteWaypointMutation>;
export type UpdateRouteWaypointMutationResult = Apollo.MutationResult<UpdateRouteWaypointMutation>;
export type UpdateRouteWaypointMutationOptions = Apollo.BaseMutationOptions<UpdateRouteWaypointMutation, UpdateRouteWaypointMutationVariables>;