import { uniq } from 'lodash'
import { formatAddress } from '../formatAddress'
import { NavigateFunction, NavigationRegister } from './types'

export const navigateOnAppleLink: NavigateFunction = (addresses) => {
  if (addresses.length === 0) return null

  const points = uniq(
    addresses.map((address) => {
      if (address.lat && address.lng) {
        return [address.lat, address.lng].join(',')
      }
      return formatAddress(address)
    })
  )

  if (points.length > 2) return null

  const parameters: Record<string, string> = {
    dirflg: 'd',
  }

  const [first, last] = points

  if (last) {
    parameters.saddr = first
    parameters.daddr = last
  } else {
    parameters.daddr = first
  }

  return `http://maps.apple.com/?${new URLSearchParams(parameters).toString()}`
}

export const NavigateOnApple: NavigationRegister = {
  title: 'Apple Maps',
  fn: navigateOnAppleLink,
  supportedCount: 2,
  available: () => {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform)
    return isMacLike || isIOS
  },
}
