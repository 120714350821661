import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

type MarkdownProps = React.ComponentProps<typeof ReactMarkdown>

export const Markdown = (props: MarkdownProps) => {
  const remarkPlugins: typeof props.remarkPlugins = [...(props.remarkPlugins || []), remarkGfm]
  return <ReactMarkdown {...props} remarkPlugins={remarkPlugins} linkTarget={props.linkTarget || '_blank'} />
}
