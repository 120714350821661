import React, { PropsWithChildren } from 'react'

import {
  BackButton,
  Buttons,
  Content,
  Header,
  Title,
  Toolbar,
} from 'components/core'

export type StandardLayoutProps = PropsWithChildren<{
  title?: React.ReactNode
  id?: string
  hideHeader?: boolean
  headerEndButtons?: React.ReactNode
  collapsingTitle?: boolean
  defaultBackTo?: string
}>

export const StandardLayout = (props: StandardLayoutProps) => (
  <>
    {props.hideHeader !== true && (
      <Header translucent>
        <Toolbar style={{ '--border-width': '0px' }}>
          <Buttons slot="start">
            <BackButton defaultHref={props.defaultBackTo} />
          </Buttons>
          <Title>{props.title}</Title>
          {props.headerEndButtons && (
            <Buttons slot="end">
              {props.headerEndButtons}
            </Buttons>
          )}
        </Toolbar>
      </Header>
    )}
    <Content fullscreen>
      {props.hideHeader !== true && props.collapsingTitle && (
        <Header collapse="condense">
          <Toolbar>
            {props.title &&
              <Title size="large">{props.title}</Title>}
          </Toolbar>
        </Header>
      )}

      {props.children}
    </Content>
  </>
)
