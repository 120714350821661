import { Input as CoreInput } from 'components/core'
import React, { useEffect, useRef } from 'react'
import { UseControllerProps, useController } from 'react-hook-form'

export type InputProps =
  UseControllerProps<any, any> &
  Omit<React.ComponentProps<typeof CoreInput>, 'value'>

export const Input = (props: InputProps) => {
  const {
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    ...inputProps
  } = props

  const ref = useRef<HTMLIonInputElement | null>(null)
  const {
    field: {
      ref: formFieldRef, onChange, onBlur, value,
    },
  } = useController({
    control,
    defaultValue: defaultValue !== undefined ? defaultValue : '',
    name,
    rules,
    shouldUnregister,
  })

  useEffect(() => {
    formFieldRef({
      focus: () => { ref.current?.setFocus() },
    })
  }, [formFieldRef])

  return (
    <CoreInput
      {...inputProps}
      ref={ref}
      value={value}
      onIonBlur={(evt) => {
        onBlur()
        if (inputProps.onIonBlur) {
          inputProps.onIonBlur(evt)
        }
      }}
      onIonChange={(evt) => {
        onChange(evt)
        if (inputProps.onIonChange) {
          inputProps.onIonChange(evt)
        }
      }}
    />
  )
}
