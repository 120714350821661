import { IonIcon } from '@ionic/react'
import { Fab, FabButton } from 'components/core'
import { settingsSharp } from 'ionicons/icons'
import { useState } from 'react'
import { SettingsMenu } from './SettingsMenu'

export const SettingsFab = () => {
  const [open, setOpen] = useState<boolean | undefined>(undefined)
  const toggle = () => setOpen((prev) => !prev)

  return (
    <>
      <Fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        onClick={toggle}
      >
        <FabButton color="light" size="small">
          <IonIcon icon={settingsSharp} />
        </FabButton>
      </Fab>

      <SettingsMenu open={open} onClose={() => setOpen(false)} />
    </>
  )
}
