import Color from 'color'
import { createContext, useContext, useEffect } from 'react'
import { Organization as OrganizationFull } from 'schema'
import { SetRequired } from 'type-fest'

type Organization = SetRequired<Partial<OrganizationFull>, 'id' | 'name'>

export const OrganizationContext = createContext<Organization | null>(null)

export const useOrganization = () => useContext(OrganizationContext)

const DEFAULT_PRIMARY_COLOR = '#fbc212'

export const OrganizationProvider: React.FC<{ organization: Organization | null }> = ({ children, organization }) => {
  const primaryColor = organization?.primaryColor || undefined

  useEffect(() => {
    const primary = parseColorWithFallback(primaryColor, DEFAULT_PRIMARY_COLOR)
    const tinted = primary.mix(Color('#ffffff'), 0.1)
    const shaded = primary.mix(Color('#000000'), 0.12)

    document.body.style.setProperty('--ion-color-primary', primary.toString())
    document.body.style.setProperty('--ion-color-primary-rgb', primary.array().map(Math.round).join(','))
    document.body.style.setProperty('--ion-color-primary-shade', shaded.toString())
    document.body.style.setProperty('--ion-color-primary-tint', tinted.toString())

    document.body.style.setProperty('--ion-color-primary-contrast', primary.luminosity() < 0.6 ? '#fff' : '#303030')
  }, [primaryColor])

  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  )
}

const parseColorWithFallback = (color: string | undefined, fallback: string) => {
  try {
    return Color(color || fallback).rgb()
  } catch {
    return Color(fallback).rgb()
  }
}
