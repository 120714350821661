import { MutationHookOptions } from '@apollo/client'
import { UnionToIntersection } from 'type-fest'
import { FullResultOf } from '../../gql/full'
import { UseTimeClockQuery } from '../../hooks/timeclock/useTimeClock'

export const updateTimeClockQueryInCache: MutationHookOptions<
  UnionToIntersection<FullResultOf<'ClockIn'> | FullResultOf<'ChangeTask'>>
>['update'] = (cache, { data }) => {
  const newTimeClock = data?.timeClock?.clockIn?.timeClock || data?.timeClock?.changeTask?.timeClock
  if (!newTimeClock) return
  cache.updateQuery({ query: UseTimeClockQuery }, (queryData) => {
    if (!queryData?.me?.id) return queryData
    return {
      ...queryData,
      me: {
        ...queryData?.me,
        timeClock: newTimeClock,
      },
    }
  })
}
