import { todayInZone } from 'helpers/datetime'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

export const useToday = (timezone?: string): DateTime => {
  const timezoneWithDefault = timezone || DateTime.local().zoneName
  const [today, setToday] = useState(() => todayInZone(timezoneWithDefault))

  useEffect(() => {
    const msTillNextDay = today.plus({ days: 1 }).diffNow('milliseconds').milliseconds + 100
    const timerTillNextDay = setTimeout(() => {
      setToday(todayInZone(timezoneWithDefault))
    }, msTillNextDay)
    return () => clearTimeout(timerTillNextDay)
  }, [today.toMillis()])
  return today
}
