import { IonPage, IonRouterOutlet } from '@ionic/react'
import { Route } from 'react-router-dom'
import { shouldAnimateRouter } from '../../helpers/shouldAnimateRouter'
import { Login } from './Login'
import { Logout } from './Logout'

export const AuthRoutes = () => (
  <IonRouterOutlet animated={shouldAnimateRouter()} id="auth">
    <IonPage>
      <Route path="/auth/logout" component={Logout} exact />
      <Route path="/auth/login" component={Login} />
    </IonPage>
  </IonRouterOutlet>
)
