import { QueryResult } from '@apollo/client'
import { useIonViewWillEnter } from '@ionic/react'

export function useAutoRefetchOnEnter<T extends QueryResult<any, any>>(query: T): T {
  const { loading, refetch } = query

  useIonViewWillEnter(() => {
    if (!loading) {
      refetch()
    }
  }, [loading, refetch])

  return query
}
