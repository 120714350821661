import { compact } from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useLoading } from 'hooks/useLoading'
import { useSession } from 'hooks/useSession'

import {
  Button,
  Buttons,
  Content,
  Header,
  Item,
  Label,
  List,
  Title,
  Toolbar,
} from 'components/core'

import { Input } from 'components/form/Input'
import { Select, SelectOption } from 'components/form/Select'
import { Textarea } from 'components/form/Textarea'
import { useForm } from 'react-hook-form'

import { useGetOrderCancellationReasonsQuery } from 'gql-docs/queries/__generated__/GetOrderCancellationReasons'
import gql from 'graphql-tag'
import { useToast } from '../hooks/useToast'
import { useCancelOrderMutation } from './__generated__/CancelOrderModal'

const CancelButton = styled(Button).attrs(() => ({
  children: 'Cancel Order',
  size: 'medium',
}))`
  margin-top: 30px;
  min-width: 50%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

gql`
  mutation CancelOrder(
    $id: Int!
    $comment: CommentInput!
    $cancellationReason: String
  ) {

    updateOrder(
      data: {
        revision: -42,
        status: "cancelled",
        cancellationReason: $cancellationReason,
        comments: {
          create: [ $comment ]
        }
      }
      where: {
        id: $id
      }
    ) {
      ...OrderShowData
    }
  }
`

interface FormValues {
  who: string
  reason: string
  comments: string
}

export const CancelOrderModal = ({
  id,
  onDismiss,
}: {
  id: number,
  onDismiss?: () => void
}) => {
  const { user } = useSession()
  const { withLoading } = useLoading()
  const [presentToast] = useToast()
  const { control, handleSubmit, formState: { errors } } = useForm<FormValues>()

  const cancellationReasonsQuery = useGetOrderCancellationReasonsQuery({
    variables: {
      where: {
        active: {
          equals: true,
        },
      },
    },
  })

  const cancellationReasons = useMemo(() => {
    const reasons = (cancellationReasonsQuery.data?.orderCancellationReasons || []).slice()
    const hasOther = reasons.some(({ name }) => name.toLowerCase() === 'other')
    if (!hasOther) {
      reasons.push({ name: 'Other', sort: Infinity, slug: '__other__' })
    }
    return reasons
  }, [cancellationReasonsQuery.data])

  const [cancelOrderMutation] = useCancelOrderMutation({
    refetchQueries: ['GetAssignmentCardFullData', 'GetRouteOverviewData'],
  })

  const onFormSubmit = withLoading(async (data: FormValues) => {
    const reason = data.reason === '__other__' ? undefined : data.reason

    try {
      await cancelOrderMutation({
        variables: {
          id,
          cancellationReason: reason,
          comment: {
            personId: user?.id,
            text: compact([
              `Order was cancelled by ${data.who}.`,
              data.comments,
            ]).join('  \n\n'),
            createdAt: DateTime.utc().toISO(),
          },
        },
      })

      presentToast({
        color: 'success',
        message: 'The order has been cancelled.',
        duration: 3000,
      })

      if (onDismiss) {
        onDismiss()
      }
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormErrorMessage = ({ field }: { field: keyof FormValues }) => {
    const msg = errors[field]?.message
    if (msg) {
      return <ErrorMessage>{msg}</ErrorMessage>
    }
    return null
  }

  return (
    <>
      <Header>
        <Toolbar>
          <Title>Cancel Order</Title>
          <Buttons slot="end">
            <Button onClick={onDismiss}> Close </Button>
          </Buttons>
        </Toolbar>
      </Header>
      <Content fullscreen>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <List>
            <Item>
              <Label position="stacked">Cancelled By</Label>
              <FormErrorMessage field="who" />
              <Input
                name="who"
                control={control}
                placeholder="Who authorized the Cancellation?"
                rules={{
                  required: 'Please enter the name of the person who cancelled the order.',
                }}
              />
            </Item>
            <Item>
              <Label position="stacked">Reason</Label>
              <FormErrorMessage field="reason" />
              <Select
                name="reason"
                control={control}
                rules={{
                  required: 'Please select reason this order was cancelled.',
                }}
                placeholder="Select Cancellation Reason"
                interface="action-sheet"
                interfaceOptions={{
                  header: 'Cancellation Reason',
                }}
              >
                {cancellationReasons.map((reason) => (
                  <SelectOption key={reason.slug} value={reason.slug}>{reason.name}</SelectOption>
                ))}
              </Select>
            </Item>
            <Item>
              <Label position="stacked">Comments</Label>
              <Textarea
                name="comments"
                control={control}
                placeholder="Additional info regarding the Cancellation"
                autoGrow
              />
            </Item>
          </List>

          <div className="ion-text-center">
            <CancelButton type="submit" />
          </div>
        </form>
      </Content>
    </>
  )
}
