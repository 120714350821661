import { useLocalStorageState } from 'ahooks'
import { NavigationApp } from 'helpers/navigate'
import React, { useMemo } from 'react'

export type UserSettings = {
  loading?: boolean
  navigationApp: NavigationApp | null
  setNavigationApp: (provider: NavigationApp) => Promise<void>
}

const UserSettingsContext = React.createContext<UserSettings>({
  loading: false,
  navigationApp: null,
  setNavigationApp: async () => { },
})

export const useUserSettings = () => React.useContext(UserSettingsContext)

export const UserSettingsProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [navigationApp, setNavigationApp] = useLocalStorageState<UserSettings['navigationApp']>('navigationApp', { defaultValue: null })

  const value = useMemo(() => {
    const state: UserSettings = {
      loading: false,
      navigationApp,
      setNavigationApp: async (provider) => setNavigationApp(provider),
    }
    return state
  }, [navigationApp, setNavigationApp])

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  )
}
