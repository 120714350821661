import { Button, Modal } from 'components/core'
import React, { useState } from 'react'
import { SetOptional } from 'type-fest'

type ModalProps = React.ComponentProps<typeof Modal>
type ButtonProps = React.ComponentProps<typeof Button>

type ButtonModalProps = SetOptional<ModalProps, 'isOpen'>
type ButtonModal = React.ComponentType<ButtonModalProps>

export interface ModalButtonProps extends Omit<ButtonProps, 'children'> {
  content?: React.ReactNode
  children: React.ReactElement<ButtonModalProps>
}

const ModalButton = (props: ModalButtonProps) => {
  const {
    children, content, onClick, ...rest
  } = props

  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Button
        {...rest}
        children={content}
        onClick={async (evt) => {
          if (onClick) {
            try {
              await onClick(evt)
            } catch {
              return
            }
          }
          setShowModal(true)
        }}
      />
      {React.Children.map(children, (child, index) => React.cloneElement(child, {
        isOpen: child.props.isOpen === undefined ? showModal : child.props.isOpen,
        onDidPresent: (event: any) => {
          if (!showModal) {
            setShowModal(true)
          }

          if (child.props.onDidPresent) {
            child.props.onDidPresent(event)
          }
        },
        onDidDismiss: (event: any) => {
          if (showModal) {
            setShowModal(false)
          }

          if (child.props.onDidDismiss) {
            child.props.onDidDismiss(event)
          }
        },
        children: React.Children.map(child.props.children, (modalChild) => {
          if (
            React.isValidElement(modalChild)
          ) {
            return React.cloneElement(modalChild, {
              isVisible: showModal,
              onDismiss: () => {
                setShowModal(false)
              },
            } as any)
          }

          return modalChild
        }),
      }))}
    </>
  )
}

type ModalButtonInterface = typeof ModalButton & {
  Modal: ButtonModal
}

const toExport = ModalButton as ModalButtonInterface
toExport.Modal = Modal as ButtonModal

export { toExport as ModalButton }
