import {
  IonCol, IonGrid, IonLabel, IonList, IonRow,
} from '@ionic/react'
import { getActivityColor } from 'helpers/colorCoding'
import { TIME_SHIFT_HOURS_FORMAT, TIME_SHIFT_ITEM_FORMAT } from 'helpers/datetime'
import { DateTime, Duration } from 'luxon'
import styled from 'styled-components'
import { TimeClockLabel } from 'components/common/TimeClockLabel'
import ClockOutPendingLabel from './ClockOutPendingLabel'

interface TimeShiftItemProps {
  name?: string
  id: number
  startTime: string
  endTime: string | undefined
  time?: string
  color?: string
}

const ItemContainer = styled(IonList)<{ activityColor: string | undefined}>`
  display: block;
  margin-left: 2px;
  border-left: 2px solid
    ${(props) => getActivityColor(props.activityColor).lightColor}
`

const TimeShiftItem = (props: TimeShiftItemProps) => {
  const {
    name, startTime, endTime, color,
  } = props

  const now = DateTime.now().toISO()
  const startTimeState = DateTime.fromISO(startTime || now)
  const endTimeState = DateTime.fromISO(endTime || now)

  let totalWorkedHours = 0
  if (startTimeState && endTimeState) {
    totalWorkedHours = endTimeState.diff(startTimeState, 'minutes').minutes
  }

  return (
    <ItemContainer activityColor={color}>
      <IonLabel style={{ marginLeft: '10px' }}>
        <TimeClockLabel bold>{name}</TimeClockLabel>
      </IonLabel>
      <IonGrid style={{ marginTop: '-10px' }}>
        <IonRow>
          <IonCol>
            <TimeClockLabel bold={false}> {startTimeState.toFormat(TIME_SHIFT_ITEM_FORMAT)}</TimeClockLabel>
          </IonCol>
          <IonCol>
            <TimeClockLabel bold={false}>
              {endTime ? (
                endTimeState.toFormat(TIME_SHIFT_ITEM_FORMAT)
              ) : (
                <ClockOutPendingLabel text='Pending clock out' />
              )}
            </TimeClockLabel>
          </IonCol>
          <IonCol size="3" style={{ textAlign: 'right' }}>
            <TimeClockLabel bold={false}>{Duration.fromObject({ minutes: totalWorkedHours }).toFormat(TIME_SHIFT_HOURS_FORMAT)}</TimeClockLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </ItemContainer>
  )
}

export default TimeShiftItem
