import * as Types from 'schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQueryQuery = { __typename?: 'Query', me?: { __typename?: 'Contact', id: number, firstName?: string | null, lastName?: string | null, organization: { __typename?: 'Organization', id: number, name: string, logo?: string | null, primaryColor?: string | null } } | { __typename?: 'Operator', id: number, firstName?: string | null, lastName?: string | null, organization: { __typename?: 'Organization', id: number, name: string, logo?: string | null, primaryColor?: string | null } } | { __typename?: 'Person', id: number, firstName?: string | null, lastName?: string | null, organization: { __typename?: 'Organization', id: number, name: string, logo?: string | null, primaryColor?: string | null } } | { __typename?: 'User', id: number, firstName?: string | null, lastName?: string | null, organization: { __typename?: 'Organization', id: number, name: string, logo?: string | null, primaryColor?: string | null } } | null };

export type AuthorizeMutationVariables = Types.Exact<{
  jwt: Types.Scalars['String'];
}>;


export type AuthorizeMutation = { __typename?: 'Mutation', authorize?: { __typename?: 'Authorization', token?: string | null } | null };


export const CurrentUserQueryDocument = gql`
    query CurrentUserQuery {
  me {
    id
    firstName
    lastName
    organization {
      id
      name
      logo
      primaryColor
    }
  }
}
    `;

/**
 * __useCurrentUserQueryQuery__
 *
 * To run a query within a React component, call `useCurrentUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQueryQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(CurrentUserQueryDocument, options);
      }
export function useCurrentUserQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(CurrentUserQueryDocument, options);
        }
export type CurrentUserQueryQueryHookResult = ReturnType<typeof useCurrentUserQueryQuery>;
export type CurrentUserQueryLazyQueryHookResult = ReturnType<typeof useCurrentUserQueryLazyQuery>;
export type CurrentUserQueryQueryResult = Apollo.QueryResult<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>;
export function refetchCurrentUserQueryQuery(variables?: CurrentUserQueryQueryVariables) {
      return { query: CurrentUserQueryDocument, variables: variables }
    }
export const AuthorizeDocument = gql`
    mutation Authorize($jwt: String!) {
  authorize(jwt: $jwt, scope: "operator") {
    token
  }
}
    `;
export type AuthorizeMutationFn = Apollo.MutationFunction<AuthorizeMutation, AuthorizeMutationVariables>;

/**
 * __useAuthorizeMutation__
 *
 * To run a mutation, you first call `useAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeMutation, { data, loading, error }] = useAuthorizeMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useAuthorizeMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeMutation, AuthorizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeMutation, AuthorizeMutationVariables>(AuthorizeDocument, options);
      }
export type AuthorizeMutationHookResult = ReturnType<typeof useAuthorizeMutation>;
export type AuthorizeMutationResult = Apollo.MutationResult<AuthorizeMutation>;
export type AuthorizeMutationOptions = Apollo.BaseMutationOptions<AuthorizeMutation, AuthorizeMutationVariables>;