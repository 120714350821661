import {
  CSSProperties, ReactNode, useRef, useState,
} from 'react'

import styled from 'styled-components'

import { useUpdateEffect } from 'ahooks'

export const CollapseContent = styled.div<{ open?: boolean }>`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`

export type CollapseProps = {
  defaultOpen?: boolean
  header?: ReactNode | ((open: boolean) => ReactNode)
  className?: string | undefined
  children: ReactNode
}

export const Collapse = (props: CollapseProps) => {
  const [open, setOpen] = useState(props.defaultOpen || false)
  const [maxHeight, setMaxHeight] = useState<CSSProperties['maxHeight']>(open ? 'fit-content' : '0')
  const ref = useRef<HTMLDivElement>(null)

  useUpdateEffect(() => {
    if (open) {
      setMaxHeight(ref.current?.scrollHeight || 0)
    } else {
      setMaxHeight(0)
    }
  }, [open])

  const header = typeof props.header === 'function' ? props.header(open) : props.header

  return (
    <div className={props.className}>
      <span onClick={() => { setOpen(!open) }}>
        {header}
      </span>
      <CollapseContent ref={ref} style={{ maxHeight }}>
        {props.children}
      </CollapseContent>
    </div>
  )
}
