import { useQuery } from '@apollo/client'
import { StandardLayout } from 'layouts/StandardLayout'
import { DateTime } from 'luxon'
import { DatetimeRange } from 'helpers/datetime'
import { TimeSheetHistory } from 'components/timeclock/TimeSheetHistory'
import DateRangePicker from 'components/timeclock/DateRangePicker'
import { graphql } from '../../../gql/full'

const GetTimeSheet = graphql(`
  query GetTimeSheet ( $where: PersonTimeSheetWhereInput) {
    me {
      id
      ... on Operator {
        timeSheet( where: $where ) {
          id
          startTime
          endTime
          ...TimeSheetHistory_TimeSheet
        }
      }
    }
  }
`)

const DefaultTimeSheet = {
  id: '0',
  startTime: DateTime.now().startOf('week').toISO(),
  endTime: DateTime.now().endOf('week').toISO(),
  shifts: [],
}

export const TimeClockHistoryIndex = () => {
  const {
    data, refetch: refetchTimeClock, loading: timeClockLoading,
  } = useQuery(GetTimeSheet, {
    variables: {
      where: {
        startTime: DateTime.now().startOf('week').toISO(),
        endTime: DateTime.now().endOf('week').toISO(),
      },
    },
  })

  const timeSheet = (data?.me && 'timeSheet' in data.me && data.me.timeSheet) ? data.me.timeSheet : DefaultTimeSheet

  const defaultDateRangeHistory: DatetimeRange = {
    startDate: DateTime.fromISO(DefaultTimeSheet.startTime),
    endDate: DateTime.fromISO(DefaultTimeSheet.endTime),
  }

  const handleDateChange = (dateRange: DatetimeRange) => {
    refetchTimeClock({
      where: {
        startTime: dateRange.startDate.toISO(),
        endTime: dateRange.endDate.toISO(),
      },
    })
  }

  return (
    <StandardLayout
      defaultBackTo="/timeclock"
      title="Time Clock History"
    >
      <DateRangePicker dateRange={defaultDateRangeHistory} setDateRange={handleDateChange} />
      <TimeSheetHistory timeSheet={timeSheet} loading={timeClockLoading} />

    </StandardLayout>
  )
}
