import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { CreateAnimation } from '@ionic/react'
import {
  Spinner,
} from 'components/core'
import { compact } from 'lodash'

export interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  enabled?: boolean
  animate?: boolean | number
}

export const LoadingWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;

  &.animated {
    position: aboslute;
    z-index: 10;
  }
`

export const Loading = ({ enabled, animate, ...props }: LoadingProps) => {
  const [finished, setFinished] = useState(true)
  const [play, setPlay] = useState(false)

  useEffect(() => {
    if (enabled === true) {
      setFinished(false)
    } else {
      setPlay(true)
    }
  }, [enabled])

  if (!animate && !enabled) return null
  if (finished) return null

  return (
    <CreateAnimation
      play={play}
      duration={animate === true ? 1000 : animate || 0}
      fromTo={{
        property: 'opacity',
        fromValue: '1',
        toValue: '0',
      }}
      onFinish={{
        callback: () => {
          setFinished(true)
          setPlay(false)
        },
      }}
    >
      <LoadingWrapper {...props} className={compact([props.className, animate && 'animated']).join(' ')}><Spinner /></LoadingWrapper>
    </CreateAnimation>
  )
}
